import { _4uDesigned } from '../../../service/organisation/organisation'
import axios from "axios"

export default Object.assign(_4uDesigned, {
    async getValues(supplier, type) {
        return await axios.get(process.env.VUE_APP_ROOT_API_4UDESIGNED + 'mapping/values/' + type).then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
    async getMappings(supplier, type) {
        return await axios.get(process.env.VUE_APP_ROOT_API_4UDESIGNED + supplier + '/' + type + '/mapping').then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
    async addMapping(supplier, type, data) {
        return await axios.put(process.env.VUE_APP_ROOT_API_4UDESIGNED + supplier + '/' + type + '/mapping', data).then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
    async getProducts(supplier) {
        return await axios.get(process.env.VUE_APP_ROOT_API_4UDESIGNED + supplier + '/custom/product').then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
    async getCategories() {
        return await axios.get(process.env.VUE_APP_ROOT_API_4UDESIGNED + 'mapping/values/category/woocommerce').then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    }
})