<template>
  <div class="container">
    <div class="boxWrapper">
      <h1>Onderhoud dashboard</h1>
      <p>We zijn druk bezig met onderhoud aan het dashboard.</p>
      <div class="box">
        <div class="row">
          <div class="col-lg imageCol"><img src="../../assets/404.png" /></div>
          <div class="col-lg textCol">
            <h3>Contact opnemen</h3>
            <p>
              Heb je nog een vraag dan kan je ons bereiken via de mail of via de telefoon. Wij helpen je graag verder met je probleem.
            </p>
            <a class="btn btn-primary" href="mailto:info@millerdigital.nl">Stuur ons een mail</a>
            <div class="text-center py-2">of</div> 
            <a class="btn btn-outline-primary" href="tel:0756167753">Bel ons</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.state.pageRefreshAnimation = false;
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.boxWrapper {
  h1 {
    padding-top: 5rem;
  }
}
.box {
  width: 75%;
  min-height: 25rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 1.5rem 4rem;
  display: flex;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.imageCol,
.textCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.textCol {
  h3 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 2rem;
  }
  a {
    width: auto;
  }
}

.imageCol {
  img {
    max-width: 400px;
    width: 100%;
  }
}
</style>
