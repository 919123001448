<template>
  <div class="container mt-4 mb-5">
    <div class="header d-flex justify-content-between">
      <h3>Standup users</h3>
      <button @click="this.updateOrder()" class="btn btn-primary">
        opslaan
      </button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>Index</th>
          <th>Foto</th>
          <th scope="col">Medewerker</th>
          <th scope="col">Git ID</th>
          <th></th>
        </tr>
      </thead>
      <!-- <tbody> -->
      <draggable :list="standupUsers" item-key="id" tag="tbody">
        <template #item="{ element }">
          <tr>
            <th>{{ element.position }}</th>
            <th>
              <input
                type="file"
                name="fields[assetsFieldHandle][]"
                class="btn"
                @change="onChange($event, element.gitId)"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png"
              />

              <label
                  for="assetsFieldHandle"
                  class=""
                  style="z-index: 9999; position: relative;"
                >
                  <img
                    class="uploadIcon"
                    :src="'https://mijn.millerdigital.nl/api/avatar/' + this.getFileName(element.avatar)"
                    style="width: 50px;"
                  />
                </label>
            </th>
            <th scope="row">{{ element.name }}</th>
            <td>{{ element.gitId }}</td>
            <td>
              <button
                @click="this.removeUser(element.gitId)"
                class="btn btn-primary"
              >
                verwijder
              </button>
            </td>
          </tr>
        </template>
      </draggable>
    </table>
  </div>
  <div class="container mt-4">
    <h3>Voeg toe aan de standup</h3>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Medewerker</th>
          <th scope="col">Git ID</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in standupUsersNew" v-bind:key="user.id" class="">
          <th scope="row">{{ user.name }}</th>
          <td>{{ user.id }}</td>
          <td>
            <button
              @click="
                this.createUser(
                  user.id,
                  user.name,
                )
              "
              class="btn btn-primary"
            >
              voeg toe
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import gitService from "../../service/admin/GitService";
import standupService from "../../service/admin/StandupService";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      standupUsers: [],
      standupUsersNew: [],
    };
  },
  methods: {
    getFileName(file) {
      if (file == '') {
        return '';
      }

      return file.split("avatar/")[1];
    },
    async createUser(gitId, name) {
      await standupService.createStandupUser(gitId, name);
      this.loadAddedStandupUsers();
      this.loadNewStandupUsers();
    },
    async removeUser(gitId) {
      await standupService.removeStandupUser(gitId);
      this.loadNewStandupUsers();
      this.loadAddedStandupUsers();
    },
    loadAddedStandupUsers() {
      standupService.getAddedStandupUsers().then((data) => {
        this.standupUsers = data;
      });
    },
    loadNewStandupUsers() {
      standupService.getNewStandupUsers().then((data) => {
        this.standupUsersNew = data;
      });
    },
    async updateOrder() {
      let order = this.standupUsers.map((user) => user.gitId);
      await standupService.updateOrder(order);
      this.loadAddedStandupUsers();
    },
    async onChange(evt, gitId) {
      let file = evt.target.files[0];
      var formData = new FormData();
      let count = 0;

      if (file.size > 1024 * 1024 * 5) {
        alert("bestand is te groot");
        return;
      }

      formData.append("file", file);

      await standupService.uploadAvatar(formData, gitId);
      await this.loadAddedStandupUsers();
    },
  },
  async created() {
    this.loadNewStandupUsers();
    this.loadAddedStandupUsers();
  },
};
</script>

<style>
</style>