<template>
  <div class="container mt-4">
    <div
      id="alert"
      style="display: none"
      class="alert alert-success"
      role="alert"
    ></div>
    <router-link to="/admin">Ga terug naar overzicht</router-link>
    <h2>Beheer alle hosting gegevens voor Direct Admin</h2>
    <div class="accordion py-3" id="accordionExample">
      <p v-if="this.$store.state.allOrganisations.length == 0">
        Er zijn geen organisaties
      </p>

      <div
        class="accordion-item strippenkaartRow"
        v-for="(organization, index) in this.$store.state.allOrganisations"
        v-bind:key="organization"
      >
        <p class="accordion-header" :id="'heading' + index">
          <button
            class="accordion-button collapsed row"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + index"
            aria-expanded="false"
            :aria-controls="'collapse' + index"
            @click="open($event, organization.directAdmin)"
          >
            {{ organization.name }} -
            {{
              organization.directAdmin
                ? organization.directAdmin.name
                : "niet aanwezig"
            }}
          </button>
        </p>
        <div
          :id="'collapse' + index"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + index"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <select
              @change="loadUsersForServer(this.selectedServer)"
              v-model="this.selectedServer"
            >
              <option value="wp1.millerdigital.nl">wp1</option>
              <option value="sw5.millerdigital.nl">sw5</option>
              <option value="sw6.millerdigital.nl">sw6</option>
            </select>
            <select
              v-if="this.isServerSelected"
              v-model="this.selectedDirectName"
            >
              <option
                v-for="name in this.$store.state.directAdminUsers"
                v-bind:key="name"
                :value="name"
              >
                {{ name }}
              </option>
            </select>
            <button
              @click="
                saveDirectAdmin(
                  organization.id,
                  organization.directAdmin,
                  index
                )
              "
              class="d-block mt-3 btn btn-primary"
            >
              opslaan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import organisationService from "../../service/admin/OrganisationService";
import directAdminService from "../../service/admin/DirectAdminService";
export default {
  data() {
    return {
      gitprojects: [],
      loaded: false,
      selectedServer: "",
      isServerSelected: false,
      directAdminNames: [],
      selectedDirectName: "",
    };
  },
  methods: {
    loadUsersForServer(directAdminUrl) {
      this.isServerSelected = true;
      directAdminService.getDirectAdminUsers(directAdminUrl);
    },
    async saveDirectAdmin(organisationId, directAdmin, index) {
      let currentName = directAdmin ? directAdmin.name : null;

      directAdminService.addOrUpdateDirectAdmin(
        currentName,
        this.selectedServer,
        organisationId,
        this.selectedDirectName
      );
      await organisationService.getAllOrganisations();
      this.selectedServer = "";
      this.selectedDirectName = "";

      $("#alert")
        .fadeTo(4000, 500)
        .slideUp(500, function () {
          $("#alert").slideUp(500);
        });
      $("#alert").empty();
      $("#alert").append("<span>Koppeling aangemaakt!</span>");
    },
    open(event, directAdmin) {
      this.selectedServer = "";
      this.selectedDirectName = "";
      this.isServerSelected = false;

      if (
        event.target.getAttribute("aria-expanded") == "true" &&
        directAdmin != null
      ) {
        this.selectedServer = directAdmin.serverUrl;
        this.selectedDirectName = directAdmin.name;
        this.loadUsersForServer(directAdmin.serverUrl);
      }
    },
    loadData() {
      organisationService.getAllOrganisations();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
#alert {
  position: fixed;
  right: 1rem;
  z-index: 999;
}
</style>
