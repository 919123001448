<template>
  <div>
    <!-- <div v-if="!this.loaded" class="spinner-wrapper">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div> -->
    <div class="admin">
      <div class="adminHeader mt-4">
        <div class="container">
          <h4>Admin dashboard</h4>
          <h2>
            {{ this.timeOfDay }},
            <span class="highlightUnderline">{{
              this.$store.state.user.firstname
            }}</span>
          </h2>
        </div>
      </div>
      <div class="statistics">
        <div class="container">
          <div class="row mt-5">
            <div class="col-sm-4">
              <div class="card">
                <div class="card-body text-center">
                  <h3>{{ this.$store.state.allUsersCount }}</h3>
                  <span>klanten</span>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="card">
                <div class="card-body text-center">
                  <h3>{{ this.$store.state.allOrganisationsCount }}</h3>
                  <span>organisaties</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actionCards">
        <div class="container">
          <div class="row mt-5">
            <div class="col-lg-4">
              <div class="card adminCard">
                <div class="card-body">
                  <h5 class="card-title">Nieuwe organisaties toevoegen</h5>
                  <p class="card-text">
                    Alle nieuwe organizaties vanuit simplicate met een druk op
                    de knop helemaal geconfigureerd.
                  </p>
                </div>
                <div class="card-footer text-muted">
                  <router-link
                    to="/admin/organisaties"
                    class="btn btn-primary mt-3"
                    >Bekijk pagina</router-link
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card adminCard">
                <div class="card-body">
                  <h5 class="card-title">Accounts beheren</h5>
                  <p class="card-text">Beheer alle account per organisatie</p>
                </div>
                <div class="card-footer text-muted">
                  <router-link to="/admin/accounts" class="btn btn-primary mt-3"
                    >Bekijk pagina</router-link
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card adminCard">
                <div class="card-body">
                  <h5 class="card-title">Direct Admin hosting beheren</h5>
                  <p class="card-text">
                    Beheer de url en de naam voor de API van Direct Admin
                  </p>
                </div>
                <div class="card-footer text-muted">
                  <router-link
                    to="/admin/direct-admin"
                    class="btn btn-primary mt-3"
                    >Bekijk pagina</router-link
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card adminCard">
                <div class="card-body">
                  <h5 class="card-title">Standup</h5>
                  <p class="card-text">Bekijk standup view</p>
                </div>
                <div class="card-footer text-muted">
                  <router-link to="/admin/standup" class="btn btn-primary mt-3"
                    >Bekijk pagina</router-link
                  >
                  <router-link to="/admin/standup/config" class="btn btn-primary mt-3 ms-1"
                    >Personen configureren</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card adminCard">
                <div class="card-body">
                  <h5 class="card-title">Thema repo beheren</h5>
                  <p class="card-text">Beheer de thema repo's</p>
                </div>
                <div class="card-footer text-muted">
                  <router-link to="/admin/repos" class="btn btn-primary mt-3"
                    >Bekijk pagina</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card adminCard">
                <div class="card-body">
                  <h5 class="card-title">Gebruiker aankopen</h5>
                  <p class="card-text">Bekijk de aankopen van gebruikers</p>
                </div>
                <div class="card-footer text-muted">
                  <router-link to="/admin/purchases" class="btn btn-primary mt-3"
                    >Bekijk pagina</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card adminCard">
                <div class="card-body">
                  <h5 class="card-title">Strippenkaart tarief</h5>
                  <p class="card-text">Stel voor strippenkaart projecten een speciaal tarief in</p>
                </div>
                <div class="card-footer text-muted">
                  <router-link to="/admin/punch-card-tariff" class="btn btn-primary mt-3"
                    >Bekijk pagina</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card adminCard">
                <div class="card-body">
                  <h5 class="card-title">Wordpress updates email</h5>
                  <p class="card-text">Verstuur de email naar alle klanten van de Wordpress updates</p>
                </div>
                <div class="card-footer text-muted">
                  <router-link to="/admin/wordpress-updates-mail" class="btn btn-primary mt-3"
                    >Bekijk pagina</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import organisationService from "../../service/admin/OrganisationService";
import userService from "../../service/admin/UserService";

export default {
  name: "Admin",
  data() {
    return {
      timeOfDay: "",
      loaded: false,
      amountUsers: 0,
      amountOrganizations: 0,
    };
  },
  created() {
    this.getCurrentTimeOfDay();
    this.getOrganisationsCount();
    this.getUsersCount();
  },
  methods: {
    getCurrentTimeOfDay() {
      var today = new Date();
      var curHr = today.getHours();

      if (curHr < 12) {
        this.timeOfDay = "Goedenmorgen";
      } else if (curHr < 18) {
        this.timeOfDay = "Goedenmiddag";
      } else {
        this.timeOfDay = "Goedenavond";
      }
    },
    getOrganisationsCount() {
      organisationService.getAllOrganisationsCount();
    },
    getUsersCount() {
      userService.getUsersCount();
    },
  },
};
</script>

<style scoped lang="scss">
.admin {
  padding-bottom: 2rem;
}
.adminCard {
  min-height: 200px;
}
.actionCards .col-lg-4 {
  margin-bottom: 1rem;
}

.spinner-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(50vh - 6rem);

  .spinner-border {
    height: 3rem;
    width: 3rem;
  }
}
</style>
