import axios from "axios"
import { store } from '../../store'
import router from '../../router'

export default {
    async getGitUsers() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/git/users').then((res) => {
            store.state.gitUsers = res.data['data']['users'];
        });
    },
    async getGitGroups() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/git/groups').then((res) => {
            store.state.allGitGroups = res.data['data']['groups'];
        });
    },
    async getReposForGroup(groupId) {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/git/repos/' + groupId).then((res) => {
            store.state.reposFromGroup = res.data['data']['repos'];
        });
    },
    
}