<template>
    <div class="syncOverview">
      <div class="syncOverviewHeader">
        <div class="container">
          <h1>Sync overview</h1>
          <p>Synchronisatie dashboard</p>
        </div>
      </div>
      <component v-bind:is="component" />
    </div>
</template>
  
<script>
    import { useOrganisationComponent } from "@/service/organisation/organisation";

    export default {
        async created() {
            this.$store.state.promiseApp.then(() => {
                this.component = useOrganisationComponent(this.components, this.$store);
            });
        },

        data (){
            return {
                component: "",
            }
        },

        computed: {
            components () {
                const components = require.context('@/views/organisation', true, /SyncOverview.vue/);
                return components.keys().map(x => components(x).default)
            }
        }
    };
</script>
      
<style lang="scss" scoped>
    .syncOverviewHeader {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
</style>