import axios from "axios"
import { store } from '../store'
import router from '../router'
import organisationService from "./OrganisationService"
export default {
    async fetchUser() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'user').then((res) => {
            store.commit("setUser", res.data["data"]["user"]);
            if (res.data["data"]["user"]["roles"].includes("ROLE_ADMIN")) {
                store.commit("setUserIsAdmin", true);
            }
        }).catch((err) => {
            console.log(err);
        })
    },
    async updateUser(data) {
        return await axios.patch(process.env.VUE_APP_ROOT_API + 'user', data).then((res) => {
            store.commit("setUser", res.data["data"]["user"]);
            if (res.data["data"]["user"]["roles"].includes("ROLE_ADMIN")) {
                store.commit("setUserIsAdmin", true);
            }
        }).catch((err) => {
            console.log(err);
        })
    },
    async switchActiveOrganisation(id) {
        return await axios.patch(process.env.VUE_APP_ROOT_API + 'organisation/update/' + id).then((res) => {
            window.location.reload()
        }).catch((err) => {
            console.log(err);
        });
    }
}