import axios from "axios";
import { store } from '../store'

export default {
    async getHosting(sla) {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'hosting').then((res) => {
            if (res.data['data']['hosting_services']) {
                let maintenance = res.data['data']['hosting_services']['maintenance'];
                store.state.maintenace = maintenance;
                let domains = res.data['data']['hosting_services']['domains'];
                
                if (sla) {
                    domains.map((domain) => {
                        domain['name'] = domain['name'].split(" ").pop();
                    })
                }

                store.state.domains = domains;
                let hosting = res.data['data']['hosting_services']['hosting'];
                store.state.hosting = hosting;
                let ssl = res.data['data']['hosting_services']['ssl'];
                store.state.ssl = ssl;
                let others = res.data['data']['hosting_services']['others'];
                store.state.others = others;
                store.state.hostingPartsCount =
                    Object.keys(maintenance).length
                    + Object.keys(hosting).length
                    + Object.keys(ssl).length
                    + Object.keys(domains).length
                    + Object.keys(others).length
            }
            store.state.hostingLoaded = true;
        }).catch((err) => {
            store.state.hostingLoaded = true;
            console.log(err);
        });
    },
    async upgradeMaintenance(name) {
        let data = { name: name }
        return await axios.post(process.env.VUE_APP_ROOT_API + 'hosting/upgrade/maintenance', data).then((res) => {
        }).catch((err) => {
            store.state.hostingLoaded = true;
            console.log(err);
        });
    },
    async upgradeHosting(name, hostingPackage) {
        let data = { name: name, hosting: hostingPackage };
        return await axios.post(process.env.VUE_APP_ROOT_API + 'hosting/upgrade/hosting', data).then((res) => {
        }).catch((err) => {
            store.state.hostingLoaded = true;
            console.log(err);
        });
    },
    async stopDomain(domainName) {
        let data = { name: domainName };
        return await axios.post(process.env.VUE_APP_ROOT_API + 'hosting/stop/domain', data).then((res) => {
        }).catch((err) => {
            store.state.hostingLoaded = true;
            console.log(err);
        });
    },
    async getDomainAvailability(domainName) {
        let data = { domain: domainName };
        return await axios.post(process.env.VUE_APP_ROOT_API + 'hosting/domain/availability', data).then((res) => {
            console.log(res.data);
            return res.data;
        }).catch((err) => {
            store.state.hostingLoaded = true;
            console.log(err);
        });
    },
    async claimDomain(domainName) {
        let data = { domain: domainName };
        return await axios.post(process.env.VUE_APP_ROOT_API + 'hosting/domain/claim', data).then((res) => {
            console.log(res.data);
            return res.data;
        }).catch((err) => {
            store.state.hostingLoaded = true;
            console.log(err);
        });
    },
}