<template>
  <div class="container mt-4 pb-4">
    <router-link to="/admin">Ga terug naar overzicht</router-link>
    <h2>Bekijk alle aankopen</h2>
    <div class="row mb-3">
      <button class="btn btn-primary col-2 me-2" @click="getPunchCards()">Strippenkaarten</button>
      <button class="btn btn-primary col-2" @click="getSubscriptions()">Abonnementen</button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Organisatie</th>
          <th scope="col">Service</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(punchCard, index) in this.shownList" v-bind:key="punchCard">
          <th>{{ index + 1 }} {{ punchCard.organization.name }}</th>
          <td>{{ punchCard.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import punchCardService from "../../service/admin/PunchCardService";
import subscriptionService from "../../service/admin/SubscriptionService";
export default {
  data() {
    return {
      shownList: [],
    };
  },
  async created() {
    await this.getPunchCards();
    this.shownList = this.$store.state.allPunchCardProjects;
  },
  methods: {
    async getPunchCards() {
      await punchCardService.getAllPunchCards();
      this.shownList = this.$store.state.allPunchCardProjects;
    },
    async getSubscriptions() {
      await subscriptionService.getAllSubscriptions();
      this.shownList = this.$store.state.allSubscriptionProjects;
    },
  },
};
</script>


<style lang="scss" scoped>
</style>