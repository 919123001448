import { createStore } from 'vuex'

export const store = createStore({
  state: {
    // Authentication / user
    user: JSON.parse(localStorage.getItem('user') || "[]"),
    isAuthenticated: localStorage.getItem('token') != null ? true : false,
    userFetched: false,
    dataFetched: false,
    isAdmin: false,
    selectOrganisation: localStorage.getItem('selectOrganisation') != null ? true : false,

    // organisations
    inActiveOrganisations: [],

    // Projects
    projects: [],
    gitRepos: [],
    repo: [],
    projectsLoaded: false,

    // Issues
    scheduledIssues: [],
    scheduledIssuesCount: 0,
    openIssues: [],
    openIssuesCount: 0,
    closedIssues: [],
    closedIssuesPaginated: [],
    closedIssuesPages: 0,
    estimatedIssues: [],
    dragIssuesEnabled: [],
    issue: [],
    issueDetailLoaded: false,
    discussion: [],
    openIssuesLoaded: false,
    estimatedIssuesLoaded: false,
    scheduledIssuesLoaded: false,

    // Punch card
    punchCards: [],
    punchCardRates: [],
    openCards: [],
    fullCards: [],
    hoursOnPunchCards: [],
    filterStrippenkaartArray: [],
    punchCardLoaded: false,

    // Hosting
    maintenace: [],
    hosting: [],
    domains: [],
    ssl: [],
    others: [],
    hostingPartsCount: 0,
    usage: [],
    hostingLoaded: false,

    // Subscription
    subscription: [],
    subscriptionProjectLead: [],
    subscriptionLoaded: false,

    // Invoices
    invoices: [],
    invoiceDetail: [],
    invoicesLoaded: false,

    // Organisations
    organisations: [],

    // file
    fileList: [],


    // admin
    allOrganisations: [],
    allOrganisationsCount: 0,
    newOrganisations: [],
    selectedOrganisationToAdd: [],
    gitUsers: [],
    allGitGroups: [],
    reposFromGroup: [],

    allUsersCount: 0,
    allUsers: [],
    directAdminUsers: [],

    allIssues: [],

    allPunchCardProjects: [],
    allSubscriptionProjects: [],

    promiseApp: null
  },
  getters: {
    getUserFetched: state => state.userFetched,
    getUser(state) {
      return state.user;
    }
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setUserFetched(state, data) {
      state.userFetched = data;
    },
    setDataFetched(state, data) {
      state.dataFetched = data;
    },
    setAuthenticated(state, data) {
      state.isAuthenticated = data
    },
    setUserIsAdmin(state, data) {
      state.isAdmin = data
    }

  },
  actions: {
    setUser(state, data) {
      state.user = data;
    }
  },
  modules: {
  }
})
