import { createI18n } from 'vue-i18n'

const mappingTypes = {
    nl: {
        "color": "kleuren",
        "material": "materialen",
        "category": "categorieën",
        "finishing": "afwerking",
        "upholstery": "bekleding",
        "size": "formaten",
        'location': 'bedrukking locaties',
        'imprint': 'imprint',
        'manufacturer': 'fabrikanten',
        'variant': 'varianten',
        "delivery": "levertijd",
        "couchtype": "banksoorten"
    }
}

const productSuppliers = {
    "eekhoorn": "Eekhoorn",
    "richmond": "Richmond",
    "zijlstra": "Zijlstra",
    "eleonora": "Eleonora",
    "byboo": "By-Boo",
    "falkross": "Falk & Ross",
    "promidata": "Promidata",
    "label51": "LABEL51",
    "livingfurn": "Livingfurn",
    "starfurn": "Starfurn",
    "tower-living": "Tower living",
    "unique-furniture": "Unique Furniture",
    "light-and-living": "Light & Living"
}

export const i18n = createI18n({
    locale: 'nl',
    messages: {
        nl: {
            "sync": {
                "mapping": {
                    "header": (ctx) => `Beheer de ${mappingTypes.nl[ctx.named('type')]} van ${productSuppliers[ctx.named('supplier')]}`,
                },
                "product": {
                    "header": (ctx) => `Beheer de producten van ${productSuppliers[ctx.named('supplier')]}`,
                }
            }
        }
    }
})