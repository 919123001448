<template>
  <div id="openIssuesBlock">
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-lg">
            <h4 class="openIssuesTitleHeader">Openstaande issues</h4>
          </div>
        </div>
      </div>
    </div>

    <issues-block-skeleton
      v-if="!this.$store.state.openIssuesLoaded"
    ></issues-block-skeleton>
    <template v-if="this.$store.state.openIssuesLoaded">
      <div class="issues" v-if="this.$store.state.openIssuesLoaded">
        <div class="container">
          <div class="">
            <p v-if="this.$store.state.openIssuesCount == 0">
              Er zijn geen openstaande issues
            </p>
            <div
              class="projectRow"
              v-for="(project, index) in this.$store.state.openIssues"
              v-bind:key="project"
            >
              <div class="labelWrapper" v-if="project.length > 0">
                <div class="label" style="background-color: rgb(105, 209, 0)">
                  <span>{{ index }}</span>
                </div>
                <div class="priorityButtons">
                  <div class="tooltipWrapper">
                    <img src="../../assets/question-mark.png" />
                    <span class="tooltiptext"
                      >Klik op de knop om issues te voorzien van een prioriteit.
                      Sleep vervolgens het issue in de gewenste volgorde en klik
                      vervolgens op opslaan. Je ziet nu dat er een
                      prioriteitsnummer in het issue komt, of dat het nummer
                      aangepast wordt op basis van de volgorde die je bepaald
                      hebt.
                    </span>
                  </div>
                  <button
                    v-if="!this.$store.state.dragIssuesEnabled[index]"
                    @click="setIssuesDraggable(index)"
                    class="btn btn-primary"
                  >
                    Issues prioriteren
                  </button>
                  <button
                    v-if="
                      this.$store.state.dragIssuesEnabled[index] &&
                      !this.savingPriority[index]
                    "
                    @click="this.savePriority(index)"
                    class="btn btn-primary"
                  >
                    Opslaan
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    disabled
                    v-if="this.savingPriority[index]"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only"> Opslaan...</span>
                  </button>
                  <button
                    v-if="this.$store.state.dragIssuesEnabled[index]"
                    @click="this.cancelPriority(index)"
                    class="btn btn-primary-outline ms-2"
                    :disabled="this.savingPriority[index]"
                  >
                    Annuleren
                  </button>
                </div>
              </div>
              <draggable
                :list="project"
                group="issues"
                @start="drag = true"
                @end="drag = false"
                :disabled="!this.$store.state.dragIssuesEnabled[index]"
                item-key="id"
                class="row"
              >
                <template #item="{ element }" class="">
                  <router-link
                    :to="
                      '/issues/' + element['project_id'] + '/' + element['iid']
                    "
                    class="issueBlockLink col-lg-4"
                    ><issue-block
                      :labelTextColor="'white'"
                      :labelBgColor="'#FF0000'"
                      :scheduledIssue="false"
                      :issue="element"
                      :projectId="element['project_id']"
                    ></issue-block>
                  </router-link>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import issueBlock from "../../components/issue/IssueBlock";
import issueService from "../../service/IssueService";
import draggable from "vuedraggable";
import IssuesBlockSkeleton from "../skeletons/issues/IssuesBlockSkeleton.vue";

export default {
  props: ["colsize"],
  components: {
    issueBlock,
    draggable,
    IssuesBlockSkeleton,
  },
  data() {
    return {
      columns: this.colsize,
      copyOfIssues: [],
      savingPriority: [],
      drag: false,
    };
  },
  methods: {
    async savePriority(projectIndex) {
      // this.savingPriority = true;
      this.savingPriority[projectIndex] = true;
      let issues = this.$store.state.openIssues[projectIndex];
      await issueService.setPriority(issues, projectIndex);
      this.$store.state.dragIssuesEnabled[projectIndex] = false;
      this.savingPriority[projectIndex] = false;
    },
    cancelPriority(projectIndex) {
      this.$store.state.openIssues[projectIndex] = this.copyOfIssues;
      this.$store.state.dragIssuesEnabled[projectIndex] = false;
    },
    setIssuesDraggable(projectIndex) {
      this.copyOfIssues = JSON.parse(
        JSON.stringify(this.$store.state.openIssues[projectIndex])
      );
      this.$store.state.dragIssuesEnabled[projectIndex] = true;
    },
    async getOpenIssues() {
      await issueService.getOpenIssues();
    },
    calculateAndSetHeight() {
      var maxHeight = -1;
      $("#openIssuesBlock .issueBlockLink").each(function () {
        let height = $(".issueTitle", this).innerHeight();
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      $("#openIssuesBlock .issueTitle").innerHeight(maxHeight);
    },
  },
  async created() {
    await this.getOpenIssues();
    this.calculateAndSetHeight();
  },
};
</script>

<style scoped lang="scss">
#openIssuesBlock {
  min-height: 5rem;
  margin-top: 4rem;
}
.labelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.label {
  background-color: #69d100;
  border-radius: 2rem;
  width: fit-content;
  padding: 0.1rem 0.7rem;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.openIssuesTitleHeader {
  margin-bottom: 2rem;
}

.issueBlockLink {
  color: black;
  text-decoration: none;
}

#issueBlock:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.priorityButtons .tooltipWrapper {
  margin-right: 1rem;

  position: relative;
  display: inline-block;

  img {
    width: 15px;
    opacity: 0.5;
  }
}

.tooltipWrapper .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: black;
  opacity: 0.9;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -125px;
  font-weight: normal;
}

.tooltipWrapper .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltipWrapper:hover .tooltiptext {
  visibility: visible;
}
@media screen and (max-width: 992px) {
  .openIssuesHeader {
    flex-direction: column;
  }
  .header {
    margin-top: 3rem;
  }

  .openIssuesTitleHeader {
    margin-bottom: 1rem;
  }
  .priorityButtons {
    text-align: left !important;
    margin-bottom: 1rem;

    .tooltipWrapper .tooltiptext {
      margin-left: -5px;
      border-top-left-radius: 0;
    }

    .tooltipWrapper .tooltiptext::after {
      left: 5px;
    }
  }
}
</style>