import axios from "axios"
import { store } from '../store'
import router from '../router'

export default {
    async getOrganisations() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'organisation').then((res) => {
            store.state.organisations = res.data['data']['organisations'];
            this.setNavBar();
        }).catch((err) => {
            console.log(err);
        })
    },
    async setOrganisation(id) {
        let data = {
            organisationId: id
        }
        return await axios.post(process.env.VUE_APP_ROOT_API + 'organisation/set', data).then((res) => {
            store.state.selectOrganisation = false;
            localStorage.removeItem('selectOrganisation');
            router.push({ path: "/dashboard" });
        }).catch((err) => {
            console.log(err);
        })
    },

    async setNavBar() {
        if (store.state.organisations.length > 1) {
            let activeOrganisationId =
                store.state.user["activeOrganisation"]["id"];
            let inActiveOrganisations = store.state.organisations.filter(
                (x) => {
                    return x.id != activeOrganisationId;
                }
            );
            store.state.inActiveOrganisations = inActiveOrganisations;
        }

        if (store.state.user["roles"].includes("ROLE_ADMIN")) {
            store.commit("setUserIsAdmin", true);
        }
    }
}