<template>
  <div class="container mt-4">
    <router-link to="/admin/accounts">Ga terug naar overzicht</router-link>
    <h2>Tarief toevoegen</h2>
    <div class="form-group">
      <label class="form-label">Klant naam</label>
      <input
        type="text"
        v-model="this.name"
        class="form-control mb-1"
        placeholder="Naam"
      />
    </div>
    <div class="form-group">
      <label class="form-label">Project id</label>
      <input
        type="text"
        v-model="this.projectId"
        class="form-control mb-1"
        placeholder="Project id"
      />
    </div>
    <div class="form-group">
      <label class="form-label">Tarief (Uur tarief)</label>
      <input
        type="text"
        v-model="this.tariff"
        class="form-control mb-1"
        placeholder="Tarief"
      />
    </div>

    <div class="form-group">
      <label class="form-label">Budget</label>
      <input
        type="text"
        v-model="this.budget"
        class="form-control mb-1"
        placeholder="Budget"
      />
    </div>

    <div class="form-group">
      <label class="form-label">Prijs</label>
      <input
        type="text"
        v-model="this.price"
        class="form-control mb-1"
        placeholder="Prijs"
      />
    </div>
    <button v-on:click="addTariff()" class="btn btn-primary mt-4">
      Tarief toevoegen
    </button>
  </div>
</template>

<script>
import PunchCardService from '../../service/admin/PunchCardService';
export default {
  data() {
    return {
      name: "",
      projectId: "",
      tariff: 0,
      budget: 0,
      price: 0,
    };
  },
  methods: {
    async addTariff() {
     let data = {
        name: this.name,
        projectId: this.projectId,
        tariff: this.tariff,
        budget: this.budget,
        price: this.price
      }
      await PunchCardService.createNewRate(data);
      this.$router.push({ path: "/admin/punch-card-tariff" });
    },
  },
};
</script>

<style scoped></style>
