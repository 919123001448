<template>
  <div class="container">
    <div class="projectRow row">
      <div
        class="col-lg-4"
        v-for="issue in this.$store.state.closedIssuesPaginated"
        v-bind:key="issue"
      >
        <router-link
          :to="'/issues/' + issue['project_id'] + '/' + issue['iid']"
          class="issueBlockLink"
          >
          <issue-block
            :labelBgColor="'#69d100'"
            :scheduledIssue="false"
            :issue="issue"
            :projectId="issue['project_id']"
          ></issue-block>
        </router-link>
      </div>
    </div>

    <v-pagination
      v-model="page"
      :pages="this.$store.state.closedIssuesPages"
      :range-size="1"
      active-color="#DCEDFF"
      @update:modelValue="updateHandler"
    />
  </div>
</template>

<script>
import issueService from "../../service/IssueService";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import issueBlock from "../../components/issue/IssueBlock";

export default {
  components: {
    VPagination,
    issueBlock,
  },
  data() {
    return {
      perPage: 9,
      page: 1,
      closedIssues: [],
    };
  },
  async created() {
    await this.getClosedIssues();
    this.calculateAndSetHeight();
  },
  methods: {
    calculateAndSetHeight() {
      var maxHeight = -1;
      $("#openIssuesBlock .issueBlockLink").each(function () {
        let height = $(".issueTitle", this).innerHeight();
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      $("#openIssuesBlock .issueTitle").innerHeight(maxHeight);
    },
    updateHandler() {
      this.$store.state.closedIssuesPaginated = JSON.parse(
        JSON.stringify(this.$store.state.closedIssues)
      );
      this.$store.state.closedIssuesPaginated =
        this.$store.state.closedIssuesPaginated.slice(
          (this.page - 1) * this.perPage,
          this.page * this.perPage
        );

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getClosedIssues() {
      await issueService.getClosedIssues();
      this.updateHandler();
    },
  },
};
</script>

<style lang="scss" scoped>
  .issueBlockLink {
    color: black;
    text-decoration: none;
  }
</style>