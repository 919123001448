import axios from "axios"
import { store } from '../store'
import router from '../router'
export default {
    async getPunchCard() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'punch-card').then((res) => {
            if (Object.keys(res.data['data']).length != 0) {
                store.state.openCards = res.data['data']['punch_card']['open']
                store.state.filterStrippenkaartArray = res.data['data']['punch_card']['open']
                store.state.fullCards = res.data['data']['punch_card']['full']
            }
            store.state.punchCardLoaded = true
        }).catch((err) => {
            store.state.punchCardLoaded = true
            console.log(err);
        })
    },
    async addPunchCard(projectId, index) {
        let data = {
            project_id: projectId
        }
        return await axios.post(process.env.VUE_APP_ROOT_API + 'punch-card', data).then((res) => {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + "-" + dd + "-" + yyyy;

            let oldStripticket = store.state.openCards[index];

            store.state.fullCards.push(oldStripticket);
            store.state.openCards.splice(index, 1);

            store.state.openCards.push({
                hours: 0,
                name: "Strippenkaart website doorontwikkeling " + today,
                id: res.data["id"],
            });

        }).catch((err) => {
            console.log(err);
        })
    },
    async addFirstPunchCard() {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'punch-card/first').then((res) => {
            let punchCard = res.data['data']['punch_card'];
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();

            today = dd + "-" + mm + "-" + yyyy;

            store.state.filterStrippenkaartArray.push({
                hours: 0,
                name: "Strippenkaart website doorontwikkeling " + today,
                id: punchCard["id"],
            });

            store.state.openCards.push({
                hours: 0,
                name: "Strippenkaart website doorontwikkeling " + today,
                id: punchCard["id"],
            });
        }).catch((err) => {
            console.log(err);
        })
    },
    async getPunchCardHours(id) {
        return await axios
            .post(
                process.env.VUE_APP_ROOT_API + "punch-card/hours",
                {
                    id: id
                },
            )
            .then((res) => {
                store.state.hoursOnPunchCards[id] = res.data
            }).catch((err) => {
                console.log(err);
            });
    }
}