<template>
  <div id="projects">
    <div class="projectsHeader">
      <div class="container">
        <h1 class="projectsHeaderTitle">Projecten</h1>
        <p class="projectsCount"></p>
      </div>
    </div>

    <div class="projectRows">
      <div class="container">
        <div v-for="n in 3" v-bind:key="n">
          <div class="projectRow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.projectsHeaderTitle {
  margin-top: 3.5rem;
}

.projectsCount {
  background-color: #ddd;
  animation: pulse-bg 2s infinite;
  width: 350px;
  content: "";
  height: 20px;
}

.projectRows {
  margin-top: 3rem;
}
.projectRow {
  background-color: #ddd;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 4.5rem;
  animation: pulse-bg 2s infinite;
}
</style>
