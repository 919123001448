<template>
    <div class="container">
        <div class="row">
            <SyncCard title="Kleuren" description="Beheer de kleuren van Falk & Ross">
                <router-link to="/sync/mapping/falkross/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
            </SyncCard>

            <SyncCard title="Maten" description="Beheer de maten van Falk & Ross">
                <router-link to="/sync/mapping/falkross/size" class="btn btn-primary">Bekijk de maten sync</router-link>
            </SyncCard>
        </div>
    </div>
</template>
  
<script>
    import SyncCard from '../../Sync/SyncCard.vue';
    import { CityPlotter } from '../../../service/organisation/organisation'

    export default Object.assign(CityPlotter, {
        components: {
            SyncCard
        }
    });
</script>