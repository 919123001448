<template>
  <div id="preloader">
    <img
      src="../../assets/gif/gif-short2.gif"
      alt="this slowpoke moves"
      width="350"
    />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
#preloader {
  background-color: #eff6f8;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
