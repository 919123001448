<template>
  <div id="sync">
    <div class="syncHeader">
      <div class="container">
        <h1 class="title">Sync management</h1>
        <p>{{ $t('sync.product.header', this) }}<br/>Selecteer de producten die gesynchroniseerd moeten worden naar Picqer</p>
      </div>
    </div>

    <div class="syncData pb-5 mt-4" v-if="dataLoaded">
        <div class="container">
          <div class="productActionContainer">
            <div class="input-group mb-5">
              <input type="text" class="form-control" placeholder="Product zoeken..." @keyup="submitSearchQuery" v-model="searchQuery">
              <button @click="saveMapping" class="btn btn-primary">Opslaan</button>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th><input type="checkbox" @change="toggleAllCheckboxes"></th>
                <th>Product naam</th>
                <th>SKU</th>
                <th>Bestelhoeveelheid</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(product, index) in products" >
                <tr v-if="!product.hide">
                  <td><input @click="checkRadioButton" class="product-checkbox" :data-index="index" type="checkbox" v-model="product.enabled"></td>
                  <td>{{product.name}}</td>
                  <td>{{product.sku}}</td>
                  <td>{{product.bestelhoeveelheid}}</td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="productActionContainer">
            <div class="input-group mt-5">
              <input type="text" class="form-control" placeholder="Product zoeken..." @keyup="submitSearchQuery" v-model="searchQuery">
              <button @click="saveMapping" class="btn btn-primary">Opslaan</button>
            </div>
          </div>
        </div>
    </div>

    <div class="spinner-wrapper" v-if="!dataLoaded">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
</template>

<script>
import syncService from "../../service/organisation/4uDesigned/SyncService";
export default {
  props: [
    'supplier'
  ],
  components: {
  },
  data() {
    return {
      dataLoaded: false,
      searchQuery: "",
      lastCheckedRadioButton: null,
      products: [],
      oldData: []
    };
  },
  async created() {
    syncService.getProducts(this.supplier).then(res => {
      this.products = res[this.supplier];
      this.oldData = JSON.parse(JSON.stringify(this.products)); // Deep copy the array
      this.dataLoaded = true;
    });
  },
  methods: {
    toggleAllCheckboxes(e) {
      const state = e.target.checked;
      this.products.forEach(product => {
        if(product.hide) return;
        product.enabled = state;
      });
    },
    submitSearchQuery(e) {
      this.products.forEach(product => {
          product.hide = !(product.name.toLowerCase().includes(e.target.value.toLowerCase()) || product.sku.toLowerCase().includes(e.target.value.toLowerCase()))
      });
    },
    checkRadioButton(e) {
      if(!this.lastCheckedRadioButton) {
        this.lastCheckedRadioButton = e.target;
        return;
      }

      if (e.shiftKey) {
        var start = this.lastCheckedRadioButton.dataset.index;
        var end = e.target.dataset.index;

        this.products.slice(Math.min(start,end), Math.max(start,end)+ 1).forEach(product => {
          product.enabled = this.lastCheckedRadioButton.checked
        });
      }

      this.lastCheckedRadioButton = e.target;
    },
    saveMapping() {
      this.dataLoaded = false;

      var payload = [];
      this.products.forEach((product, index) => {
        if(product.enabled != this.oldData[index].enabled) {
          payload.push({
            key: product.sku,
            value: product.enabled
          });
        }
      });

      syncService.addMapping(this.supplier, 'product', {payload: payload}).then(res => {
        this.oldData = JSON.parse(JSON.stringify(this.products)); // Deep copy the array
        this.dataLoaded = true;
      });
    }
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss" scoped>
.spinner-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(50vh - 6rem);

  .spinner-border {
    height: 3rem;
    width: 3rem;
  }
}

.productActionContainer {
  margin-left: auto;
  width: 400px;
  max-width: 100%;
}
</style>