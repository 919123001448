export function useOrganisationComponent(components, store) {
  return components.find((components) => components.simplicateId === store.state.user.activeOrganisation.simplicateId);
}

export const _4uDesigned = {
  simplicateId: 'organization:53d6b686bdf81fd65364a764ef2cfac5'
}

export const CityPlotter = {
  simplicateId: 'organization:a4c0874ea3a486bf5364a764ef2cfac5'
}

export const Promotijgers = {
  simplicateId: 'organization:4fb1849f616b92025364a764ef2cfac5'
}

export const syncOrganisations = {
  _4uDesigned, CityPlotter, Promotijgers
}