<template>
  <div class="container">
    <div id="login">
      <div class="loginWrapper">
        <form class="loginForm">
          <div class="passwordResetContent">
            <img class="mdLogo" src="../../../assets/md_hres.png" />
            <div class="passwordResetInstuction">
              <p class="headTitle">Controleer je e-mail</p>
              <p>
                Wij hebben een wachtwoord herstelmail met instructies naar je
                gemaild.
              </p>
            </div>

            <div class="imageHouseWrapper">
              <img
                class="imageHouse"
                src="../../../assets/email_without_computer.svg"
              />
            </div>
            <router-link to="/login" class="btn btn-primary loginButton">
              Terug naar inloggen
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
  },
};
</script>

<style lang="scss" scoped>
#app {
  background-image: url("../../../assets/background_md_55.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  padding-bottom: 0;
}
#login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loginWrapper {
  width: 75%;
  min-height: 25rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
  padding: 1.5rem 4rem;
  display: flex;
  height: 100%;

  form {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.passwordResetInstuction {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  .headTitle {
    margin-bottom: 0;
    font-weight: bold;
  }

  p {
    width: 50%;
    margin: auto;
    line-height: 1.3rem;
  }
}

.imageHouse {
  max-width: 8rem;
}

.imageHouseWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 2rem;
}
.keepLoggedIn {
  font-size: 11px;
  color: #888888;
}

.loginButton {
  width: 60%;
  display: block;
  margin: auto;
}

.mdLogo {
  width: 6rem;
}

@media screen and (max-width: 992px) {
  .loginButton {
    width: 85%;
  }
  .imageHouseWrapper {
    justify-content: center;
    margin-top: 2rem;
  }

  .passwordResetInstuction p {
    width: 85%;
  }

  .loginWrapper {
    width: 95%;
    padding: 1rem;
  }

  .mdLogo {
    width: 4rem;
  }
}
</style>
