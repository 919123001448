<template>
  <div class="container mt-4">
    <div
      id="alert"
      style="display: none"
      class="alert alert-success"
      role="alert"
    ></div>
    <router-link to="/admin">Ga terug naar overzicht</router-link>
    <h2>Beheer alle default thema repo's</h2>
    <div class="accordion py-3" id="accordionExample">
      <div
        class="accordion-item strippenkaartRow"
        v-for="(organisation, index) in this.$store.state.allOrganisations"
        v-bind:key="organisation"
      >
        <p class="accordion-header" :id="'heading' + index">
          <button
            class="accordion-button collapsed row"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + index"
            aria-expanded="false"
            :aria-controls="'collapse' + index"
            @click="
              open(
                organisation.gitlabGroupId,
                $event,
                organisation.gitlabStandardRepoId,
                organisation.gitlabStandardRepoName
              )
            "
          >
            {{ organisation.name }} - {{ organisation.gitlabStandardRepoName }}
          </button>
        </p>
        <div
          :id="'collapse' + index"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + index"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body" v-if="this.loaded">
            <select v-model="this.selectedProject">
              <option
                v-for="project in this.$store.state.reposFromGroup"
                v-bind:key="project"
                :value="{ project_id: project.id, project_name: project.name }"
              >
                {{ project.name }}
              </option>
            </select>
            <button
              @click="saveDefaultRepo(organisation.id, index)"
              class="d-block mt-3 btn btn-primary"
            >
              opslaan
            </button>
          </div>

          <div class="accordion-body" v-if="!this.loaded">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import organisationService from "../../service/admin/OrganisationService";
import gitService from "../../service/admin/GitService";
export default {
  data() {
    return {
      loaded: false,
      selectedProject: "",
      selectedGroup: "",
    };
  },
  methods: {
    async saveDefaultRepo(organisationId, index) {
      let data = {
        repo_id: this.selectedProject.project_id,
        repo_name: this.selectedProject.project_name,
      };

      await organisationService.updateDefaultRepo(organisationId, data);

      this.$store.state.allOrganisations[index]["gitlabStandardRepoId"] =
        this.selectedProject.project_id;
      this.$store.state.allOrganisations[index]["gitlabStandardRepoName"] =
        this.selectedProject.project_name;

      $("#alert")
        .fadeTo(4000, 500)
        .slideUp(500, function () {
          $("#alert").slideUp(500);
        });
      $("#alert").empty();
      $("#alert").append("<span>Repo aangepaast!</span>");
    },
    open(gitlabGroupId, event, gitlabDefaultRepoId, gitlabDefaultRepoName) {
      if (event.target.getAttribute("aria-expanded") == "true") {
        this.loaded = true;
        this.$store.state.reposFromGroup = [];
        gitService.getReposForGroup(gitlabGroupId);
        this.selectedProject = {
          project_id: gitlabDefaultRepoId,
          project_name: gitlabDefaultRepoName,
        };
      }
    },
    loadData() {
      organisationService.getAllOrganisations();
    },
  },
  created() {
    this.loadData();
  },
};
</script>
<style lang="scss" scoped>
#alert {
  position: fixed;
  right: 1rem;
  z-index: 999;
}
</style>