<template>
    <div class="container mt-4">
      <h1>Wordpress updates mail</h1>
      <p class="w-50">Verstuur een email naar de contactpersonen van de wordpress onderhoud klanten wanneer de websites zijn geupdate</p>
      <button class="btn btn-primary">Verstuur emails</button>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>