import axios from "axios"
import { store } from '../../store'
import router from '../../router'

export default {
    async getAllOrganisations() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/organisations').then((res) => {
            store.state.allOrganisations = res.data['data']['organisations'];
        }).catch((err) => {
            console.log(err);
            throw err;
        })
    },
    async getAllOrganisationsCount() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/organisations/count').then((res) => {
            store.state.allOrganisationsCount = res.data['data']['organisations'];
        }).catch((err) => {
            console.log(err);
            throw err;
        })
    },
    async getNewOrganisations() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/organisations/new').then((res) => {
            store.state.newOrganisations = res.data['data']['organisations'];
        }).catch((err) => {
            console.log(err);
            throw err;
        })
    },
    async createOrganisation(data) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'admin/organisation', data).then((res) => {
          
        }).catch((err) => {
            console.log(err);
            throw err;
        })
    },
    async updateDefaultRepo(organisationId, data) {
        return await axios.post(process.env.VUE_APP_ROOT_API + `admin/organisation/${organisationId}/default-repo`, data).then((res) => {
        }).catch((err) => {
            console.log(err);
            throw err;
        })
    }
}