<template>
  <div class="container">
    <div id="login">
      <div class="loginWrapper">
        <form class="row loginForm">
          <div class="col-lg">
            <img class="mdLogo" src="../../assets/md_hres.png" />
            <div class="form-group emailInputRow">
              <label for="emailInput" class="col-form-label">E-MAILADRES</label>
              <input
                v-model="email"
                type="email"
                class="form-control"
                id="emailInput"
                aria-describedby="emailHelp"
                placeholder="vul je e-mail adres in"
              />
            </div>
            <p v-if="noEmail" class="text-danger error">Geen email ingevuld</p>
            <div class="form-group passwordInputRow">
              <label for="passwordInput">WACHTWOORD</label>
              <input
                v-model="password"
                type="password"
                class="form-control"
                id="passwordInput"
                placeholder="vul je wachtwoord in"
              />

              <p v-if="noPassword" class="text-danger error">
                Geen wachtwoord ingevuld
              </p>
            </div>
            <p v-if="this.loginError" class="error text-danger">
              Het e-mail adres of wachtwoord lijkt onjuist te zijn.
            </p>
            <p v-if="this.anyLoginError" class="error text-danger">
              Er is iets fout gegaan
            </p>

            <div class="form-check keepLoggedInRow">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                v-model="this.rememberCredentials"
                id="flexCheckDefault"
              />
              <label
                class="form-check-label keepLoggedIn"
                for="flexCheckDefault"
              >
                Onthoud mijn gegevens
              </label>
            </div>

            <button
              v-if="!this.sendRequest"
              type="submit"
              @click.prevent="this.login()"
              class="btn btn-primary loginButton"
            >
              Inloggen
            </button>
            <button
              class="btn btn-primary loginButton"
              type="button"
              disabled
              v-if="this.sendRequest"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only"> Inloggen...</span>
            </button>

            <router-link class="forgotPassword" to="/wachtwoord-vergeten"
              >Ben je je wachtwoord vergeten?</router-link
            >
          </div>
          <div class="col-lg imageHouseWrapper">
            <img class="imageHouse" src="../../assets/login_house.svg" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import authService from "../../service/AuthService";
import organisationService from "../../service/OrganisationService";
export default {
  data() {
    return {
      email: "",
      password: "",
      rememberCredentials: false,
      sendRequest: false,
      loginError: false,
      noEmail: false,
      noPassword: false,
      anyLoginError: false,
    };
  },
  mounted() {
    this.$store.state.userFetched = true;
  },
  methods: {
    async login() {
      this.noEmail = false;
      this.noPassword = false;
      this.anyLoginError = false;

      if (this.email == "") {
        this.noEmail = true;
      }
      if (this.password == "") {
        this.noPassword = true;
      }

      if (this.noEmail && this.noPassword) {
        this.loginError = false;
      }
      if (!this.noPassword && !this.noEmail) {
        this.sendRequest = true;

        let data = {
          email: this.email,
          password: this.password,
          rememberCredentials: this.rememberCredentials,
        };
        try {
          await authService.login(data, this.$route.params.nextUrl);
          await organisationService.getOrganisations();
          this.sendRequest = false;
        } catch (e) {
          console.log(e);
          this.loginError = true;
          this.sendRequest = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  background-image: url("../../assets/background_md_55.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  padding-bottom: 0;
}
#login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loginWrapper {
  width: 75%;
  min-height: 25rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
  padding: 1.5rem 4rem;
  display: flex;
  height: 100%;

  form {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.emailInputRow {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.emailInputRow label,
.passwordInputRow label {
  font-weight: bold;
  font-size: 12px;
}
.keepLoggedInRow {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.imageHouse {
  width: 85%;
}

.imageHouseWrapper {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.keepLoggedIn {
  font-size: 11px;
  color: #888888;
}
.loginForm .form-group.row .col {
  padding-left: 0;
  padding-right: 0;
}
.loginButton {
  width: 80%;
}

.error {
  font-weight: bold;
  font-size: 12px;
  margin-top: 0.5rem;
}

#passwordInput,
#emailInput {
  background-color: #fcfcfc;
  border: none;
  padding: 1rem 2rem;
}

.forgotPassword {
  font-size: 11px;
  margin-top: 1rem;
  display: block;
  margin-bottom: 1rem;
}

.mdLogo {
  width: 6rem;
}

@media screen and (max-width: 992px) {
  .imageHouseWrapper {
    justify-content: center;
    margin-top: 2rem;
  }

  .loginWrapper {
    width: 95%;
    padding: 1rem;
  }

  .mdLogo {
    width: 4rem;
  }
}
</style>