<template>
  <div
    id="estimatedIssues"
    :class="this.isSmallContainer ? 'small-container' : 'normal-container'"
    v-if="this.$store.state.estimatedIssues.length > 0"
  >
    <div class="header">
      <div class="container" style="">
        <div class="row scheduledIssuesHeader">
          <div class="col-lg">
            <h4 class="openIssuesTitleHeader">
              Wachten op klant akkoord issues
            </h4>
          </div>
          <div class="col-lg allIssuesLink" v-if="this.link">
            <router-link to="/issues" class="highlightBlue"
              >Alle issues <img src="../../assets/arrow_right.png"
            /></router-link>
          </div>
        </div>
      </div>
    </div>

    <issues-block-skeleton
      v-if="!this.$store.state.estimatedIssuesLoaded"
    ></issues-block-skeleton>
    <template v-if="this.$store.state.estimatedIssuesLoaded">
      <div class="issues">
        <div class="container">
          <div class="row">
            <p v-if="this.$store.state.estimatedIssues == 0">
              Er zijn geen ingeplande issues
            </p>
            <div
              v-bind:class="'col-lg-' + this.columns"
              v-for="issue in this.$store.state.estimatedIssues"
              v-bind:key="issue"
            >
              <router-link
                :to="'/issues/' + issue['project_id'] + '/' + issue['iid']"
                class="issueBlockLink"
                ><issue-block
                  :labelBgColor="'#69d100'"
                  :scheduledIssue="false"
                  :issue="issue"
                  :projectId="issue['project_id']"
                ></issue-block>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import issueService from "../../service/IssueService";
import issueBlock from "../../components/issue/IssueBlock";
import IssuesBlockSkeleton from "../skeletons/issues/IssuesBlockSkeleton.vue";
export default {
  props: ["smallContainer", "colsize", "showLink"],
  components: {
    issueBlock,
    IssuesBlockSkeleton,
  },
  data() {
    return {
      isSmallContainer: this.smallContainer,
      columns: this.colsize,
      link: this.showLink,
    };
  },
  async created() {
    await this.getEstimatedIssues();
    this.calculateAndSetHeight()
  },
  methods: {
    async getEstimatedIssues() {
      await issueService.getEstimatedIssues();
    },
    calculateAndSetHeight() {
      var maxHeight = -1;
      $("#estimatedIssues .issueBlockLink").each(function () {
        let height = $(".issueTitle", this).innerHeight();
        console.log(height);
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      $("#estimatedIssues .issueTitle").innerHeight(maxHeight);
    },
  },
};
</script>

<style scoped>
.small-container {
  max-width: 960px;
  margin: 0 auto;
  margin-top: 6rem;
}

#scheduledIssues {
  margin-top: 4rem;
  min-height: 5rem;
}

.openIssuesTitleHeader {
  margin-bottom: 2rem;
}

.issueBlockLink {
  color: black;
  text-decoration: none;
}

#issueBlock:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.allIssuesLink {
  text-align: right;
}
@media screen and (max-width: 992px) {
  .scheduledIssuesHeader {
    flex-direction: row;
  }

  .openIssuesTitleHeader {
    margin-bottom: 1rem;
  }
  .allIssuesLink {
    margin-bottom: 1rem;
    text-align: left;
  }

  .OpenIssuesBlockContent {
    margin-top: 3rem;
  }
}
</style>
