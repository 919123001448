<template>
  <div class="container">
    <div id="passwordReset">
      <div class="passwordResetWrapper">
        <form class="row resetForm">
          <div class="col-lg-8 passwordResetContent">
            <img class="mdLogo" src="../../../assets/md_hres.png" />
            <div class="passwordResetInstuction">
              <p class="headTitle">Wachtwoord vergeten</p>
              <p>
                Voer het e-mail adres in dat hoort bij je account en wij sturen
                je een e-mail met instructies om je wachtwoord opnieuw in te
                stellen.
              </p>
            </div>
            <div class="form-group emailInputRow">
              <label for="emailInput">E-MAIL ADRES</label>
              <input
                v-model="email"
                type="email"
                class="form-control"
                id="emailInput"
                aria-describedby="emailHelp"
                placeholder="Vul je e-mail adres in"
              />
            </div>
            <p v-if="noEmail" class="text-danger error">Geen email ingevuld</p>

            <button
              v-if="!this.sendRequest"
              type="submit"
              @click.prevent="resetPassword()"
              class="btn btn-primary resetButton"
            >
              Instructie mail versturen
            </button>
            <button
              class="btn btn-primary resetButton"
              type="button"
              disabled
              v-if="this.sendRequest"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only"> Instructie email versturen...</span>
            </button>
          </div>
          <div class="col-lg-4 imageHouseWrapper">
            <img class="imageHouse" src="../../../assets/email.svg" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { email } from '@vuelidate/validators';
import authService from "../../../service/AuthService";
export default {
  data() {
    return {
      email: "",
      sendRequest: false,
      noEmail: false
    };
  },
  methods: {
    async resetPassword() {
      if (this.email == "") {
        this.noEmail = true;
        return;
      }
      this.sendRequest = true;
      await authService.resetPassword(this.email);
      this.$router.push({ path: "/email-verstuurd" });
      this.sendRequest = false;
    },
  },
};
</script>
<style scoped lang="scss">
#passwordReset {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.passwordResetWrapper {
  width: 75%;
  min-height: 25rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
  padding: 1.5rem 4rem;
  display: flex;
  height: 100%;

  form {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.passwordResetContent {
  padding-right: 6rem;
}

.passwordResetInstuction {
  margin-top: 2rem;

  .headTitle {
    margin-bottom: 0;
    font-weight: bold;
  }

  p {
    width: 90%;
    line-height: 1.3rem;
  }
}

.emailInputRow {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.emailInputRow label {
  font-weight: bold;
  font-size: 12px;
}
.keepLoggedInRow {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.imageHouse {
  width: 100%;
}

.imageHouseWrapper {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.loginForm .form-group.row .col {
  padding-left: 0;
  padding-right: 0;
}
.resetButton {
  width: 100%;
  height: 3rem;
}

.error {
  font-weight: bold;
  font-size: 12px;
  margin-top: 0.5rem;
}

#emailInput {
  background-color: #fcfcfc;
  border: none;
  padding: 1rem 2rem;
  width: 100%;
  text-align: center;
}
.mdLogo {
  width: 6rem;
}
@media screen and (max-width: 992px) {
  .passwordResetContent {
    padding-right: 0;
  }
  .imageHouseWrapper {
    justify-content: center;
    margin-top: 2rem;
  }

  .loginWrapper {
    width: 95%;
    padding: 1rem;
  }
  .mdLogo {
    width: 4rem;
  }
}
</style>
