import axios from "axios"
import { store } from '../store'
import router from '../router'

export default {
    async getMainDevGroup() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'git/group/main-dev').then((res) => { 
            store.state.subscriptionProjectLead = res.data['data']['main_dev'];
        }).catch((err) => {
            console.log(err);
        });
    }
}