import { CityPlotter } from '../../../service/organisation/organisation'
import axios from "axios"

export default Object.assign(CityPlotter, {
    async getValues(supplier, type) {
        return await axios.get(process.env.VUE_APP_ROOT_API_CITYPLOTTER + 'value/' + type).then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
    async getMappings(supplier, type) {
        return await axios.get(process.env.VUE_APP_ROOT_API_CITYPLOTTER + 'mapping/' + type).then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
    async addMapping(supplier, type, data) {
        return await axios.put(process.env.VUE_APP_ROOT_API_CITYPLOTTER + 'mapping/' + type, data).then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
});