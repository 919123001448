<template>
  <p>
    Je hebt momenteel {{ this.$store.state.projects.length }}
    {{ this.$store.state.projects.length > 0 ? "projecten" : this.$store.state.projects.length == 1 ? "project" : "projecten" }}
  </p>
</template>


<script>
export default {
  data() {
    return {
      projectCount: 0,
    };
  },
  created() {},
  methods: {},
};
</script>