<template>
  <div class="app-content">
    <navbar v-if="!$route.meta.hideNavbar"></navbar>
    <router-view v-if="!this.$store.state.selectOrganisation"></router-view>
    <pre-loader v-if="!this.$store.state.dataFetched"></pre-loader>
    <select-organisation
      v-if="this.$store.state.selectOrganisation"
    ></select-organisation>
  </div>
</template>
<script>
import navbar from "./components/Navbar";
import userService from "./service/UserService";
import organisationService from "./service/OrganisationService";
import preLoader from "./views/preLoader/PreLoader";
import selectOrganisation from "./views/selectOrganisation/SelectOrganisation";
export default {
  components: { navbar, preLoader, selectOrganisation },
  async beforeCreate() {
    let token = localStorage.getItem("token");

    if (token != null) {
      this.$store.state.promiseApp = new Promise(async (resolve, reject) => {
        let user = await userService.fetchUser();
        let organisations = await organisationService.getOrganisations();

        organisationService.setNavBar();

        this.$store.commit("setDataFetched", true);

        resolve();
      });
    } else {
      this.$store.state.promiseApp = Promise.resolve();
      this.$store.commit("setDataFetched", true);
    }
  },
};
</script>
<style lang="scss">
@import "bootstrap/scss/bootstrap";

$blue_color: #009fd9;
$orange_color: #ffc400;

#app {
  font-family: proxima-nova, Georgia, Serif;
  min-height: 100vh;
  background-color: #eff6f8;
  background-image: url("./assets/background_md_55.png");
  background-position: top; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-attachment: fixed;
}

h1,
h2 {
  font-family: "Noto Serif", serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.highlightUnderline {
  position: relative;
  z-index: 0;
}
.highlightUnderline:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 17%;
  width: 100%;
  height: 14%;
  background-color: #e9b22c;
  z-index: -1;
}

.highlightBlue {
  color: $blue_color !important;
  font-weight: bold !important;
  text-decoration: none;
}

.highlightBold {
  font-weight: bold !important;
  color: black;
}
.btn {
  padding: 0.6rem 1.5rem;
}

.btn-primary {
  background-color: $blue_color;
  border: 1px solid transparent;
  font-weight: bold;
  color: #ffffff;
}

.btn-primary:hover {
  color: $blue_color;
  font-weight: bold;
  border: 1px solid $blue_color;
  background-color: transparent;
}

.btn-primary-outline,
.btn-outline-primary {
  color: $blue_color;
  font-weight: bold;
  border-color: $blue_color;
}

.btn-primary-outline:hover,
.btn-outline-primary:hover {
  background-color: $blue_color;
  color: white;
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: #4b8ef1;
  border-color: #4b8ef1;
}

.router-link-active {
  font-weight: bold;
  color: black !important;
}

@media (max-width: 768px) {
  html,
  body {
    width: auto !important;
    overflow-x: hidden !important;
  }
}
</style>
