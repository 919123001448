<template>
    <div class="syncOverviewCards">
      <div class="container">
        <h2>De Eekhoorn</h2>
        <div class="row sync-cards">
          <SyncCard title="Kleuren" description="Beheer de kleuren van De Eekhoorn en Picqer">
            <router-link to="/sync/mapping/eekhoorn/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
          </SyncCard>
          <SyncCard title="Materialen" description="Beheer de materialen van De Eekhoorn en Picqer">
            <router-link to="/sync/mapping/eekhoorn/material" class="btn btn-primary">Bekijk de materialen sync</router-link>
          </SyncCard>
          <SyncCard title="Categorieën" description="Beheer de categorieën van De Eekhoorn en WooCommerce">
            <router-link to="/sync/mapping/eekhoorn/category" class="btn btn-primary">Bekijk de categorieën sync</router-link>
          </SyncCard>
          <SyncCard title="Producten" description="Beheer de producten van De Eekhoorn en Picqer">
            <router-link to="/sync/producten/eekhoorn" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
        </div>
  
        <h2>Richmond</h2>
        <div class="row sync-cards">
          <SyncCard title="Kleuren" description="Beheer de kleuren van Richmond en Picqer">
            <router-link to="/sync/mapping/richmond/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
          </SyncCard>
  
          <SyncCard title="Materialen" description="Beheer de materialen van Richmond en Picqer">
            <router-link to="/sync/mapping/richmond/material" class="btn btn-primary">Bekijk de materialen sync</router-link>
          </SyncCard>
  
          <SyncCard title="Categorieën" description="Beheer de categorieën van Richmond en WooCommerce">
            <router-link to="/sync/mapping/richmond/category" class="btn btn-primary">Bekijk de categorieën sync</router-link>
          </SyncCard>
  
          <SyncCard title="CSV Upload" description="Upload een CSV met levertijden">
            <form @submit.prevent="updateDeliveryTimes">
              <div id="alert"
                  style="display: none"
                  class="alert"
                  role="alert">
              </div>
              <div class="mb-4">
                <input class="form-control" type="file" accept=".csv" id="file" ref="file">
              </div>
              <button id="csv-button" class="btn btn-primary">
                <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
                Upload
              </button>
            </form>
          </SyncCard>
  
          <SyncCard title="Producten" description="Beheer de producten van Richmond en Picqer">
            <router-link to="/sync/producten/richmond" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
        </div>
  
        <h2>Zijlstra</h2>
        <div class="row sync-cards">
          <SyncCard title="Kleuren" description="Beheer de kleuren van Zijlstra en Picqer">
            <router-link to="/sync/mapping/zijlstra/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
          </SyncCard>
  
          <SyncCard title="Materialen" description="Beheer de materialen van Zijlstra en Picqer">
            <router-link to="/sync/mapping/zijlstra/material" class="btn btn-primary">Bekijk de materialen sync</router-link>
          </SyncCard>
  
          <SyncCard title="Categorieën" description="Beheer de categorieën van Zijlstra en WooCommerce">
            <router-link to="/sync/mapping/zijlstra/category" class="btn btn-primary">Bekijk de categorieën sync</router-link>
          </SyncCard>
  
          <SyncCard title="Producten" description="Beheer de producten van Zijlstra en Picqer">
            <router-link to="/sync/producten/zijlstra" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
        </div>
  
        <h2>Eleonora</h2>
        <div class="row sync-cards">
          <SyncCard title="Kleuren" description="Beheer de kleuren van Eleonora en Picqer">
            <router-link to="/sync/mapping/eleonora/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
          </SyncCard>
  
          <SyncCard title="Materialen" description="Beheer de materialen van Eleonora en Picqer">
            <router-link to="/sync/mapping/eleonora/material" class="btn btn-primary">Bekijk de materialen sync</router-link>
          </SyncCard>
  
          <SyncCard title="Categorieën" description="Beheer de categorieën van Eleonora en WooCommerce">
            <router-link to="/sync/mapping/eleonora/category" class="btn btn-primary">Bekijk de categorieën sync</router-link>
          </SyncCard>
  
          <SyncCard title="Producten" description="Beheer de producten van Eleonora en Picqer">
            <router-link to="/sync/producten/eleonora" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
  
          <SyncCard title="Afwerking" description="Beheer de afwerking van Eleonora en Picqer">
            <router-link to="/sync/mapping/eleonora/finishing" class="btn btn-primary">Bekijk de afwerking sync</router-link>
          </SyncCard>
  
          <SyncCard title="Bekleding" description="Beheer de bekleding van Eleonora en Picqer">
            <router-link to="/sync/mapping/eleonora/upholstery" class="btn btn-primary">Bekijk de bekleding sync</router-link>
          </SyncCard>
        </div>
  
        <h2>Byboo</h2>
        <div class="row sync-cards">
          <SyncCard title="Kleuren" description="Beheer de kleuren van Byboo en Picqer">
            <router-link to="/sync/mapping/byboo/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
          </SyncCard>
  
          <SyncCard title="Materialen" description="Beheer de materialen van Byboo en Picqer">
            <router-link to="/sync/mapping/byboo/material" class="btn btn-primary">Bekijk de materialen sync</router-link>
          </SyncCard>
  
          <SyncCard title="Categorieën" description="Beheer de categorieën van Byboo en WooCommerce">
            <router-link to="/sync/mapping/byboo/category" class="btn btn-primary">Bekijk de categorieën sync</router-link>
          </SyncCard>
  
          <SyncCard title="Producten" description="Beheer de producten van Byboo en Picqer">
            <router-link to="/sync/producten/byboo" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
  
          <SyncCard title="Afwerking" description="Beheer de afwerking van Byboo en Picqer">
            <router-link to="/sync/mapping/byboo/finishing" class="btn btn-primary">Bekijk de afwerking sync</router-link>
          </SyncCard>
        </div>

        <h2>LABEL51</h2>
        <div class="row sync-cards">
          <SyncCard title="Kleuren" description="Beheer de kleuren van LABEL51 en Picqer">
            <router-link to="/sync/mapping/label51/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
          </SyncCard>
  
          <SyncCard title="Materialen" description="Beheer de materialen van LABEL51 en Picqer">
            <router-link to="/sync/mapping/label51/material" class="btn btn-primary">Bekijk de materialen sync</router-link>
          </SyncCard>
  
          <SyncCard title="Categorieën" description="Beheer de categorieën van LABEL51 en WooCommerce">
            <router-link to="/sync/mapping/label51/category" class="btn btn-primary">Bekijk de categorieën sync</router-link>
          </SyncCard>
  
          <SyncCard title="Producten" description="Beheer de producten van LABEL51 en Picqer">
            <router-link to="/sync/producten/label51" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
  
          <SyncCard title="Levertijd" description="Beheer de levertijd van LABEL51 en Picqer">
            <router-link to="/sync/mapping/label51/delivery" class="btn btn-primary">Bekijk de levertijd sync</router-link>
          </SyncCard>

          <SyncCard title="Soort Bank" description="Beheer de soorten banken van LABEL51 en Picqer">
            <router-link to="/sync/mapping/label51/couchtype" class="btn btn-primary">Bekijk de soort bank sync</router-link>
          </SyncCard>
        </div>

        <h2>Livingfurn</h2>
        <div class="row sync-cards">
          <SyncCard title="Producten" description="Beheer de levertijden van Livingfurn">
            <router-link to="/sync/producten/livingfurn" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
        </div>

        <h2>Starfurn</h2>
        <div class="row sync-cards">
          <SyncCard title="Producten" description="Beheer de levertijden van Starfurn">
            <router-link to="/sync/producten/starfurn" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
        </div>

        <h2>Tower living</h2>
        <div class="row sync-cards">
          <SyncCard title="Producten" description="Beheer de levertijden van Tower living">
            <router-link to="/sync/producten/tower-living" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
        </div>

        <h2>Unique Furniture</h2>
        <div class="row sync-cards">
          <SyncCard title="Producten" description="Beheer de levertijden van Unique Furniture">
            <router-link to="/sync/producten/unique-furniture" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>
        </div>

        <h2>Light & Living</h2>
        <div class="row sync-cards">
          <SyncCard title="Producten" description="Beheer de producten van Light & Living en Picqer">
            <router-link to="/sync/producten/light-and-living" class="btn btn-primary">Bekijk de producten sync</router-link>
          </SyncCard>

          <SyncCard title="Categorieën" description="Beheer de categorieën van Light & Living en WooCommerce">
            <router-link to="/sync/mapping/light-and-living/category" class="btn btn-primary">Bekijk de categorieën sync</router-link>
          </SyncCard>

          <SyncCard title="Kleuren" description="Beheer de kleuren van Light & Living en Picqer">
            <router-link to="/sync/mapping/light-and-living/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
          </SyncCard>

          <SyncCard title="Materialen" description="Beheer de materialen van Light & Living en Picqer">
            <router-link to="/sync/mapping/light-and-living/material" class="btn btn-primary">Bekijk de materialen sync</router-link>
          </SyncCard>
          
          <SyncCard title="Afwerking" description="Beheer de afwerking van Light & Living en Picqer">
            <router-link to="/sync/mapping/light-and-living/finishing" class="btn btn-primary">Bekijk de afwerking sync</router-link>
          </SyncCard>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  import SyncCard from '../../Sync/SyncCard.vue';
  import { _4uDesigned } from '../../../service/organisation/organisation'
  import SyncService from '../../../service/organisation/4uDesigned/SyncService'

  export default Object.assign(_4uDesigned, {
    methods: {
      showAlert(message, type) {
        const className = "alert-" + type;
          $("#alert")
            .addClass(className)
            .fadeTo(4000, 500)
            .slideUp(500, function () {
              $("#alert").slideUp(500).removeClass(className);
            });
          $("#alert").empty();
          $("#alert").append("<span>" + message + "</span>");
      },
  
      async updateDeliveryTimes() {
        const file = this.$refs.file.files.item(0);
  
        if (file) {
          $('#csv-button').attr('disabled', 'disabled');
          $('#csv-button .spinner-border').removeClass('d-none');
          const fileReader = new FileReader();
          fileReader.onload = async (event) => {
            const payload = this.$papa.parse(event.target.result).data
              .slice(3)
              .filter(element => (element[0] && element[3]))
              .map(element => ({ key: element[0], value: element[3] }));
            const result = await SyncService.addMapping( 'richmond', 'delivery-time', { payload: payload });
            if (result) {
              this.showAlert("CSV is geupload", "success");
            } else {
              this.showAlert("Er ging iets mis", "danger");
            }
            $('#csv-button').removeAttr("disabled");
            $('#csv-button .spinner-border').addClass('d-none');
          };
          fileReader.readAsText(file);
        } else {
          this.showAlert("Selecteer een bestand", "danger");
        }
        this.$refs.file.files = null;
      }
    },
  
    components: {
      SyncCard
    }
  });
  </script>
  
  <style lang="scss" scoped>
    .sync-cards {
      margin-bottom: 3rem;
    }
  </style>
      
  