import { Promotijgers } from '../../../service/organisation/organisation'
import shopwareService from '../../ShopwareService'

export default Object.assign(Promotijgers, {
    async getValues(supplier, type) {
        if (type === 'manufacturer') {
            return shopwareService.request(
                (client) => client.get(process.env.VUE_APP_ROOT_API_PROMOTIJGERS + 'product-manufacturer'),
                (data) => {
                    const manufacturers = {};
                    data.data.forEach((manufacturer) => manufacturers[manufacturer.id] = manufacturer.translated.name);
                    return manufacturers;
                }
            );
        } else if (type === 'variant') {
            return;
        }
        return shopwareService.request((client) => {
            return client.get(process.env.VUE_APP_ROOT_API_PROMOTIJGERS + 'values/' + type);
        });
    },

    async getMappings(supplier, type) {
        return shopwareService.request((client) => {
            return client.get(process.env.VUE_APP_ROOT_API_PROMOTIJGERS + 'mapping/' + type);
        })
    },

    async addMapping(supplier, type, data) {
        return shopwareService.request((client) => {
            return client.put(process.env.VUE_APP_ROOT_API_PROMOTIJGERS + 'mapping/' + type, data);
        });
    },

    async getCategories() {
        return shopwareService.request(
            (client) => client.get(process.env.VUE_APP_ROOT_API_PROMOTIJGERS + 'category'),
            (data) => {
                const categories = {};
                data.data.forEach((category) => categories[category.id] = category.translated.name);
                return categories;
            }
        );
    },
});