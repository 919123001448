<template>
  <div id="invoices">
    <invoice-skeleton
      v-if="!this.$store.state.invoicesLoaded"
    ></invoice-skeleton>
    <template v-if="this.$store.state.invoicesLoaded">
      <div class="stripTicketsHeader">
        <div class="container">
          <h1 class="stripTicketsHeaderTitle">Facturen</h1>
          <p>
            Je hebt
            <span class="highlightBold">{{
              this.$store.state.invoices.length > 0
                ? this.$store.state.invoices.length
                : "nog geen"
            }}</span>
            facturen!
          </p>
        </div>
      </div>

      <div class="container" v-if="this.$store.state.invoices.length > 1">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Factuurdatum</th>
              <th scope="col">Factuurnummer</th>
              <th scope="col">Omschrijving</th>
              <th scope="col">Totaal incl. btw</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="invoice in this.$store.state.invoices"
              v-bind:key="invoice.id"
              @click="goToDetail(invoice)"
            >
              <td data-label="Factuurdatum">
                {{ this.dateFormat(invoice["created"]) }}
              </td>
              <td data-label="Factuurnummer">
                {{ invoice["invoice_number"] }}
              </td>
              <td data-label="Omschrijving">{{ invoice["subject"] }}</td>
              <td data-label="Totaal excl. btw">
                €{{ this.roundDecimal(invoice["total_including_vat"]) }}
              </td>
              <td data-label="Status">
                {{ this.convertStatus(invoice["status"]["name"]) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import InvoiceSkeleton from "../../components/skeletons/invoices/InvoiceSkeleton.vue";
import invoiceService from "../../service/InvoiceService";
export default {
  components: { InvoiceSkeleton },
  created() {
    this.getInvoices();
  },
  methods: {
    getInvoices() {
      invoiceService.getInvoices();
    },
    convertStatus(status) {
      switch (status) {
        case "label_Payed":
          return "Betaald";

        case "label_Sended":
          return "Verstuurd";

        case "label_Expired":
          return "Verlopen";
      }
    },
    roundDecimal(num) {
      return parseFloat(num).toFixed(2);
    },
    dateFormat(dateString) {
      let date = new Date(dateString);
      let day = ("0" + date.getDate()).slice(-2);

      let month = ("0" + (date.getMonth() + 1)).slice(-2);

      return day + "-" + month + "-" + date.getFullYear();
    },
    goToDetail(invoice) {
      this.$store.state.invoiceDetail = [];
      this.$router.push({ path: "/facturen/" + invoice["id"] });
    },
  },
};
</script>

<style scoped lang="scss">
#invoices {
  padding-bottom: 2rem;
}
.stripTicketsHeaderTitle {
  margin-top: 3.5rem;
}
$border-color: rgb(209, 209, 209);
tbody tr {
  cursor: pointer;
}
tbody tr:hover {
  background-color: lightgray;
}
@media screen and (max-width: 767px) {
  table {
    border: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    caption {
      text-align: left;
      font-size: 1.3em;
      margin: 0.5em 0 0.75em;
    }

    thead {
      display: none;
    }

    tr {
      display: block;
      border: 2px solid $border-color;
      padding: 1em 1em 0.5em;

      & + tr {
        margin-top: 0.625em;
      }
    }

    td {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid $border-color;
      font-size: 0.8em;
      line-height: 1.35em;

      &:before {
        content: attr(data-label);
        font-size: 0.9em;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        max-width: 45%;
        color: #545454;
      }

      & + td {
        margin-top: 0.8em;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>