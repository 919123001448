import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Login from '../views/authentication/Login.vue'
import PasswordReset from '../views/authentication/passwordReset/PasswordReset.vue'
import PageNotFound from '../views/error/404.vue'
import MaintenancePage from '../views/maintenance/Maintenance.vue'
import Projects from '../views/projects/Projects.vue'
import Invoices from '../views/invoices/Invoices.vue'
import InvoiceDetail from '../views/invoices/InvoiceDetail.vue'
import Issues from '../views/issues/Issues.vue'
import IssuesClosed from '../views/issues/ClosedIssuesView.vue'
import IssueDetail from '../views/issues/IssueDetail.vue'
import Hosting from '../views/hosting/Hosting.vue'
import Profile from '../views/profile/Profile.vue'
import Subscription from '../views/subscription/Subscription.vue'
import SLA from '../views/sla/SLA.vue'
import PunchCard from '../views/punchCard/PunchCard.vue'
import ResetEmailSend from '../views/authentication/passwordReset/ResetEmailSend'
import PasswordResetForm from '../views/authentication/passwordReset/PasswordResetForm'
import PasswordResetExpired from '../views/authentication/passwordReset/PasswordResetExpired'
import PasswordChanged from '../views/authentication/passwordReset/PasswordChanged'

import SetupAcountForm from '../views/authentication/setupAccount/SetupAccountForm'
import AccountConfigured from '../views/authentication/setupAccount/AccountConfigured'

// admin 
import AdminDashboard from "../views/admin/Dashboard.vue"
import ManageAccounts from "../views/admin/ManageAccounts.vue"
import AddAccount from "../views/admin/AddAccount.vue"
import ManageHosting from "../views/admin/ManageHosting.vue"
import Standup from "../views/admin/Standup.vue"
import StandupConfig from "../views/admin/StandupConfig.vue"
import NewOrganisations from "../views/admin/NewOrganisations.vue"
import ManageRepo from "../views/admin/ManageRepo.vue"
import ConnectAccount from "../views/admin/ConnectAccount.vue"
import UserPurchases from "../views/admin/UserPurchases.vue"
import PunchCardTariff from "../views/admin/PunchCardTariff.vue"
import PunchCardTariffAdd from "../views/admin/AddTariff.vue"
import WordpressUpdatesMail from "../views/admin/WordpressUpdatesMail.vue"

import SyncOverview from '../views/Sync/SyncOverview.vue'
import SyncMapping from '../views/Sync/SyncMapping.vue'
import SyncProducts from '../views/Sync/SyncProducts.vue'

// store
import { store } from "../store";

const routes = [
  {
    path: "/maintenance",
    component: MaintenancePage,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/",
    redirect: "/login",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/websocket",
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Inloggen - Miller Dashboard',
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'wachtwoord vergeten',
    component: PasswordReset,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/wachtwoord-vergeten/verlopen",
    name: 'wachtwoord vergeten verlopen',
    component: PasswordResetExpired,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/wachtwoord-vergeten/:uuid",
    name: 'wachtwoord aanpassen formulier',
    component: PasswordResetForm,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/account-instellen/:uuid",
    name: 'account instellen formulier',
    component: SetupAcountForm,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/wachtwoord-veranderd",
    name: 'wachtwoord veranderd',
    component: PasswordChanged,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/account-ingesteld",
    name: 'account ingesteld',
    component: AccountConfigured,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: '/email-verstuurd',
    name: 'email verstuurd',
    component: ResetEmailSend,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/projecten',
    name: 'projecten',
    component: Projects,
    meta: {
      title: 'Projecten - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/facturen',
    name: 'facturen',
    component: Invoices,
    meta: {
      title: 'Facturen - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: "/facturen/:id",
    name: 'facturen detail',
    component: InvoiceDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/issues',
    name: 'issues',
    component: Issues,
    meta: {
      title: 'Issues - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/issues/closed',
    name: 'closed issues',
    component: IssuesClosed,
    meta: {
      title: 'Gesloten issues - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: "/issues/:projectId/:iid",
    name: 'issue detail',
    component: IssueDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/strippenkaart',
    name: 'strippenkaart',
    component: PunchCard,
    meta: {
      title: 'Strippenkaart - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/hosting',
    name: 'hosting',
    component: Hosting,
    meta: {
      title: 'Hosting - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/profiel',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'Mijn profiel - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/sync',
    name: 'sync overview',
    component: SyncOverview,
    meta: {
      title: 'Sync - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/sync/mapping/:supplier/:type',
    name: 'sync mapping',
    component: SyncMapping,
    meta: {
      title: 'Sync Mapping - Miller Dashboard',
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/sync/producten/:supplier',
    name: 'sync products',
    component: SyncProducts,
    meta: {
      title: 'Sync Producten - Miller Dashboard',
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/abonnementen',
    name: 'subscription',
    component: Subscription,
    meta: {
      title: 'Abonnementen - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/SLA',
    name: 'SLA',
    component: SLA,
    meta: {
      title: 'SLA - Miller Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'adminDashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/accounts',
    name: 'accounts',
    component: ManageAccounts,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/accounts/add',
    name: 'add account',
    component: AddAccount,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/accounts',
    name: 'accounts',
    component: ManageAccounts,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/accounts/connect',
    name: 'accounts connect',
    component: ConnectAccount,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/direct-admin',
    name: 'direct admin',
    component: ManageHosting,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/purchases',
    name: 'purchases',
    component: UserPurchases,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/organisaties',
    name: 'add organisations',
    component: NewOrganisations,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/standup',
    name: 'standup',
    component: Standup,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      hideNavbar: true,
    },
  },
  {
    path: '/admin/standup/config',
    name: 'standup config',
    component: StandupConfig,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/punch-card-tariff',
    name: 'punch card tariff',
    component: PunchCardTariff,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/punch-card-tariff/add',
    name: 'punch card tariff add',
    component: PunchCardTariffAdd,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/repos',
    name: 'repos',
    component: ManageRepo,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin/wordpress-updates-mail',
    name: 'wordpress-mails',
    component: WordpressUpdatesMail,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");
    store.state.promiseApp.then(() => {
      if (process.env.VUE_APP_MAINTENANCE_MODE == 'true' && to.path !== "/maintenance") {
        next({
          path: "/maintenance"
        });
        return false;
      }
      // check if route needs authentication
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // when no token is available in localstorage, go to login
        if (localStorage.getItem("token") == null) {
          // return false;
          next({
            name: "login",
            params: { nextUrl: to.fullPath },
          })
          // check if route is admin authorized and user is admin
        } else if ((to.matched.some((record) => record.meta.requiresAdmin)) && !store.state.isAdmin) {
          console.log('test');
          next({
            path: "/"
          });
          // if user is logged in and route requires auth go next
        }

        else {
          if (to.meta.title) {
            document.title = to.meta.title
          } else {
            document.title = "Miller Dashboard"
          }
          next();
        }
      } else {
        // if user has token and wants to go to log in, send them to dashboard
        if (to.path == '/login' && localStorage.getItem("token") != null) {
          next({
            path: "/dashboard",
          });
        } else {
          // when user has no token go to login
          next();
        }
      }
    })
});

export default router
