import axios from "axios"
import { store } from '../../store'
import router from '../../router'

export default {
    async getAllPunchCards() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/punch-cards').then((res) => {
            console.log(res.data);

            store.state.allPunchCardProjects = res.data['data']['punch-cards']
        });
    },
    async getAllRates() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'punch-card/rates').then((res) => {
            console.log(res.data);

            store.state.punchCardRates = res.data
        });
    },
    async createNewRate(data) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'punch-card/rate', data).then((res) => {
            console.log(res.data);
        });
    },
    async remove(data) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'punch-card/delete/rate', data).then((res) => {
            console.log(res.data);
        });
    }
}