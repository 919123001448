<template>
    <div class="container">
        <div class="row">
            <SyncCard title="Bedrukking locaties" description="Beheer de bedrukking locaties van promidata">
                <router-link to="/sync/mapping/promidata/location" class="btn btn-primary">Bekijk de locatie sync</router-link>
            </SyncCard>
            <SyncCard title="Imprint" description="Beheer de imprint van promidata">
                <router-link to="/sync/mapping/promidata/imprint" class="btn btn-primary">Bekijk de imprint sync</router-link>
            </SyncCard>
            <SyncCard title="Kleuren" description="Beheer de kleuren van promidata">
                <router-link to="/sync/mapping/promidata/color" class="btn btn-primary">Bekijk de kleuren sync</router-link>
            </SyncCard>
            <SyncCard title="Fabrikanten" description="Beheer de fabrikanten van promidata">
                <router-link to="/sync/mapping/promidata/manufacturer" class="btn btn-primary">Bekijk de fabrikanten sync</router-link>
            </SyncCard>
            <SyncCard title="Categorieën" description="Beheer de categorieën van promidata">
                <router-link to="/sync/mapping/promidata/category" class="btn btn-primary">Bekijk de categorieën sync</router-link>
            </SyncCard>
            <SyncCard title="Varianten" description="Beheer de varianten van promidata">
                <router-link to="/sync/mapping/promidata/variant" class="btn btn-primary">Bekijk de varianten sync</router-link>
            </SyncCard>
        </div>
    </div>
</template>
  
<script>
    import SyncCard from '../../Sync/SyncCard.vue';
    import { Promotijgers } from '../../../service/organisation/organisation'

    export default Object.assign(Promotijgers, {
        components: {
            SyncCard
        }
    });
</script>