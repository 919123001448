<template>
  <div id="createIssueBlock">
    <div class="container">
      <h4 class="issueTitleHeader">Issue aanmaken</h4>
      <p class="issueUnderTitle">
        Heb je een vraag, een probleem op je site of een nieuwe functie die je
        wil toevoegen? <br />
        Vul hieronder de vereiste gegevens in en wij reageren zo snel mogelijk.
      </p>
      <div class="createIssueFormWrapper" style="position: relative">
        <form
          class="createIssueForm"
          id="my-form"
          method="POST"
          enctype="multipart/form-data"
          @submit.prevent="createIssue"
          @drop="drop"
          @dragover="dragover"
          @dragleave="dragleave"
        >
          <div class="row">
            <div class="col-lg-6 issueInputs">
              <p v-if="noTitle" class="noTitle text-danger">
                *Vul een titel in
              </p>
              <input
                type="text"
                placeholder="Titel van je issue"
                v-model="title"
              />
              <p v-if="noDescription" class="noDescription text-danger">
                *Vul een omschrijving in
              </p>

              <textarea
                placeholder="Omschrijf zo duidelijk mogelijk je issue.."
                v-model="description"
              />
            </div>
            <div class="col-lg-6 right">
              <div class="projectSelector">
                <h6 class="projectTitle">Project</h6>
                <p v-if="noProject" class="noProject text-danger">
                  *Selecteer een financiering
                </p>

                <select
                  class="form-select-sm select-css"
                  name="projects"
                  id="projects"
                  v-model="this.selectedSimplicateProject"
                >
                  <option
                    v-for="project in this.$store.state.projects"
                    v-bind:key="project.id"
                    :value="project.name"
                  >
                    {{ project.name }}
                  </option>
                </select>
              </div>
              <div class="issueButtons">
                <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="btn btn-secondary uploadFiles"
                  style="opacity: 0; overflow: hidden"
                  @change="onChange"
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                />

                <label
                  for="assetsFieldHandle"
                  class="btn btn-secondary uploadFiles"
                >
                  <img
                    class="uploadIcon"
                    src="../../assets/download.png"
                  />Upload bestanden
                </label>
                <button class="btn btn-primary" v-if="!this.sendRequest">
                  Issue aanmaken
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  disabled
                  v-if="this.sendRequest"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only"> Issue aanmaken...</span>
                </button>
              </div>
            </div>
          </div>

          <input type="hidden" id="files" name="files" value="" />
          <transition name="slide-fade">
            <div class="bottom" v-if="this.$store.state.fileList.length">
              <h6>Toegevoegde bestanden</h6>
              <div class="uploadFilesPreview">
                <div
                  class="imagePreview"
                  v-for="file in this.$store.state.fileList"
                  v-bind:key="file"
                >
                  <img
                    src="../../assets/times-solid.svg"
                    @click="this.remove(this.$store.state.fileList.indexOf(file))"
                    class="removeImage"
                  />

                  <img
                    :src="
                      'https://mijn.millerdigital.nl/api/temp/' +
                      this.getFileName(file)
                    "
                  />
                  <p class="imageName">{{ file.split("temp/")[1] }}</p>
                </div>
              </div>
            </div>
          </transition>
        </form>
        <div class="spinner-wrapper" v-if="this.uploadingImage">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import projectService from "../../service/ProjectService";

import jQuery from "jquery";
import issueService from "../../service/IssueService";
const $ = jQuery;
window.$ = $;

export default {
  name: "CreateIssueBlock",
  components: {},
  data: function () {
    return {
      title: "",
      description: "",
      imagePaths: "",
      selectedSimplicateProject: "",
      noTitle: false,
      noDescription: false,
      noProject: false,
      sendRequest: false,
      uploadingImage: false,
    };
  },
  methods: {
    getProjects() {
      projectService.getProjects().then(() => {
        this.setProjects();
      });
    },
    getFileName(file) {
      return file.split("temp/")[1];
    },
    setProjects() {
      this.$store.state.projects.forEach((project) => {
        let projectLowerCase = project.name.toLowerCase();
        if (project.name == "Doorontwikkelings abonnement") {
          this.selectedSimplicateProject = project.name;
        } else if (projectLowerCase.includes("strippenkaart")) {
          this.selectedSimplicateProject = project.name;
        } else if (project.name == "Doorontwikkeling website") {
          this.selectedSimplicateProject = project.name;
        } else if (this.selectedSimplicateProject == "") {
          this.selectedSimplicateProject = project.name;
        }
      });
    },
    onChange() {
      this.uploadImage();
    },
    remove(i) {
      this.$store.state.fileList.splice(i, 1);
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    uploadImage() {
      // TODO check file size and extensions
      $(".createIssueForm").addClass("loading");
      this.uploadingImage = true;
      var formData = new FormData();
      let count = 0;
      let fileListArr = Array.from(this.$refs.file.files);

      fileListArr.forEach((element) => {
        if (element.size > 1024 * 1024 * 5) {
          alert("bestand is te groot");
          return;
        }
        count++;
        formData.append("file_" + count, element);
      });

      issueService.uploadFile(formData);

      this.uploadingImage = false;
      $(".createIssueForm").removeClass("loading");
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!$(".createIssueForm").hasClass("active")) {
        // event.currentTarget.classList.add("dotted-border");
        $(".createIssueForm").addClass("active");
      }
    },
    dragleave() {
      // Clean up
      $(".createIssueForm").removeClass("active");
    },
    drop(event) {
      event.preventDefault();
      // set files
      this.$refs.file.files = event.dataTransfer.files;
      this.uploadImage();
      // Clean up
      $(".createIssueForm").removeClass("active");
    },
    async createIssue() {
      this.noTitle = false;
      this.noDescription = false;
      this.noProject = false;
      if (this.title == "") {
        this.noTitle = true;
      }
      if (this.description == "") {
        this.noDescription = true;
      }
      if (this.selectedSimplicateProject == "") {
        this.noProject = true;
      }

      if (!this.noTitle && !this.noDescription && !this.noProject) {
        this.sendRequest = true;
        this.uploadingImage = true;
        const postData = {
          title: this.title,
          description: this.description,
          image_paths: this.$store.state.fileList,
          simplicate_project: this.selectedSimplicateProject,
        };

        await issueService.createIssue(postData);

        this.sendRequest = false;

        this.title = "";
        this.description = "";
        this.uploadingImage = false;
        this.$store.state.fileList = [];
      }
    },
  },
  created() {
    this.$store.state.fileList = [];
    this.getProjects();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/css/select.css";
.issueTitleHeader {
  text-align: center;
  padding-top: 3rem;
}
.issueUnderTitle {
  text-align: center;
  max-width: 800px;
  margin: auto;
}
.createIssueForm {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  max-width: 960px;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 1.5rem;
}
.issueInputs input,
.issueInputs textarea {
  display: block;
  background-color: #fcfcfc;
  border: none;
  width: 100%;
  padding: 1rem;
}

.issueInputs textarea {
  resize: none;
  height: 8rem;
}
.issueInputs input {
  margin-bottom: 0.5rem;
}
#createIssueBlock {
  .right {
    padding: 0 5rem;
    display: flex;
    flex-direction: column;

    button,
    input {
      width: 100%;
    }
  }
}

.projectSelector {
  flex: 1;
  margin-top: 1rem;
}

.uploadIcon {
  margin-right: 0.5rem;
  width: 10px;
}

.uploadFilesPreview {
  max-width: 960px;
  margin: 0 auto;
  display: flex;

  .imagePreview {
    margin-top: 0.75rem;
    position: relative;
    width: 10rem;
    margin-right: 2rem;

    .removeImage {
      position: absolute;
      right: -8px;
      top: -8px;
      // background-color: black;
      color: white;
      border: none;
      display: inline-block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 5rem;
      display: block;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 15px;
    }

    .imageName {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0;
      margin-top: 0.5rem;
      opacity: 0.7;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  transform: translateY(-20px);
  opacity: 0;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

@media screen and (max-width: 992px) {
  #createIssueBlock {
    .right {
      padding: 0 15px;
      margin-top: 2rem;
    }
  }
}

.projectTitle {
  display: block;
  font-weight: bold;
}

.uploadFiles {
  width: 100%;
  margin-bottom: 0.5rem;
  background-color: #fafafa;
  color: black;
  border: none;
}

.loading {
  opacity: 0.5;
  cursor: none;
}

#dashboard.loading #createIssueBlock h4 {
  background-color: #ddd;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  margin-top: 3rem;
  width: 30%;
}

#dashboard.loading .createIssueForm {
  border: 5px solid rgb(236, 236, 236);
  box-shadow: none;
  background-color: rgba(204, 204, 204, 0.867);
}

.createIssueForm .bottom h6 {
  margin-top: 1rem;
}

// dropzone

.fileuploader {
  position: relative;
  width: 60%;
  margin: auto;
  height: 400px;
  border: 4px dashed #ddd;
  background: #f6f6f6;
  margin-top: 85px;
}
.fileuploader #upload-label {
  background: rgba(231, 97, 92, 0);
  color: #fff;
  position: absolute;
  height: 115px;
  top: 20%;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  min-width: 20%;
  text-align: center;
  cursor: pointer;
}
.createIssueForm.active {
  border: dashed 2px grey;
}
.fileuploader.active #upload-label {
  background: #fff;
  color: #e7615c;
}

.fileuploader #upload-label i:hover {
  color: #444;
  font-size: 9.4rem;
}

.fileuploader #upload-label span.title {
  font-size: 1em;
  font-weight: bold;
  display: block;
}

span.tittle {
  position: relative;
  top: 222px;
  color: #bdbdbd;
}

.fileuploader #upload-label i {
  text-align: center;
  display: block;
  color: #e7615c;
  height: 115px;
  font-size: 9.5rem;
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}
/** Preview of collections of uploaded documents **/
.preview-container {
  position: relative;
  bottom: 0px;
  width: 35%;
  margin: auto;
  top: 25px;
  visibility: hidden;
}
.preview-container #previews {
  max-height: 400px;
  overflow: auto;
}
.preview-container #previews .zdrop-info {
  width: 88%;
  margin-right: 2%;
}
.preview-container #previews.collection {
  margin: 0;
  box-shadow: none;
}

.preview-container #previews.collection .collection-item {
  background-color: #e0e0e0;
}

.preview-container #previews.collection .actions a {
  width: 1.5em;
  height: 1.5em;
  line-height: 1;
}
.preview-container #previews.collection .actions a i {
  font-size: 1em;
  line-height: 1.6;
}
.preview-container #previews.collection .dz-error-message {
  font-size: 0.8em;
  margin-top: -12px;
  color: #f44336;
}

/*media queries*/

@media only screen and (max-width: 601px) {
  .fileuploader {
    width: 100%;
  }

  .preview-container {
    width: 100%;
  }
}

.spinner-wrapper {
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}
</style>
