import axios from "axios"
import { store } from '../store'
import router from '../router'
export default {
    async loadPackagesSLA(platform) {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'SLA/packages?platform=' + platform).then((res) => {
            return res.data['data'];
        }).catch((err) => {
            console.log(err);
        });
    },
    async purchaseSLA(data) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'SLA', data).then((res) => {
            return res.data['data'];
        }).catch((err) => {
            console.log(err);
        });
    },
    async currentSLA() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'SLA').then((res) => {
            return res.data['data'];
        }).catch((err) => {
            console.log(err);
        });
    }
}