<template>
  <div id="closedIssues">
    <div class="issuesHeader">
      <div class="container">
        <div class="row">
          <div class="col-lg left">
            <router-link to="/issues">Ga terug naar issues</router-link>
            <h1 class="issueHeaderTitle">Gesloten issues</h1>
            <span v-if="this.$store.state.openIssues">
              Je hebt
              <span class="highlightBold"
                >{{ this.$store.state.closedIssues.length }} gesloten</span
              >
              issues.
            </span>
          </div>
        </div>
      </div>
    </div>
    <closed-issues></closed-issues>
  </div>
</template>

<script>
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ClosedIssues from "../../components/issue/IssuesClosed.vue";

export default {
  components: {
    ClosedIssues,
  },
  data() {
    return {
     
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#closedIssues {
  padding-bottom: 5rem;
}
.issuesHeader {
  margin-bottom: 3rem;
   margin-top: 3.5rem;
}

</style>