import axios from "axios"
import { store } from '../store'

export default {
    async getProjectCount() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'projects/count')
    },
    async getProjects() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'projects').then((res) => {
            store.state.projects = res.data['data']['projects'];
            store.state.projectsLoaded = true;
        }).catch((err) => {
            store.state.projectsLoaded = true;
            console.log(err);
        })
    }, 
    async getGitProjects() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'repos').then((res) => {
            store.state.gitRepos = res.data['data']['repos'];
        }).catch((err) => {
            console.log(err);
        })
    },
    async getGitProject(projectId) {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'repo/' + projectId).then((res) => {
            store.state.repo = res.data['data']['repo']
        }).catch((err) => {
            console.log(err);
        })
    }
}