<template>
  <div class="container mt-4">
    <router-link to="/admin/accounts">Ga terug naar overzicht</router-link>
    <h2>Gebruiker koppelen aan organisatie</h2>
    <div class="form-group mb-2">
      <label class="form-label">Gebruiker</label>
      <select
        v-model="user"
        class="form-select"
        aria-label="Default select example"
      >
        <option
          v-for="user in this.$store.state.allUsers"
          v-bind:key="user"
          :value="user.id"
        >
          {{ user.firstname }} {{ user.lastname }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label class="form-label">Organisatie</label>
      <select
        v-model="organisation"
        class="form-select"
        aria-label="Default select example"
      >
        <option
          v-for="organisation in this.$store.state.allOrganisations"
          v-bind:key="organisation"
          :value="organisation.id"
        >
          {{ organisation.name }}
        </option>
      </select>
    </div>

    <button v-on:click="connectUser()" class="btn btn-primary mt-4">
      Gebruiker koppelen
    </button>
  </div>
</template>

<script>
import organisationService from "../../service/admin/OrganisationService";
import userService from "../../service/admin/UserService";
import OrganisationService from '../../service/OrganisationService';
export default {
  data() {
    return {
      organisation: "",
      user: "",
    };
  },
  created() {
    this.getOrganisations();
    this.getUsers();
  },
  methods: {
    async connectUser() {
      await userService.connectUserToOrganisation(this.user, this.organisation);
      this.$router.push({path: "/admin/accounts"})
    },
    getOrganisations() {
      organisationService.getAllOrganisations();
    },
    getUsers() {
      userService.getUsers();
    },
  },
};
</script>

<style scoped></style>
