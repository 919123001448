<template>
  <div class="container">
    <div class="boxWrapper">
      <h1>Oeps, ongelukje...</h1>
      <p>Kan gebeuren toch?</p>
      <div class="box">
        <div class="row">
          <div class="col-lg imageCol"><img src="../../assets/404.png" /></div>
          <div class="col-lg textCol">
            <h3>Er is iets misgegaan</h3>
            <p>
              Wij hebben de pagina waar je naar op zoek bent niet kunnen vinden.
              Deze pagina bestaat niet meer of er heeft zich een probleem
              voorgedaan waardoor de pagina niet geopend kan worden. Waar ben je
              naar op zoek?
            </p>
            <router-link class="btn btn-primary" to="/dashboard"
              >Terug naar dashboard</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.state.pageRefreshAnimation = false;
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.boxWrapper {
  h1 {
    margin-top: 3rem;
  }
}
.box {
  width: 75%;
  min-height: 25rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 1.5rem 4rem;
  display: flex;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.imageCol,
.textCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.textCol {
  h3 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 2rem;
  }
  a {
    width: auto;
  }
}

.imageCol {
  img {
    max-width: 400px;
    width: 100%;
  }
}
</style>
