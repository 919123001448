<template>
  <div class="container">
    <div id="login">
      <div class="loginWrapper">
        <form class="loginForm">
          <div class="passwordResetContent">
            <img class="mdLogo" src="../../../assets/md_hres.png" />
            <div class="passwordResetInstuction">
              <p class="headTitle">
                Je reset-link is helaas verlopen of al gebruikt
              </p>
              <p>
                Uit security overwegingen moet je opnieuw een reset-link
                aanvragen om je wachtwoord opnieuw in te stellen. Vul hieronder
                opnieuw je mailadres in voor het opvragen van een instructiemail
                om je wachtwoord opnieuw in te stellen:
              </p>
            </div>
            <div class="enterInformation">
              <div class="form-group emailInputRow">
                <label for="emailInput">E-MAIL ADRES</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  id="emailInput"
                  aria-describedby="emailHelp"
                  placeholder="Vul je e-mail adres in"
                />
                <p v-if="noEmail" class="text-danger error">
                  Geen email ingevuld
                </p>
              </div>
              <button
                v-if="!this.sendRequest"
                type="submit"
                @click.prevent="sendResetPasswordLink()"
                class="btn btn-primary loginButton"
              >
                Instructie mail versturen
              </button>
              <button
                class="btn btn-primary loginButton"
                type="button"
                disabled
                v-if="this.sendRequest"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only"> Instructie email versturen...</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import authService from "../../../service/AuthService";
export default {
  data() {
    return {
      email: "",
      sendRequest: false,
      noEmail: false,
    };
  },
  created() {},
  methods: {
    async sendResetPasswordLink() {
      if (this.email == "") {
        this.noEmail = true;
        return;
      }
      this.sendRequest = true;
      await authService.resetPassword(this.email);
      this.$router.push({ path: "/email-verstuurd" });
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  background-image: url("../../../assets/background_md_55.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  padding-bottom: 0;
}
#login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loginWrapper {
  width: 75%;
  min-height: 25rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
  padding: 1.5rem 4rem;
  display: flex;
  height: 100%;

  form {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
.mdLogo {
  width: 6rem;
}
.passwordResetInstuction {
  margin-top: 2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  .headTitle {
    margin-bottom: 0;
    font-weight: bold;
  }

  p {
    width: 100%;
    line-height: 1.3rem;
  }
}

.emailInputRow {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.emailInputRow label {
  font-weight: bold;
  font-size: 12px;
}

.enterInformation {
  width: 60%;
  margin: auto;
  margin-top: 3.5rem;
  margin-bottom: 4rem;
}
.keepLoggedInRow {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.imageHouse {
  width: 100%;
}

.imageHouseWrapper {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.keepLoggedIn {
  font-size: 11px;
  color: #888888;
}
.loginForm .form-group.row .col {
  padding-left: 0;
  padding-right: 0;
}
.loginButton {
  width: 100%;
  height: 3rem;
}

.error {
  font-weight: bold;
  font-size: 12px;
  margin-top: 0.5rem;
}

#emailInput {
  background-color: #fcfcfc;
  border: none;
  padding: 1rem 2rem;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .passwordResetContent {
    padding-right: 0;
  }
  .imageHouseWrapper {
    justify-content: center;
    margin-top: 2rem;
  }

  .loginWrapper {
    width: 95%;
    padding: 1.5rem;
  }
}
</style>
