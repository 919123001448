import axios from "axios"
import { store } from '../../store'
import router from '../../router'
import { url } from "@vuelidate/validators";

export default {
    async getDirectAdminUsers(url) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'admin/direct-admin/users', { url: url }).then((res) => {
            store.state.directAdminUsers = res.data['data']['users'];
        });
    },
    async addOrUpdateDirectAdmin(currentName, url, organisationId, newName) {
        let data = {
            currentName: currentName,
            url: url,
            organisationId: organisationId,
            newName: newName
        }
        return await axios.post(process.env.VUE_APP_ROOT_API + 'admin/direct-admin', data).then((res) => {
            // store.state.directAdminUsers = res.data['data']['users'];
        });
    }
}