import axios from "axios"
import { store } from '../store'

export default {
    async getScheduledIssues() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'issues/scheduled').then((res) => {
            store.state.scheduledIssues = res.data['data']['issues'];

            let count = 0;
            for (const key in res.data['data']['issues']) {
                count += res.data['data']['issues'][key].length
                store.state.dragIssuesEnabled[key] = false;
            }
            store.state.scheduledIssuesCount = count;

            store.state.scheduledIssuesLoaded = true;
        }).catch((err) => {
            console.log(err);
            store.state.scheduledIssuesLoaded = true;
        })
    },
    async getClosedIssues() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'issues/closed').then((res) => {
            console.log(res.data);
            store.state.closedIssues = res.data['data']['issues'];
            store.state.closedIssuesPages = Math.ceil(store.state.closedIssues.length / 9)
            // let count = 0;
            // for (const key in res.data['data']['issues']) {
            //     count += res.data['data']['issues'][key].length
            //     store.state.dragIssuesEnabled[key] = false;
            // }
            // store.state.scheduledIssuesCount = count;

            // store.state.scheduledIssuesLoaded = true;
        }).catch((err) => {
            console.log(err);
            // store.state.scheduledIssuesLoaded = true;
        })
    },
    async getOpenIssues() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'issues/open').then((res) => {
            store.state.openIssues = res.data['data']['issues'];

            let count = 0;
            for (const key in res.data['data']['issues']) {
                count += res.data['data']['issues'][key].length
                store.state.dragIssuesEnabled[key] = false;
            }
            store.state.openIssuesCount = count;
            store.state.openIssuesLoaded = true;

        }).catch((err) => {
            console.log(err);
        })
    },
    async getEstimatedIssues() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'issues/estimated').then((res) => {
            store.state.estimatedIssues = res.data['data']['issues'];
            store.state.estimatedIssuesLoaded = true;
        }).catch((err) => {
            console.log(err);
            store.state.openIssuesLoaded = true;
        })
    },
    async uploadFile(formData) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'issues/pre-upload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            res.data['data']['paths'].forEach((element) => {
                store.state.fileList.push(element);
            });
            return res.data['data']['paths'];
        }).catch((err) => {
            console.log(err);
        });
    },
    async createIssue(data) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'issue', data).then((res) => {
        }).catch((err) => {
            console.log(err);
        })
    },
    async getIssue(projectId, issueId) {
        return await axios.post(process.env.VUE_APP_ROOT_API + `issue/${projectId}/${issueId}`).then((res) => {
            store.state.issue = res.data['data']['issue'];
            store.state.issueDetailLoaded = true;

        }).catch((err) => {
            console.log(err);
        });
    },
    async closeIssue(projectId, issueId) {
        return await axios.post(process.env.VUE_APP_ROOT_API + `issue/${projectId}/${issueId}/close`).then((res) => {
        }).catch((err) => {
            console.log(err);
        });
    },
    async setTimeEstimateAgreed(projectId, issueId) {
        return await axios.post(process.env.VUE_APP_ROOT_API + `issue/${projectId}/${issueId}/estimate/agreed`).then((res) => {
        }).catch((err) => {
            console.log(err);
        });
    },
    async sendMessage(projectId, issueId, data) {
        return await axios.post(process.env.VUE_APP_ROOT_API + `issue/${projectId}/${issueId}/message`, data).then((res) => {
            return res.data['data']['message']
        }).catch((err) => {
            console.log(err);
        });
    },
    async getIssueDiscussion(projectId, issueId) {
        return await axios.get(process.env.VUE_APP_ROOT_API + `issue/${projectId}/${issueId}/messages`).then((res) => {
            store.state.discussion = res.data['data']['discussion'];
        }).catch((err) => {
            console.log(err);
        });
    },
    async setPriority(issues, index) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'issues/priority', { issues: issues }).then((res) => {
            store.state.openIssues[index] = res.data['data']['issues'];
        }).catch((err) => {
            console.log(err);
        });
    }
}