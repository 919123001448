<template>
  <div id="dashboard">
    <div class="dashboard-welcome">
      <div class="container">
        <div class="dashboard-welcome__content">
          <h1>
            {{ this.timeOfDay }}
            <span v-if="this.$store.state.user" class="highlightUnderline">{{
              this.$store.state.user.firstname
            }}</span
            >!
          </h1>
          <project-count></project-count>
        </div>
      </div>
    </div>
    <create-issue-block></create-issue-block>
    <scheduled-issues :showLink="true" :smallContainer="true" colsize="6"></scheduled-issues>
  </div>
</template>

<script>
import projectCount from "../../components/dashboard/ProjectCount";
import createIssueBlock from "../../components/issue/CreateIssueBlock";
import ScheduledIssues from '../../components/issue/ScheduledIssues';
export default {
  data() {
    return {
      timeOfDay: "",
    };
  },
  components: {
    projectCount,
    createIssueBlock,
    ScheduledIssues,
  },
  methods: {
    getCurrentTimeOfDay() {
      var today = new Date();
      var curHr = today.getHours();

      if (curHr < 12) {
        this.timeOfDay = "Goedemorgen";
      } else if (curHr < 18) {
        this.timeOfDay = "Goedemiddag";
      } else {
        this.timeOfDay = "Goedeavond";
      }
    },
  },
  created() {
    this.getCurrentTimeOfDay();
  },
};
</script>

<style lang="scss">
.dashboard-welcome {
  margin-bottom: 2rem;
}
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#dashboard {
  // background-color: #eff6f8;
  position: relative;
  z-index: 99;
  padding-bottom: 4rem;
}

#dashboard.loading {
  background-color: #eff6f8;
}

@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
  }
}

.dashboard-welcome__content {
  text-align: center;
  padding-top: 5rem;

  p {
    padding-top: 1rem;
  }
}
</style>