<template>
  <div id="SLA">
    <SLA-skeleton v-if="!this.loaded"></SLA-skeleton>

    <template v-if="this.loaded">
      <div class="SLAHeader">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <h1 class="SLAHeaderTitled">SLA pakketten</h1>
            </div>
            <div
              v-if="this.SLA.length > 0"
              class="col-6 d-flex justify-content-end align-items-center"
            >
              <button @click="toggleView()" class="btn btn-primary">
                {{ this.showPackages ? "Mijn SLA" : "Extra SLA aanschaffen" }}
              </button>
            </div>
          </div>
          <span v-if="this.SLA.length == 0"
            >Wij houden je website/webshop in optimale gezondheid</span
          >
          <span v-if="this.SLA.length > 0"
            >Je hebt op dit moment {{ this.SLA.length }} SLA
            {{ this.SLA.length == 1 ? "pakket" : "pakketten" }}</span
          >
        </div>
      </div>

      <div class="noSLA" v-if="this.SLA.length == 0 || this.showPackages">
        <div class="container">
          <div class="platforms">
            <button
              class="me-2"
              :class="
                this.activePlatform == 'WordPress'
                  ? 'btn btn-primary'
                  : 'btn btn-primary-outline'
              "
              @click="this.loadAvailableSLA('WordPress')"
            >
              WordPress
            </button>
            <button
              :class="
                this.activePlatform == 'WooCommerce'
                  ? 'btn btn-primary'
                  : 'btn btn-primary-outline'
              "
              class="me-2"
              @click="this.loadAvailableSLA('WooCommerce')"
            >
              WooCommerce
            </button>
            <button
              :class="
                this.activePlatform == 'Shopware'
                  ? 'btn btn-primary'
                  : 'btn btn-primary-outline'
              "
              @click="this.loadAvailableSLA('Shopware')"
            >
              Shopware
            </button>
          </div>
          <table class="table table-striped w-auto">
            <thead>
              <tr>
                <th></th>
                <th class="heading brons" scope="col">Brons</th>
                <th class="heading zilver" scope="col">Zilver</th>
                <th class="heading goud" scope="col">Goud</th>
                <th class="heading platinum" scope="col">Platinum</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(categorie, index) in this.availableSLA['categories']"
                v-bind:key="categorie"
              >
                <th class="category" scope="row">{{ categorie }}</th>
                <td style="width: 140px">
                  {{
                    this.availableSLA["specs"][index][0] == "check"
                      ? ""
                      : this.availableSLA["specs"][index][0]
                  }}
                  <img
                    class="kruisje"
                    v-if="!this.availableSLA['specs'][index][0]"
                    src="../../assets/kruisje.svg"
                    alt=""
                  />
                  <img
                    class="kruisje"
                    v-if="this.availableSLA['specs'][index][0] == 'check'"
                    src="../../assets/vinkje.svg"
                    alt=""
                  />
                </td>
                <td style="width: 140px">
                  {{
                    this.availableSLA["specs"][index][1] == "check"
                      ? ""
                      : this.availableSLA["specs"][index][1]
                  }}
                  <img
                    class="kruisje"
                    v-if="!this.availableSLA['specs'][index][1]"
                    src="../../assets/kruisje.svg"
                    alt=""
                  />
                  <img
                    class="kruisje"
                    v-if="this.availableSLA['specs'][index][1] == 'check'"
                    src="../../assets/vinkje.svg"
                    alt=""
                  />
                </td>
                <td style="width: 140px">
                  {{
                    this.availableSLA["specs"][index][2] == "check"
                      ? ""
                      : this.availableSLA["specs"][index][2]
                  }}
                  <img
                    class="kruisje"
                    v-if="!this.availableSLA['specs'][index][2]"
                    src="../../assets/kruisje.svg"
                    alt=""
                  />
                  <img
                    class="kruisje"
                    v-if="this.availableSLA['specs'][index][2] == 'check'"
                    src="../../assets/vinkje.svg"
                    alt=""
                  />
                </td>
                <td style="width: 140px">
                  {{
                    this.availableSLA["specs"][index][3] == "check"
                      ? ""
                      : this.availableSLA["specs"][index][3]
                  }}
                  <img
                    class="kruisje"
                    v-if="!this.availableSLA['specs'][index][3]"
                    src="../../assets/kruisje.svg"
                    alt=""
                  />
                  <img
                    class="kruisje"
                    v-if="this.availableSLA['specs'][index][3] == 'check'"
                    src="../../assets/vinkje.svg"
                    alt=""
                  />
                </td>
              </tr>
              <tr>
                <th></th>
                <td
                  v-for="(price, index) in this.availableSLA['prices']"
                  v-bind:key="index"
                >
                  <span class="euro-sign">€ </span>
                  <span class="price">{{ price }}</span>
                  <span class="per-month">per maand</span>
                  <button
                    :class="'purchase-sla-' + index"
                    class="purchaseSLA btn btn-primary"
                    @click="
                      openPopup(
                        this.availableSLA['defaultServices'][index],
                        index
                      )
                    "
                  >
                    kopen
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <h6 class="text-center mt-4">
            Indien je kiest voor jaarbetaling dan krijg je 10% korting op het
            tarief.
          </h6>
        </div>
      </div>

      <div class="SLA" v-if="this.SLA.length > 0 && !this.showPackages">
        <div class="container">
          <div class="SLARows">
            <div v-for="SLA in this.SLA" v-bind:key="SLA">
              <div class="SLARow">
                <div class="row">
                  <div class="col-4">
                    <p class="">{{ SLA.name }}</p>
                  </div>
                  <div class="col-auto">
                    <span class="col-auto me-2 text-muted">{{
                      this.formatDate(SLA.start_date)
                    }}</span>
                    <span class="col-auto text-muted">
                      {{
                        SLA["subscription_cycle"] == "Year"
                          ? "jaarabonnement"
                          : "maandabonnement"
                      }}
                    </span>
                  </div>
                  <div
                    class="col d-flex align-items-center justify-content-end"
                  >
                    <a
                      @click="toggleView()"
                      class="specifications highlightBlue text-decoration-underline"
                      >specificaties</a
                    >
                  </div>
                  <div
                    class="col-auto d-flex justify-content-end align-items-center"
                  >
                    <p>
                      <span class="highlightBlue"
                        >€ {{ this.roundDecimal(SLA.price) }}</span
                      >
                      per
                      {{
                        SLA["subscription_cycle"] == "Year" ? "jaar" : "maand"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mdPopup" style="display: none">
        <div class="closeConfirmWrapper">
          <h4>{{ this.activePlatform }} SLA {{ this.type }} aanschaffen</h4>
          <div class="d-flex mb-1">
            <span class="checkboxLabel" for="titel">Jaarbetaling: </span>
            <div class="form-check">
              <input
                v-model="this.changes"
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                :disabled="this.sendRequest"
              />
            </div>
          </div>
          <p class="mb-1">
            Startdatum:
            <span class="highlightBlue">{{ getFirstOfMonth() }}</span>
          </p>
          <div class="d-flex align-items-center">
            <span>Website: </span>
            <Multiselect
              class="organisationSelect ms-2"
              mode="single"
              :close-on-select="true"
              :searchable="true"
              v-model="this.selectedDomain"
              :options="this.$store.state.domains"
              label="name"
              valueProp="name"
              :allow-empty="false"
              :create-option="true"
              noOptionsText="Vul de url in (bijv. millerdigital.nl) en druk op enter"
              placeholder="Vul de url in of selecteer een url"
            >
            </Multiselect>
          </div>
          <p class="mt-3">
            Prijs:
            <span class="highlightBlue"
              >€ {{ this.changes ? this.price * 12 * 0.9 : this.price }}</span
            >
            {{ this.changes ? "per jaar (10% korting)" : "per maand" }}
          </p>
          <div class="buttonRow mt-4">
            <button @click="cancelPurchase()" class="btn btn-primary-outline">
              Annuleren
            </button>
            <button
              @click="purchaseSLA()"
              v-if="!this.sendRequest"
              class="btn confirmCloseBtn"
            >
              Akkoord
            </button>
            <button
              v-if="this.sendRequest"
              class="btn confirmCloseBtn"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only"> Akkoord...</span>
            </button>
          </div>
          <p class="text-danger mt-2">{{ this.error }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SLASkeleton from "../../components/skeletons/SLA/SLASkeleton.vue";
import Multiselect from "@vueform/multiselect";
import SLAService from "../../service/SLAService";
import hostingService from "../../service/HostingService";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  components: {
    Multiselect,
    SLASkeleton,
  },
  data() {
    return {
      availableSLA: [],
      activePlatform: "",
      type: "",
      sendRequest: false,
      changes: false,
      price: 0,
      selectedDomain: "",
      defaultService: "",
      SLA: [],
      loaded: false,
      showPackages: false,
      error: "",
    };
  },
  async created() {
    await this.loadAvailableSLA("WordPress");
    await this.currentSLA();
    this.loaded = true;
  },
  updated() {
    $("td").hover(function (event) {
      var columnIndex = $(this).index();

      $(".purchase-sla-" + (columnIndex - 1)).css(
        "display",
        event.type === "mouseenter" ? "block" : "none"
      );

      $("thead > tr")
        .children()
        .eq(columnIndex)
        .css({
          "border-left":
            event.type === "mouseenter"
              ? "10px solid #2c5a72"
              : "4px solid #2c5a72",
          "border-right":
            event.type === "mouseenter"
              ? "10px solid #2c5a72"
              : "4px solid #2c5a72",
          "border-top":
            event.type === "mouseenter"
              ? "10px solid #2c5a72"
              : "4px solid #2c5a72",
        });
      $("tbody > tr").each(function () {
        $(this)
          .children()
          .eq(columnIndex)
          .css({
            "border-left":
              event.type === "mouseenter"
                ? "10px solid #2c5a72"
                : "4px solid #2c5a72",
            "border-right":
              event.type === "mouseenter"
                ? "10px solid #2c5a72"
                : "4px solid #2c5a72",
          });
      });

      $("tbody > tr:last-child")
        .children()
        .eq(columnIndex)
        .css({
          "border-bottom":
            event.type === "mouseenter"
              ? "10px solid #2c5a72"
              : "4px solid #2c5a72",
        });
    });
  },
  methods: {
    formatDate(date) {
      let splittedDate = date.split("-");
      return splittedDate[2] + "-" + splittedDate[1] + "-" + splittedDate[0];
    },
    toggleView() {
      this.showPackages = !this.showPackages;
    },
    roundDecimal(object) {
      return parseFloat(object).toFixed(2).replace(".", ",");
    },
    async loadAvailableSLA(platform) {
      this.activePlatform = platform;
      await SLAService.loadPackagesSLA(platform).then((data) => {
        this.availableSLA = data;
      });
    },
    async openPopup(defaultServiceId, index) {
      this.type = this.availableSLA["types"][index];
      this.price = this.availableSLA["prices"][index];
      this.defaultService = defaultServiceId;
      $(".mdPopup").show();
      await this.loadDomains();
    },
    getFirstOfMonth() {
      const today = new Date();

      if (today.getDate() == 1) {
        return today.toLocaleDateString("nl-NL");
      }

      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = 1;
      const firstDayOfMonth = new Date(year, month, day);
      return firstDayOfMonth.toLocaleDateString("nl-NL");
    },
    async loadDomains() {
      await hostingService.getHosting(true);
    },
    async currentSLA() {
      await SLAService.currentSLA().then((data) => {
        this.SLA = data;
      });
    },
    cancelPurchase() {
      $(".mdPopup").hide();
    },
    async purchaseSLA() {
      if (this.selectedDomain == "") {
        this.error = "*Website moet ingevuld zijn";
        return;
      }

      this.sendRequest = true;
      let data = {
        billingType: this.changes ? "Year" : "Month",
        domain: this.selectedDomain,
        startDate: this.getFirstOfMonth(),
        defaultService: this.defaultService,
      };

      await SLAService.purchaseSLA(data);
      await this.currentSLA();
      this.sendRequest = false;
      this.showPackages = false;
      $(".mdPopup").hide();
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped lang="scss">
#SLA {
  padding-bottom: 4rem;
}
.SLAHeader {
  padding-top: 3rem;
}

.heading {
  padding: 20px;
}

.table {
  text-align: center;
  margin: 0 auto;
}

td,
th:not(:first-child) {
  border-left: 4px solid #2c5a72;
  border-right: 4px solid #2c5a72;
}

thead tr th:not(:first-child) {
  border-top: 4px solid #2c5a72;
}

tbody tr:last-child td {
  border-bottom: 4px solid #2c5a72;
}
.category {
  text-align: right;
}

.brons {
  background: #cc6633;
}

.zilver {
  background: #c0c0c0;
}

.goud {
  background: #ffd700;
}

.platinum {
  background: #e5e4e2;
}

.kruisje {
  width: 15px;
}
.platforms {
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left: 210px;
}

.btn-primary:focus {
  background-color: #009fd9;
  color: white;
}

.per-month {
  display: block;
  color: #f6c75b;
}

.euro-sign {
  font-size: 20px;
}
.price {
  font-size: 30px;
}
.purchaseSLA {
  display: none;
}

.form-check {
  margin-left: 5px;
}

.purchaseSLA {
  margin-top: 10px;
  width: 100%;
}

.mdPopup {
  transform: translateY(0);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.mdPopup .closeConfirmWrapper {
  position: absolute;
  width: 30rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.SLARows {
  margin-top: 3rem;
}
.SLARow {
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;

  a {
    color: black;
    text-decoration: none;
  }

  .projectTitle {
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
  }
}

.specifications {
  cursor: pointer;
}
</style>