<template>
  <div class="container">
    <div id="login">
      <div class="loginWrapper">
        <form class="row loginForm">
          <div class="col-lg-8 passwordResetContent">
            <img class="mdLogo" src="../../../assets/md_hres.png" />
            <div class="passwordResetInstuction">
              <p class="headTitle">Nieuw wachtwoord instellen</p>
              <p>
                Je nieuwe wachtwoord moet anders zijn dan voorgaande eerder
                gebruikte wachtwoorden.
              </p>
            </div>
            <div class="form-group passwordInputRow">
              <label for="passwordInput">WACHTWOORD</label>
              <input
                v-model="password"
                type="password"
                class="form-control"
                id="passwordInput"
                placeholder="voer je nieuwe wachtwoord in"
              />
              <password-meter @score="onScore" :password="password" />
              <p v-if="notEnoughChar" class="text-danger error">
                Je wachtwoord moet minimaal 6 tekens bevatten
              </p>
              <p v-if="noPassword" class="text-danger error">
                Er is geen wachtwoord ingevuld
              </p>
            </div>

            <div class="form-group passwordInputRow">
              <label for="passwordInputRepeat">WACHTWOORD HERHALEN</label>
              <input
                v-model="passwordRepeat"
                type="password"
                class="form-control"
                id="passwordInputRepeat"
                placeholder="voer nogmaals je nieuwe wachtwoord in"
              />
              <p v-if="noPasswordRepeat" class="text-danger error">
                Het wachtwoord is niet herhaald
              </p>
              <p v-if="passwordsNotSame" class="text-danger error">
                Beide wachtwoorden moeten hetzelfde zijn
              </p>
            </div>

            <button
              v-if="!this.changingPassword"
              type="submit"
              @click.prevent="resetPassword()"
              class="btn btn-primary loginButton"
            >
              Nieuw wachtwoord opslaan
            </button>
            <button
              v-if="this.changingPassword"
              class="btn btn-primary loginButton"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only"> Nieuw wachtwoord opslaan...</span>
            </button>
          </div>
          <div class="col-lg-4 imageHouseWrapper">
            <img class="imageHouse" src="../../../assets/database.svg" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import PasswordMeter from "vue-simple-password-meter";
import authService from "../../../service/AuthService"

export default {
  components: {
    PasswordMeter,
  },
  data() {
    return {
      password: "",
      passwordRepeat: "",
      passwordIsReset: false,
      notEnoughChar: false,
      passwordsNotSame: false,
      noPassword: false,
      noPasswordRepeat: false,
      changingPassword: false,
    };
  },
  setup() {
    const score = ref(null);
    const onScore = (payload) => {
      score.value = payload.score;
    };

    return {
      onScore,
      score,
    };
  },
  created() {
    this.checkPasswordToken();
  },
  methods: {
    async resetPassword() {
      this.noPassword = false;
      this.noPasswordRepeat = false;
      this.passwordsNotSame = false;
      this.notEnoughChar = false;

      if (this.password == "") {
        this.noPassword = true;
      }
      if (this.passwordRepeat == "") {
        this.noPasswordRepeat = true;
      }

      if (this.password.length < 6) {
        this.notEnoughChar = true;
      }

      if (this.password != this.passwordRepeat) {
        this.passwordsNotSame = true;
      }

      if (
        (this.password == this.passwordRepeat) &
        (this.password.length >= 6)
      ) {
        this.changingPassword = true;
        await authService.changePassword(this.password, this.$route.params.uuid)
        this.$router.push({path: "/wachtwoord-veranderd"});
      }
    },
    checkPasswordToken() {
      authService.checkPasswordToken(this.$route.params.uuid)
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  background-image: url("../../../assets/background_md_55.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  padding-bottom: 0;
}
#login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loginWrapper {
  width: 75%;
  min-height: 25rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
  padding: 1.5rem 4rem;
  display: flex;
  height: 100%;

  form {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.passwordResetContent {
  padding-right: 6rem;
}

.passwordResetInstuction {
  margin-top: 2rem;

  .headTitle {
    margin-bottom: 0;
    font-weight: bold;
  }

  p {
    width: 90%;
    line-height: 1.3rem;
  }
}

.passwordInputRow label {
  font-weight: bold;
  font-size: 12px;
}
.keepLoggedInRow {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.imageHouse {
  width: 100%;
}

.imageHouseWrapper {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.loginButton {
  width: 100%;
  height: 3rem;
  margin-top: 2rem;
}

.error {
  font-weight: bold;
  font-size: 12px;
  margin-top: 0.5rem;
}

#passwordInput,
#passwordInputRepeat {
  background-color: #fcfcfc;
  border: none;
  padding: 1rem 2rem;
  width: 100%;
}
.mdLogo {
  width: 6rem;
}
@media screen and (max-width: 992px) {
  .passwordResetContent {
    padding-right: 0;
  }
  .imageHouseWrapper {
    justify-content: center;
    margin-top: 2rem;
  }

  .loginWrapper {
    width: 95%;
    padding: 1rem;
  }

  .mdLogo {
    width: 4rem;
  }

  .imageHouse {
    max-width: 14rem;
  }
}
</style>
