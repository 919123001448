<template>
  <div>
    <div v-if="Object.keys(this.$store.state.invoiceDetail).length == 0" class="spinner-wrapper">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div class="invoiceTemplate" v-if="Object.keys(this.$store.state.invoiceDetail).length > 0 ">
      <div class="container">
        <div class="goBack">
          <router-link to="/facturen">Terug naar alle facturen</router-link>
        </div>
        <div class="invoiceTemplateWrapper">
          <h3>
            Factuur {{ this.$store.state.invoiceDetail["invoice_number"] }}
          </h3>
          <p>Onderwerp: {{ this.$store.state.invoiceDetail["subject"] }}</p>
          <table class="table tableLines">
            <thead>
              <tr>
                <th scope="col">Omschrijving</th>
                <th scope="col">Aantal</th>
                <th scope="col">Prijs</th>
                <th scope="col">Totaal</th>
                <th scope="col">Btw</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="line in this.$store.state.invoiceDetail['invoice_lines']"
                v-bind:key="line"
              >
                <td>{{ line["description"] }}</td>
                <td>{{ this.roundDecimal(line["amount"]) }}</td>
                <td>{{ this.roundDecimal(line["price"]) }}</td>
                <td>
                  {{ this.calculateTotal(line["amount"], line["price"]) }}
                </td>
                <td>21%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="fw-bold">Subtotaal</td>
                <td>
                  {{
                    this.roundDecimal(
                      this.$store.state.invoiceDetail["total_excluding_vat"]
                    )
                  }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Btw 21%</td>
                <td>
                  {{
                    this.roundDecimal(
                      this.$store.state.invoiceDetail["total_vat"]
                    )
                  }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="fw-bold">Totaal</td>
                <td class="fw-bold">
                  {{
                    this.roundDecimal(
                      this.$store.state.invoiceDetail["total_including_vat"]
                    )
                  }}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import invoiceService from "../../service/InvoiceService";
import axios from "axios";
export default {
  methods: {
    roundDecimal(object) {
      return parseFloat(object).toFixed(2).replace(".", ",");
    },
    calculateTotal(hours, price) {
      let total = hours * price;
      return this.roundDecimal(total);
    },
    loadData() {
      invoiceService.getInvoiceById(this.$route.params.id);
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.goBack {
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.invoiceTemplateWrapper {
  background-color: white;
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}
.invoiceTemplate {
  margin-top: 2rem;
  padding-bottom: 5rem;
}

table {
  border-collapse: separate;
  empty-cells: hide;
}

.spinner-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(50vh - 6rem);

  .spinner-border {
    height: 3rem;
    width: 3rem;
  }
}
</style>
