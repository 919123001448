<template>
  <div id="issueDetail">
    <vue-easy-lightbox
      scrollDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <issue-detail-skeleton
      v-if="!this.$store.state.issueDetailLoaded"
    ></issue-detail-skeleton>
    <template v-if="this.$store.state.issueDetailLoaded">
      <div class="issueDetailHeader">
        <div class="container">
          <router-link to="/issues" class="highlightBlue"
            ><img src="../../assets/arrow_left.png" /> Alle issues</router-link
          >
          <h3>Issue: {{ this.$store.state.issue["title"] }}</h3>
          <div
            v-if="this.$store.state.issue['labels']"
            class="label"
            :style="
              'background-color:' +
              this.setLabelColor(this.$store.state.issue['labels'][0])
            "
          >
            <span v-if="this.$store.state.issue['labels'].length > 0">{{
              this.$store.state.issue["labels"][0]
            }}</span>
            <span v-else>Geen label aanwezig</span>
          </div>
        </div>
      </div>
      <div class="issueDetailInfo">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 sidebar" v-if="this.$store.state.issue">
              <div class="contentWrapper">
                <div class="content-container">
                  <div class="project">
                    <h6>Project</h6>
                    <p>{{ this.$store.state.repo["name"] }}</p>
                  </div>

                  <div class="description">
                    <h6>Omschrijving</h6>
                    <p
                      v-if="this.$store.state.issue['description']"
                      v-html="this.description"
                    ></p>
                    <p v-if="!this.description">Er is geen beschrijving</p>
                  </div>

                  <div class="developer">
                    <h6>Medewerker</h6>
                    <p v-if="!this.$store.state.issue['assignee']">
                      Er is nog geen medewerker aangewezen
                    </p>
                    <p v-if="this.$store.state.issue['assignee']">
                      <img
                        class="avatarAssignee"
                        :src="this.$store.state.issue['assignee']['avatar_url']"
                      />{{ this.$store.state.issue["assignee"]["name"] }}
                    </p>
                  </div>
                </div>

                <div
                  v-if="this.$store.state.issue['time_stats']"
                  :class="
                    'extended-content-container ' +
                    (this.$store.state.issue['time_stats'][
                      'human_time_estimate'
                    ] && !timeEstimateIsAgreed()
                      ? 'waitingForAgreement'
                      : '')
                  "
                >
                  <div class="esitmatedTime">
                    <h6>
                      Tijdsinschatting
                      <div class="tooltipWrapper">
                        <img src="../../assets/question-mark.png" />
                        <span class="tooltiptext"
                          >Onze programmeurs maken een inschatting voor de
                          geschatte hoeveelheid tijd die we nodig hebben om het
                          op te lossen. Na akkoord gaan we hiermee aan de
                          slag.</span
                        >
                      </div>
                    </h6>
                    <p
                      v-if="
                        !this.$store.state.issue['time_stats'][
                          'human_time_estimate'
                        ]
                      "
                    >
                      Er is nog geen tijdsinschatting gemaakt
                    </p>
                    <p
                      v-if="
                        this.$store.state.issue['time_stats'][
                          'human_time_estimate'
                        ] && !timeEstimateIsAgreed()
                      "
                    >
                      Er is een tijdsinschatting gemaakt van
                      {{
                        this.timeEstimateFormat(
                          this.$store.state.issue["time_stats"][
                            "human_time_estimate"
                          ]
                        )
                      }}
                      Ga je hiermee akkoord?
                    </p>
                    <button
                      @click="showPopUpTimeEstimate()"
                      v-if="
                        this.$store.state.issue['time_stats'][
                          'human_time_estimate'
                        ] &&
                        !timeEstimateIsAgreed() &&
                        !this.sendRequestTimeEstimateAgreed
                      "
                      class="btn btn-primary"
                    >
                      Tijdsinschatting akkoord
                    </button>
                    <button
                      class="btn btn-primary"
                      type="button"
                      disabled
                      v-if="this.sendRequestTimeEstimateAgreed"
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">
                        Tijdsinschatting accorderen...</span
                      >
                    </button>

                    <p
                      v-if="timeEstimateIsAgreed()"
                      class="text-success fw-bold"
                    >
                      Tijdsinschatting van
                      {{
                        this.timeEstimateFormat(
                          this.$store.state.issue["time_stats"][
                            "human_time_estimate"
                          ]
                        )
                      }}
                      is akkoord!
                    </p>
                  </div>
                </div>

                <div class="content-container">
                  <div class="dueDate">
                    <h6>
                      Verwachte opleverdatum

                      <div class="tooltipWrapper">
                        <img src="../../assets/question-mark.png" />
                        <span class="tooltiptext"
                          >Wij proberen de wens of aanpassing op te leveren op
                          de aangegeven datum, maar dit is geen harde
                          belofte.</span
                        >
                      </div>
                    </h6>

                    <p v-if="!this.$store.state.issue.due_date">
                      Er is geen verwachte opleverdatum
                    </p>
                    <p v-if="this.$store.state.issue.due_date">
                      <img
                        class="calenderIcon"
                        src="../../assets/calender_icon.png"
                      />
                      Ingepland op
                      <span class="highlightBlue">{{
                        this.localizeDate(this.$store.state.issue["due_date"])
                      }}</span>
                    </p>
                  </div>

                  <div class="files">
                    <h6>Bestanden</h6>
                    <div class="filesWrapper">
                      <p v-if="this.urls.length == 0">Er zijn geen bestanden</p>
                      <div
                        class="fileContent"
                        v-for="url in this.urls"
                        v-bind:key="url"
                      >
                        <div class="top">
                          <img
                            v-bind:src="url"
                            style="cursor: zoom-in"
                            v-on:click="showSingle(url)"
                          />
                        </div>

                        <div class="bottom">
                          <p class="fileName">
                            {{ this.getFileNameFromUrl(url) }}
                          </p>

                          <a class="seeImageModal" v-on:click="showSingle(url)"
                            >Bekijken</a
                          >
                          <a v-bind:href="url" target="_blank" download
                            >Downloaden</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-container">
                <button
                  class="btn btn-primary closeIssue"
                  v-on:click="closeIssue()"
                >
                  Issue sluiten - alles akkoord
                </button>
              </div>
            </div>

            <div class="col-lg-8 chatFeed">
              <div id="feed" class="feed">
                <div id="chatMessagesFeed" v-html="this.html.join(' ')"></div>

                <div class="clear"></div>
              </div>
              <editor
                ref="tm"
                id="tiny"
                class="tinymce"
                api-key="naoakinl1gl8kijppaoycd452vme0yq00hroercgjpbs2q8a"
                v-model="data"
                :init="this.init"
              />
              <div class="uploadFiles">
                <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="btn btn-secondary uploadInput"
                  style="opacity: 0; overflow: hidden; height: 1px"
                  @change="onChange"
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  v-if="!this.uploadingImage"
                />

                <label
                  v-if="!this.uploadingImage"
                  for="assetsFieldHandle"
                  class="btn btn-secondary uploadFilesButton"
                >
                  <img class="uploadIcon" src="../../assets/download.png" />
                  <span class="d-none d-md-inline-block uploadFilesButtonText">
                    Upload bestanden
                  </span>
                </label>
                <label
                  v-if="this.uploadingImage"
                  class="btn btn-secondary uploadFilesButton"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only"> Bestanden uploaden...</span>
                </label>
                <a
                  class="btn btn-primary sendMessage"
                  v-if="!this.sendingMessage"
                  @click="sendMessage()"
                  >Versturen</a
                >
                <button
                  class="btn btn-primary sendMessage"
                  type="button"
                  disabled
                  v-if="this.sendingMessage"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only"> Versturen...</span>
                </button>
              </div>
              <p class="text-danger fw-bold" v-if="this.sendingMessageError">
                *Typ een bericht of upload een foto en verstuur deze
              </p>

              <div class="uploadFilesPreview" v-if="this.filelist.length">
                <div class="mt-4 row">
                  <div
                    class="col-lg-4"
                    v-for="file in this.filelist"
                    v-bind:key="file"
                  >
                    <button
                      @click="remove(filelist.indexOf(file))"
                      class="removePreview"
                    >
                      X
                    </button>
                    <img
                      :src="
                        'https://mijn.millerdigital.nl/api/temp/' +
                        this.getFileName(file)
                      "
                    />
                    <span>{{ file.split("temp/")[1] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8 closeConfirm">
              <div class="closeConfirmWrapper">
                <h4>Je sluit dit issue af</h4>
                <p class="fw-bold">Ben je akkoord met het resultaat?</p>

                <p>
                  Zo ja wat fijn dat je tevreden bent. Dan kunt u dit issue
                  gerust afsluiten.
                </p>
                <p>
                  Zo niet, ga terug en neem contact met ons op over wat u wilt
                  veranderen.
                </p>

                <div class="buttonRow">
                  <button
                    :disabled="this.closingIssue"
                    class="btn btn-primary-outline"
                    @click="declineClose()"
                  >
                    Terug naar issue
                  </button>
                  <button
                    v-if="!this.closingIssue"
                    class="btn btn-primary confirmCloseBtn"
                    @click="
                      confirmCloseIssue(
                        this.$route.params.projectId,
                        this.$route.params.iid
                      )
                    "
                  >
                    Issue sluiten
                  </button>
                  <button
                    class="btn confirmCloseBtn"
                    type="button"
                    disabled
                    v-if="this.closingIssue"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only"> Issue sluiten...</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="mdPopup" style="display: none">
      <div class="closeConfirmWrapper">
        <h4>Je gaat akkoord met de tijdsinschatting</h4>
        <p class="fw-bold">Onze collega's gaan er mee aan de slag</p>

        <p>
          Zodra je akkoord geeft worden er uren gemaakt op dit project. Deze
          uren worden gefactureerd. Mochten we er over heen gaan, wordt dit
          uiteraard gecommuniceerd.
        </p>
        <div class="buttonRow">
          <button
            :disabled="this.closingIssue"
            class="btn btn-primary-outline"
            @click="declineTimeEstimate()"
          >
            Annuleren
          </button>
          <button
            v-if="!this.sendRequestTimeEstimateAgreed"
            class="btn confirmCloseBtn"
            @click="
              setTimeEstimateAgreed(
                this.$route.params.projectId,
                this.$route.params.iid
              )
            "
          >
            Akkoord
          </button>
          <button
            class="btn confirmCloseBtn"
            type="button"
            disabled
            v-if="this.sendRequestTimeEstimateAgreed"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"> Akkoord...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";
import { mapActions } from "vuex";
// import turndown from "turndown";
const TurndownService = require("turndown").default;
const showdown = require("showdown");

import jQuery from "jquery";
import issueService from "../../service/IssueService";
import projectService from "../../service/ProjectService";
import IssueDetailSkeleton from "../../components/skeletons/issues/IssueDetailSkeleton.vue";
const $ = jQuery;
window.$ = $;

export default {
  name: "IssueDetail",
  components: {
    editor: Editor,
    IssueDetailSkeleton,
  },
  data: function () {
    return {
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      issue: "",
      project: "",
      description: "",
      loaded: false,
      data: "",
      messages: [],
      html: [],
      images: [],
      urls: [],
      sendingMessage: false,
      sendRequestTimeEstimateAgreed: false,
      connection: null,
      uploadingImage: false,
      sendingMessageError: false,
      filelist: [],
      closingIssue: false,
      issueDataLoaded: false,
      stopWebsocket: false,
      init: {
        height: 250,
        menubar: false,
        allow_html_in_named_anchor: false,
        paste_data_images: false,
        automatic_uploads: true,
        mobile: {
          theme: "silver",
          plugins: "autosave lists autolink",
          toolbar: "undo bold italic styleselect",
        },

        plugins: [
          "advlist autolink lists link charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | link | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help",
      },
    };
  },
  async created() {
    this.$store.state.issueDetailLoaded = false;
    await this.loadIssueData(
      this.$route.params.projectId,
      this.$route.params.iid
    );
    await this.loadProjectData(this.$route.params.projectId);
    await this.loadIssueDiscussion(
      this.$route.params.projectId,
      this.$route.params.iid
    );
    await this.startWebsocket();
    this.setMessages(this.$store.state.discussion);
    this.setDescription(
      this.$store.state.issue.description,
      this.$store.state.repo.web_url
    );
  },
  unmounted() {
    if (this.$store.state.issueDetailLoaded) {
      this.stopWebsocket = true;
      this.closeWebsocket();
    }
  },
  methods: {
    showSingle(url) {
      this.imgs = url;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    // load data
    async loadIssueDiscussion(projectId, issueId) {
      await issueService.getIssueDiscussion(projectId, issueId);
    },
    async loadIssueData(projectId, issueId) {
      await issueService.getIssue(projectId, issueId);
    },
    async loadProjectData(projectId) {
      await projectService.getGitProject(projectId);
    },
    // start websocket
    async startWebsocket() {
      // this.connection = await new WebSocket(
      //   "wss://mijn.millerdigital.nl/websocket"
      // );
      console.log(process.env.VUE_APP_WS);
      this.connection = await new WebSocket(process.env.VUE_APP_WS);

      this.connection.onmessage = (e) => {
        let jsonData = JSON.parse(e.data);
        var currentdate = new Date();
        var strMinutes = "" + currentdate.getMinutes();
        if (strMinutes.length == 1) {
          strMinutes = "0" + strMinutes;
        }
        this.html.push(
          `<div class="messageDeveloper message">
                <div class="messageContent">
                  ${this.MdToHTML(jsonData["msg"])}
                  <p class="messageTimeDate">${jsonData["developer_name"]} 
                  op ${
                    currentdate.getDate() + "-" + (currentdate.getMonth() + 1)
                  } om ${currentdate.getHours() + ":" + strMinutes}</p>
                </div>
          </div>`
        );
        $("#feed").animate({ scrollTop: $("#chatMessagesFeed").height() }, 500);
      };

      this.connection.onopen = () => {
        console.log("Successfully connected to the echo websocket server...");
        var data = {
          type: "register",
          issueId: this.$route.params.iid,
          projectId: this.$route.params.projectId,
          userToken: localStorage.getItem("token"),
        };
        console.log(data);
        this.connection.send(JSON.stringify(data));
      };

      this.connection.onclose = async () => {
        if (!this.stopWebsocket) {
          await this.startWebsocket();
        }
        console.log("Closed connection...");
      };
    },
    // helpers
    timeEstimateFormat(timeEstimate) {
      let format = timeEstimate;
      let times = format.split(" ");

      times.forEach((element, key) => {
        let amount = parseInt(element.substring(0, element.length - 1));
        times[key] =
          (key == times.length - 1 && times.length != 1 ? " en " : "") +
          element
            .replace("d", amount == 1 ? " dag" : " dagen")
            .replace("w", amount == 1 ? " week" : " weken")
            .replace("h", amount == 1 ? " uur" : " uur")
            .replace("m", amount == 1 ? " minuut" : " minuten")
            .replace("s", amount == 1 ? " Seconde" : " seconden");
      });

      return times.join(" ");
    },
    getFileName(file) {
      return file.split("temp/")[1];
    },
    closeWebsocket() {
      this.connection.close();
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    async uploadImage() {
      this.uploadingImage = true;
      var formData = new FormData();
      let count = 0;
      let fileListArr = Array.from(this.$refs.file.files);

      fileListArr.forEach((element) => {
        if (element.size > 1024 * 1024 * 5) {
          alert("bestand is te groot");
          return;
        }
        count++;
        formData.append("file_" + count, element);
      });

      let images = await issueService.uploadFile(formData);
      images.forEach((element) => {
        this.filelist.push(element);
      });

      this.uploadingImage = false;
    },
    onChange() {
      this.uploadImage();
    },
    async sendMessage() {
      this.sendingMessageError = false;
      if (!this.data && this.filelist.length == 0) {
        this.sendingMessageError = true;
        return;
      }
      this.sendingMessage = true;
      var turndownService = new TurndownService().keep(["img"]);

      const markdown = turndownService.turndown(this.data);

      const data = {
        message: markdown,
        imagePaths: this.filelist,
      };

      let message = await issueService.sendMessage(
        this.$route.params.projectId,
        this.$route.params.iid,
        data
      );

      const dateAndTime = message["notes"][0]["created_at"].split("T");
      const date = this.localizeDate(dateAndTime[0]);
      const time =
        dateAndTime[1].split(":")[0] + ":" + dateAndTime[1].split(":")[1];
      this.html.push(
        `<div class="messageCustomer message">
                <div class="messageContent">
                  ${this.MdToHTML(message["notes"][0]["body"])}
                  <p class="messageTimeDate">Jij schreef op ${date} om ${time}</p>
                </div>
              </div>`
      );
      this.data = "";
      this.sendingMessage = false;
      this.filelist = [];
      $("#feed").animate({ scrollTop: $("#chatMessagesFeed").height() }, 500);
    },
    localizeDate(date) {
      const splittedDate = date.split("-");
      return splittedDate[2] + "-" + splittedDate[1];
    },
    declineClose() {
      $(".closeConfirm").hide();
      $(".chatFeed").show();
      $(".sidebar").removeClass("inActive");
      $(".issueDetailHeader").removeClass("inActive");
    },
    async confirmCloseIssue(projectId, issueId) {
      this.closingIssue = true;
      await issueService.closeIssue(projectId, issueId);
      this.closingIssue = false;
      this.$router.push({ path: "/issues" });
    },
    closeIssue() {
      $("html, body").animate({ scrollTop: 0 }, 100);

      $(".closeConfirm").show();
      $(".chatFeed").hide();
      $(".sidebar").addClass("inActive");
      $(".issueDetailHeader").addClass("inActive");
    },
    getFileNameFromUrl(url) {
      return url.substring(url.lastIndexOf("/") + 1);
    },
    setMessages(messagesArray) {
      messagesArray.forEach((message) => {
        message["notes"].forEach((note) => {
          const messageSender =
            note["author"]["username"] == "Millerdashboard"
              ? "messageCustomer"
              : "messageDeveloper";

          let isSystem = note["system"];

          const dateAndTime = note["created_at"].split("T");
          const date = this.localizeDate(dateAndTime[0]);
          const time =
            dateAndTime[1].split(":")[0] + ":" + dateAndTime[1].split(":")[1];
          this.html.push(`<div class="${
            isSystem ? "system" : messageSender
          } message">
                <div class="messageContent">
                  ${this.MdToHTML(note["body"])}
                  <p class="messageTimeDate">${this.getMessageSendUser(
                    isSystem,
                    note["author"]["name"],
                    messageSender
                  )} op ${date} om ${time}</p>
                </div>
              </div>`);
        });
      });
      setTimeout(() => {
        $("#feed").animate({ scrollTop: $("#chatMessagesFeed").height() }, 300);
      }, 1);
    },
    getMessageSendUser(isSystem, authorName, messageSender) {
      if (isSystem) {
        return "Automatisch bericht";
      } else if (messageSender == "messageCustomer") {
        return "Jij schreef";
      } else if (messageSender == "messageDeveloper") {
        return authorName;
      }
    },
    canSplit(str, token) {
      return (str || "").split(token).length > 1;
    },
    setDescription(description, projectUrl) {
      if (this.canSplit(description, "[/!]<br>")) {
        description = description.split("[/!]<br>")[1];
      }
      const descriptionHtml = this.MdToHTML(description);

      var stringWithoutImg = descriptionHtml.replace(/<img[^>]*>/g, "");
      var imagesWithRelativeUrlArray = descriptionHtml.match(/<img[^>]*>/g);
      if (imagesWithRelativeUrlArray != null) {
        var urls = [];
        imagesWithRelativeUrlArray.forEach((element) => {
          urls.push(element.match(/<img[^>]+src="([^">]+)"/)[1]);
        });

        this.urls = urls;
      }
      this.description = stringWithoutImg;
    },
    MdToHTML(markdownValue) {
      const converter = new showdown.Converter();
      let text = markdownValue;
      let html = converter.makeHtml(text);
      let relativeProjectUrl =
        this.$store.state.issue["references"]["full"].split("#")[0];

      return html.replaceAll(
        "/uploads",
        "https://mijn.millerdigital.nl/api/git"
      );
      
    },
    showPopUpTimeEstimate() {
      $("html, body").animate({ scrollTop: 0 }, 100);
      $(".mdPopup").show();
      $(".issueDetailHeader").css({ opacity: "0.3", "pointer-events": "none" });
      $(".issueDetailInfo").css({ opacity: "0.3", "pointer-events": "none" });
    },
    declineTimeEstimate() {
      $(".mdPopup").hide();
      $(".issueDetailHeader").css({ opacity: "1", "pointer-events": "auto" });
      $(".issueDetailInfo").css({ opacity: "1", "pointer-events": "auto" });
    },
    async setTimeEstimateAgreed(projectId, issueId) {
      this.sendRequestTimeEstimateAgreed = true;
      await issueService.setTimeEstimateAgreed(projectId, issueId);
      window.location.reload();
    },
    timeEstimateIsAgreed() {
      let hasLabelDoorvoeren = false;
      let hasTimeEstimate = false;
      if (this.$store.state.issue["labels"]) {
        this.$store.state.issue["labels"].forEach((element) => {
          if (element == "Doorvoeren") {
            hasLabelDoorvoeren = true;
          }
        });
      }
      if (this.$store.state.issue["time_stats"]["human_time_estimate"]) {
        hasTimeEstimate = true;
      }

      return hasLabelDoorvoeren && hasTimeEstimate;
    },
    async updateIssue(projectId, issueIid) {
      await axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "gitlab/updatedIssue/" +
            projectId +
            "/" +
            issueIid,
          null,
          {
            headers: {
              Authorization: `${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          this.issue = res.data;
        });
    },
    setLabelColor(label) {
      switch (label) {
        case "Afwachting klant":
          return "#7F8C8D";
        case "Akkoord klant?":
          return "#ed9121";
        case "Bij livegang":
          return "#9400d3";
        case "Doorvoeren":
          return "#69D100";
        case "Geen oplossing beschikbaar":
          return "#FF0000";
        case "Inschatten":
          return "#0033CC";
        case "Klokken op strippenkaart":
          return "#FFECDB";
        case "Meer info nodig / overleggen":
          return "#F0AD4E";
        case undefined:
          return "#a69392";
      }
    },
  },
};
</script>

<style lang="scss">
#issueDetail {
  position: relative;
  padding-bottom: 8rem;

  .label {
    background-color: #69d100;
    border-radius: 2rem;
    width: fit-content;
    padding: 0.1rem 0.7rem;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
}
.issueDetailHeader {
  margin-top: 2rem;

  h2 {
    margin-top: 1rem;
    max-width: 75%;
  }
  .label {
    margin-top: 1rem;
  }
}

.issueDetailInfo {
  margin-top: 2rem;
}

.sidebar.inActive,
.issueDetailHeader.inActive {
  opacity: 0.5;
  pointer-events: none;
}
.row > .sidebar {
  padding-left: 0;
  padding-right: 0;
}

.issueDetailInfo .sidebar {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .esitmatedTime {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .extended-content-container.waitingForAgreement {
    background-color: #fff3cc;

    .esitmatedTime {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  background-color: #ffffff;
  // height: 100%;
  transition: all 0.2s ease-in;

  .progress {
    border-radius: 50px;
    height: 0.6rem;

    .progress-bar {
      background-color: #ffc400;
      border-radius: 50px;
    }
  }
}

.progressWrapper {
  display: flex;
}

.workHours {
  width: 75%;
}

.overTimeHours {
  width: 25%;
}

// .project,
// .description,
// .developer,
// .dueDate,
// .progress,
// .esitmatedTime {
//   margin-bottom: 2rem;
// }

.avatarAssignee {
  max-width: 40px;
  margin-right: 0.5rem;
}
.calenderIcon {
  width: 22px;
  margin-right: 0.5rem;
}

.filesWrapper {
  width: 100%;

  img {
    width: 100%;
    object-fit: cover;
    max-height: 300px;
  }

  .fileName {
    margin-bottom: 0;
    padding: 0.5rem 0;
    font-size: 17px;
  }

  .fileContent {
    margin-bottom: 1rem;
    border-radius: 4px;
    display: flex;

    .top {
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      max-width: 125px;
      max-height: 75px;
      img {
        object-fit: contain;
        height: 100%;
      }
    }
    .bottom {
      padding: 0.2rem 0;
      margin-left: 1rem;
      a {
        color: rgba(0, 0, 0, 0.8);
        text-decoration: none;
        cursor: pointer;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .seeImageModal {
    margin-right: 1rem;
  }
}

.btn.closeIssue {
  background-color: #ffc400;
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 1rem 0;
}

.chatFeed {
  padding: 0 5rem;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
}
.mdPopup {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 30rem;
}
.closeConfirm {
  height: 100%;
  padding: 0 3rem;
  max-width: 40rem;
  display: none;
}

.btn.confirmCloseBtn {
  background-color: #ffc400;
  color: white;
  font-weight: bold;
  border: none;
}

.confirmCloseBtn:hover,
.closeIssue:hover {
  color: #ffc400;
  border: 1px solid #ffc400;
  background-color: transparent;
}

.closeConfirmWrapper {
  background-color: white;
  padding: 2rem;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.07);
}

.feed {
  height: 30rem;
  overflow-y: scroll;
  margin-bottom: 2rem;
}
#issueDetail {
  .uploadIcon {
    margin-right: 0;
  }
  .uploadFilesButtonText {
    margin-left: 1rem;
  }

  .uploadFiles {
    background: #ffffff;
    border-bottom: 1px solid rgb(204, 204, 204);
    border-left: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    padding: 1rem;
    display: flex;
    justify-content: flex-end;

    .uploadInput {
      width: 1px;
    }

    .uploadFilesButton {
      background-color: #fafafa;
      color: black;
      padding: 0.5rem 1.5rem;
      font-weight: bold;
      margin-right: 1rem;
    }

    .sendMessage {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.tox-tinymce {
  width: 100% !important;
}

.message {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;

  .messageContent {
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      max-height: 300px;
      object-fit: contain;
    }
  }
}

.system.message {
  background-color: rgb(196, 255, 224);
  justify-content: center;

  .messageContent {
    width: 100%;
    text-align: center;
  }
}

.system.error {
  background-color: red;
  color: white;
}
.messageDeveloper {
  padding-left: 1rem;

  .messageContent {
    background-color: #e6f9ff;

    p {
      margin-bottom: 10px;
    }
  }
}

.messageCustomer {
  justify-content: flex-end;
  padding-right: 1rem;
  .messageContent {
    background-color: #ffffff;

    p {
      margin-bottom: 10px;
    }
  }
}

.messageContent {
  max-width: 60%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.2rem;
}
.messageTimeDate {
  font-weight: bold;
  font-size: 14px;
  margin-top: 0.2rem;
}

.modal-body {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 100vh;
  }
}

.uploadFilesPreview {
  .col-lg-4 {
    margin-bottom: 1.5rem;
    position: relative;

    .removePreview {
      position: absolute;
      right: 0;
      top: -10px;
      background-color: black;
      color: white;
      border: none;
      display: inline-block;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      box-sizing: border-box;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 13rem;
    }
  }
}
.tooltipWrapper {
  position: relative;
  display: inline-block;

  img {
    width: 15px;
    opacity: 0.5;
  }
}

.tooltipWrapper .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: black;
  opacity: 0.9;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -125px;
  font-weight: normal;
}

.tooltipWrapper .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltipWrapper:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 992px) {
  .chatFeed {
    padding: 0 0.5rem;
    margin-top: 2rem;
  }

  .messageContent {
    max-width: 75%;
    padding: 1rem;
  }
}

@media screen and (max-width: 991px) {
  .issueDetailInfo .row {
    flex-direction: column-reverse;
  }

  .sidebar {
    margin-top: 3rem;
  }
}
</style>
