<template>
  <div class="container mt-4">
    <router-link to="/admin/accounts">Ga terug naar overzicht</router-link>
    <h2>Gebruiker toevoegen</h2>
    <div class="form-group">
      <label class="form-label">E-mail</label>
      <input
        type="text"
        v-model="email"
        class="form-control mb-1"
        placeholder="Email"
      />
    </div>
    <div class="form-group">
      <label class="form-label">Voornaam</label>
      <input
        type="text"
        v-model="firstname"
        class="form-control mb-1"
        placeholder="Voornaam"
      />
    </div>
    <div class="form-group">
      <label class="form-label">Achternaam</label>
      <input
        type="text"
        v-model="lastname"
        class="form-control mb-1"
        placeholder="Achternaam"
      />
    </div>

    <div class="form-group">
      <label class="form-label">Organisatie</label>
      <select
        v-model="organisation"
        class="form-select"
        aria-label="Default select example"
      >
        <option value="selecteer" selected>Selecteer een organisatie</option>
        <option
          v-for="organisation in this.$store.state.allOrganisations"
          v-bind:key="organisation"
          :value="organisation.id"
        >
          {{ organisation.name }}
        </option>
      </select>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        v-model="this.isAdmin"
      />
      <label class="form-check-label" for="flexCheckDefault">
        Is een admin gebruiker
      </label>
    </div>
    <button v-on:click="addUser()" class="btn btn-primary mt-4">
      Gebruiker toevoegen
    </button>
  </div>
</template>

<script>
import axios from "axios";
import organisationService from "../../service/admin/OrganisationService";
import userService from "../../service/admin/UserService";
export default {
  data() {
    return {
      email: "",
      firstname: "",
      lastname: "",
      organisation: "selecteer",
      organisationsArray: [],
      isAdmin: false,
    };
  },
  created() {
    this.getOrganizations();
  },
  methods: {
    async addUser() {
      if (
        this.email == "" ||
        this.lastname == "" ||
        this.firstname == "" ||
        this.organisation == "selecteer"
      ) {
        alert("vul alle gegevens in!!");
        return;
      }
      let data = {
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        organisationId: this.organisation,
        admin: this.isAdmin,
      };

      await userService.addUser(data);
      this.$router.push({ path: "/admin/accounts" });
    },
    getOrganizations() {
      organisationService.getAllOrganisations();
    },
  },
};
</script>

<style scoped></style>
