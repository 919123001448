<template>
  <!-- <transition name="fade">
    <div>
      <subscription-skeleton
        v-if="!this.$store.state.loadedAllData"
      ></subscription-skeleton>
    </div>
  </transition> -->

  <div id="subscription">
    <subscription-skeleton
      v-if="!this.$store.state.subscriptionLoaded"
    ></subscription-skeleton>
    <template v-if="this.$store.state.subscriptionLoaded">
      <div class="subscriptionHeader">
        <div class="container">
          <h1 class="subscriptionHeaderTitled">Abonnementen</h1>
          <span> Consequent doorontwikkelen tegen een lager tarief </span>
        </div>
      </div>

      <div
        class="noSubscription"
        v-if="Object.keys(this.$store.state.subscription).length == 0"
      >
        <div class="container">
          <div class="noSubscriptionBlock">
            <div class="row">
              <div class="col-lg-4 imageWrapper">
                <img
                  class="subscriptionImage"
                  src="../../assets/subscription.svg"
                />
              </div>
              <div class="col-lg-8 explanation">
                <h4>Je hebt nog geen doorontwikkelingsabonnement</h4>
                <h6>Wat is een doorontwikkelingsabonnement</h6>
                <p>
                  Een doorontwikkelingsabonnement sluit je af als je maandelijks
                  een vast bedrag wil storten en wij daarvoor aan de slag gaan
                  met je site. Des te groter de hoeveelheid uren die je afneemt
                  per maand, des te hoger je korting. Een
                  doorontwikkelingsabonnement sluit je af voor de looptijd van 1
                  jaar en is daarna maandelijks opzegbaar, dus je hebt voldoende
                  flexibiliteit. Je tegoed verloopt niet en je kan dus ook
                  sparen voor grotere aanpassingen in de toekomst.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="availableSubscriptions"
        v-if="Object.keys(this.$store.state.subscription).length == 0"
      >
        <div class="container">
          <div class="availableSubscriptionsWrapper">
            <h4>Beschikbare abonnementen</h4>

            <div class="subscriptionsRows">
              <div class="subscriptionRow">
                <div class="left">
                  <p>
                    Doorontwikkelingsabonnement
                    <span class="fw-bold">5 uur</span>
                  </p>
                  <p class="text-muted">€475.- per maand</p>
                </div>
                <div class="right">
                  <button
                    :disabled="this.buyingSubscription"
                    v-if="!this.buyButtons[5]"
                    class="btn btn-primary"
                    @click="this.buySubscription(5, 475)"
                  >
                    Abonnement starten
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    disabled
                    v-if="this.buyButtons[5]"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only"> Abonnement starten...</span>
                  </button>
                </div>
              </div>

              <div class="subscriptionRow">
                <div class="left">
                  <p>
                    Doorontwikkelingsabonnement
                    <span class="fw-bold">10 uur</span>
                  </p>
                  <p class="text-muted">
                    €855.- per maand (in plaats van 950.-)
                  </p>
                </div>
                <div class="right">
                  <p class="saveSubscription">
                    je bespaart <br />
                    <span>€95.- per maand</span>
                  </p>
                  <button
                    :disabled="this.buyingSubscription"
                    v-if="!this.buyButtons[10]"
                    class="btn btn-primary"
                    @click="this.buySubscription(10, 855)"
                  >
                    Abonnement starten
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    disabled
                    v-if="this.buyButtons[10]"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only"> Abonnement starten...</span>
                  </button>
                </div>
              </div>

              <div class="subscriptionRow">
                <div class="left">
                  <p>
                    Doorontwikkelingsabonnement
                    <span class="fw-bold">20 uur</span>
                  </p>
                  <p class="text-muted">
                    1520.- per maand (in plaats van 1900.-)
                  </p>
                </div>
                <div class="right">
                  <p class="saveSubscription">
                    je bespaart <br />
                    <span>€380.- per maand</span>
                  </p>
                  <button
                    :disabled="this.buyingSubscription"
                    v-if="!this.buyButtons[20]"
                    class="btn btn-primary"
                    @click="this.buySubscription(20, 1520)"
                  >
                    Abonnement starten
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    disabled
                    v-if="this.buyButtons[20]"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only"> Abonnement starten...</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="subscription"
        v-if="Object.keys(this.$store.state.subscription).length > 0"
      >
        <div class="container">
          <div class="row">
            <div class="subscriptionSidebar col-lg-4">
              <h6>Abonnement</h6>
              <div class="subscriptionSidebarBlock">
                <div class="subscriptionName sidebarItem">
                  <p class="fw-bold sidebarTitle">Abonnement</p>
                  <p>
                    {{ 
                      this.$store.state.subscription["subscription"]["name"] /* TODO */
                    }}
                  </p>
                </div>
                <div class="subscriptionCost sidebarItem">
                  <p class="fw-bold sidebarTitle">Maandelijkse kosten</p>
                  <p>
                    €{{
                      this.roundDecimal(
                        this.$store.state.subscription["subscription"]["price"] /* TODO */
                      )
                    }}
                    excl. btw
                  </p>
                </div>

                <div class="subscriptionStatus sidebarItem">
                  <p class="fw-bold sidebarTitle">Verbruik</p>
                  <div class="statusRow">
                    <span>Budget</span>
                    <span
                      >€
                      {{ this.roundDecimal(this.totalBudget) }}
                    </span
                    >
                  </div>
                  <div class="statusRow">
                    <span>Besteed</span>
                    <span
                      >€
                      {{ this.roundDecimal(this.spentBudget) }}
                      </span
                    >
                  </div>
                  <div class="statusRow">
                    <span>Verschil</span>
                    <span
                      >€
                        {{ this.roundDecimal(this.totalBudget - this.spentBudget) }}

                      </span
                    >
                  </div>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="
                        this.overBudget
                          ? 'width: 100%'
                          : 'width:' + this.$store.state.subscription.budget_percentage + '%'
                      "
                    >
                      {{ this.roundDecimal(this.totalBudget) }}
                    </div>
                    <div
                      v-if="this.overBudget"
                      class="progress-bar overBudget"
                      role="progressbar"
                      :style="'width: ' + this.$store.state.subscription.subscription.budget_percentage + '%'"
                    >
                      {{ this.roundDecimal((this.totalBudget - this.spentBudget) * -1) }}
                    </div>
                  </div>
                </div>

                <div class="subscriptionLead sidebarItem">
                  <p class="fw-bold sidebarTitle">Project lead</p>
                  <p v-if="!this.$store.state.subscriptionProjectLead">
                    Er is geen project lead aangewezen
                  </p>
                  <div class="div" v-else>
                    <img
                      class="avatarAssignee"
                      :src="
                        this.$store.state.subscriptionProjectLead['avatar_url']
                      "
                    />{{ this.$store.state.subscriptionProjectLead["name"] }}
                  </div>
                </div>

                <div class="subscriptionEndDate">
                  <span class="fw-bold sidebarTitle">Einddatum</span>

                  <img
                    class="calenderIcon"
                    src="../../assets/calender_icon.png"
                  />
                  <p>
                    Verlengd op
                    <span class="highlightBlue">{{
                      this.$store.state.subscription.subscription.invoice_date ?? this.$store.state.subscription.subscription.start_date
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="monthly-mail">
                <h6 class="mt-4">Maandelijks email overzicht</h6>
              </div>
              <div class="form-check form-switch">
                <input
                  @change="toggleActive()"
                  v-model="this.monthlyMailSwitchActive"
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  :disabled="this.toggleActiveLoading"
                />
                <label class="checkboxLabel" for="titel"
                  >Ontvang maandelijks een uren overzicht in de mail</label
                >
              </div>
            </div>
            <div class="subscriptionHours col-lg-8">
              <h6>Geboekte uren</h6>
              <p
                v-if="
                  this.$store.state.subscription['monthly_bookings'].length == 0
                "
              >
                Er zijn geen boekingen
              </p>
              <div class="subscriptionHoursBlock">
                <div class="accordion" id="accordionExample">
                  <div
                    class="accordion-item strippenkaartRow"
                    v-for="(subscription, index) in this.$store.state
                      .subscription['monthly_bookings']"
                    v-bind:key="subscription"
                  >
                    <p
                      class="accordion-header"
                      :id="'heading' + index.toString().split(' ').join('')"
                    >
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="
                          '#collapse' + index.toString().split(' ').join('')
                        "
                        aria-expanded="false"
                        :aria-controls="
                          'collapse' + index.toString().split(' ').join('')
                        "
                      >
                        <div class="col-lg subscriptionMonth">{{ index }}</div>
                        <div class="col-lg amountHours">
                          {{ this.roundDecimal(subscription.total) }}
                          uur
                        </div>
                      </button>
                    </p>
                    <div
                      :id="'collapse' + index.toString().split(' ').join('')"
                      class="accordion-collapse collapse"
                      :aria-labelledby="
                        'heading' + index.toString().split(' ').join('')
                      "
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <table class="table">
                          <tbody>
                            <tr
                              v-for="hours in this.$store.state.subscription[
                                'monthly_bookings'
                              ][index]['hours']"
                              v-bind:key="hours"
                            >
                              <td>
                                {{ this.dateFormat(hours.start_date) }}
                              </td>
                              <td class="hoursNote">
                                {{
                                  hours.note != ""
                                    ? hours.note
                                    : "geen omschrijving"
                                }}
                              </td>
                              <td>{{ this.roundDecimal(hours.hours + hours.corrections.amount) }}</td>
                              <td>
                                €{{
                                  this.roundDecimal((hours.hours * hours.tariff) + hours.corrections.value)
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p class="totalHoursSubscription">
                          Totaal aantal uur:
                          <strong>
                            {{
                              this.roundDecimal(
                                this.$store.state.subscription[
                                  "monthly_bookings"
                                ][index]["total"]
                              )
                            }}
                          </strong>
                        </p>
                      </div>

                      <div class="accordion-body" v-if="this.hoursLoading">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import subscriptionSkeleton from "../../components/skeletons/subscription/SubscriptionSkeleton.vue";
import subscriptionService from "../../service/SubscriptionService";
import gitService from "../../service/GitService";
export default {
  components: {
    subscriptionSkeleton,
  },
  data() {
    return {
      monthlyBookings: [],
      subscriptionTotals: [],
      hoursOnStrippenkaart: [],
      hoursLoading: false,
      subscription: null,
      subscriptionMonthsActive: 0,
      totalHours: 0,
      spendBudget: 0,
      projectLead: null,
      overBudget: false,
      budgetPercentage: 0,
      buyButtons: {
        5: false,
        10: false,
        20: false,
      },
      buyingSubscription: false,
      monthlyMailSwitchActive: false,
      toggleActiveLoading: false
    };
  },
  async created() {
    await this.getSubscription();
    await this.getMonthlyMailSwitch();
    if (Object.keys(this.$store.state.subscription).length != 0) {
      await this.getMainDev();
    }
  },
  methods: {
    async getMonthlyMailSwitch() {
      await subscriptionService.getMonthlyMailSwitch().then((res) => {
        this.monthlyMailSwitchActive = res["data"]["active"];
      });
    },
    async getSubscription() {
      await subscriptionService.getSubscription();

      var correctedBudget = 0;
      for (const [key, value] of Object.entries(this.$store.state.subscription.monthly_bookings)) {
        correctedBudget += value.correction.price;
      }
 
      this.totalBudget = this.$store.state.subscription.project.budget.total.value_budget;
      this.spentBudget = this.$store.state.subscription.project.budget.total.value_spent + correctedBudget;


      await this.calculateBudgetPercentage(
        this.totalBudget,
        this.spentBudget
      );
    },
    async getMainDev() {
      await gitService.getMainDevGroup();
    },
    calculateBudgetPercentage(budget, spend) {
      if (spend <= budget) {
        let usedBudgetPercentage = (spend / budget) * 100;
        this.overBudget = false;
        this.budgetPercentage = usedBudgetPercentage;
        return usedBudgetPercentage;
      } else {
        let difference = spend - budget;
        let percentageOverBudget = (difference / budget) * 100;
        this.overBudget = true;
        this.budgetPercentage = percentageOverBudget;
        return percentageOverBudget;
      }
    },
    roundDecimal(num) {
      return parseFloat(num).toFixed(2).replace(".", ",");
    },
    dateFormat(dateString) {
      let date = new Date(dateString);
      let day = ("0" + date.getDate()).slice(-2);

      let month = ("0" + (date.getMonth() + 1)).slice(-2);

      return day + "-" + month + "-" + date.getFullYear();
    },
    monthDiff(dateFrom, dateTo) {
      return (
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
      );
    },
    async buySubscription(hours, price) {
      this.buyButtons[hours] = true;
      this.buyingSubscription = true;
      await subscriptionService.addSubscription(hours, price);
    },
    async toggleActive() {
      this.toggleActiveLoading = true;
      await subscriptionService.updateMonthlyEmailActive(this.monthlyMailSwitchActive);
      this.toggleActiveLoading = false;

    },
  },
};
</script>

<style scoped lang="scss">
#subscription {
  padding-bottom: 4rem;
}
.subscriptionHeader {
  margin-top: 3rem;
}

.noSubscriptionBlock {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 6px 3px 6px solid rgba(0, 0, 0, 0.16);
  max-width: 960px;
  margin: 3rem auto 0;

  .imageWrapper {
    position: relative;
  }
  .subscriptionImage {
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 0;
    max-width: 24rem;
    object-fit: contain;
  }

  .explanation {
    padding: 3rem 4rem 3rem 5rem;
    z-index: 1;
    h4 {
      padding-top: 2rem;
    }
  }
}

.calenderIcon {
  width: 22px;
  margin-right: 0.5rem;
}

.availableSubscriptionsWrapper {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;

  .subscriptionRow {
    background-color: white;
    width: 100%;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }

    .right {
      display: flex;

      .saveSubscription {
        margin-right: 1.5rem;

        span {
          color: #009fd9;
          font-weight: bold;
        }
      }
    }
  }
}

.form-check-input:checked {
    background-color: #ffc400;
    border-color: #ffc400;
}

.subscription {
  margin-top: 3rem;
  .subscriptionSidebarBlock {
    background-color: white;
    padding: 1.5rem 2rem;

    .sidebarTitle {
      display: block;
      margin-bottom: 0 !important;
    }

    .subscriptionEndDate p {
      display: inline;
    }
  }
}

.accordion-item {
  margin-bottom: 0.5rem;
  border: none;
}
.amountHours {
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
  color: gray;
}

.sidebarItem {
  margin-bottom: 1rem;
}

.sidebarTitle {
  margin-bottom: 0.5rem;
}
.subscriptionMonth {
  font-weight: 500;
}
.statusRow {
  display: flex;
  justify-content: space-between;
}

.progress {
  border-radius: 50px;
  height: 0.6rem;
  margin-top: 1rem;
  height: 1rem;

  .progress-bar {
    background-color: #ffc400;
  }

  .overBudget.progress-bar {
    background-color: red;
  }
}

@media screen and (max-width: 992px) {
  .noSubscriptionBlock .explanation {
    padding: 1rem 2rem;
  }
  .subscriptionHours {
    margin-top: 2rem;
  }

  .availableSubscriptionsWrapper .subscriptionRow,
  .availableSubscriptionsWrapper .subscriptionRow .right {
    flex-direction: column;
  }
}

$border-color: rgb(209, 209, 209);

@media screen and (max-width: 767px) {
  table {
    border: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    caption {
      text-align: left;
      font-size: 1.3em;
      margin: 0.5em 0 0.75em;
    }

    thead {
      display: none;
    }

    tr {
      display: block;
      border: 2px solid $border-color;
      padding: 1em 1em 0.5em;

      & + tr {
        margin-top: 0.625em;
      }
    }

    td {
      display: flex;
      border-bottom: 1px solid $border-color;
      font-size: 0.8em;
      line-height: 1.35em;

      & + td {
        margin-top: 0.8em;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .amountHours {
    margin-left: 1rem;
  }

  .totalHoursSubscription {
    margin-top: 1rem;
  }
}
</style>
