<template>
  <div id="hosting">
    <hosting-skeleton
      v-if="!this.$store.state.hostingLoaded"
    ></hosting-skeleton>
    <template v-if="this.$store.state.hostingLoaded">
      <div
        id="alert"
        style="display: none"
        class="alert alert-success"
        role="alert"
      ></div>
      <div class="hostingHeader">
        <div class="container">
          <h1 class="hostingHeaderTitle">Hosting en onderhoud</h1>
          <span v-if="this.$store.state.hostingPartsCount > 0">
            Je hebt
            <span class="highlightBold"
              >{{ this.$store.state.hostingPartsCount }} hosting
              onderdelen</span
            >
          </span>
          <span v-else
            >Je hebt op dit moment geen hosting bij Miller Digital</span
          >
        </div>
      </div>

      <div
        class="wordpressMaintenance"
        v-for="onderhoud in this.$store.state.maintenace"
        v-bind:key="onderhoud"
      >
        <div class="container">
          <div class="wordpressMaintenanceWrapper">
            <div class="top">
              <div class="imageWrapper">
                <img src="../../assets/hosting.png" />
              </div>
              <div class="maintenanceText">
                <div class="maintenanceTextTop">
                  <div class="maintenanceName">
                    <h5>
                      {{
                        this.$store.state.maintenace.length > 1
                          ? onderhoud.name
                          : "Wordpress onderhoud"
                      }}
                    </h5>

                    <p class="highlightBlue">
                      {{
                        onderhoud["complications_onderhoud"]
                          ? "inclusief complicaties"
                          : "exclusief complicaties"
                      }}
                    </p>
                  </div>

                  <span class="maintenancePrice text-muted price"
                    >€{{ this.roundDecimal(onderhoud) }}</span
                  >
                </div>
                <div class="maintenanceTextBottom">
                  <div class="row">
                    <div class="col-lg-5">
                      <span class="inclusive">Inclusief</span>
                      <span>Wordpress updates</span>
                      <span>Thema en plugin updates</span>
                      <span v-if="onderhoud['complications_onderhoud']"
                        >Complicaties t.g.v. updates</span
                      >
                    </div>
                    <div
                      class="col-lg-5"
                      v-if="!onderhoud['complications_onderhoud']"
                    >
                      <span class="exclusive">Exclusief</span>
                      <span>Complicaties t.g.v. updates</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom">
              <button
                v-if="!onderhoud['complications_onderhoud']"
                @click="upgradeMaintenance(onderhoud.name)"
                class="btn btn-primary"
              >
                Upgraden
              </button>
              <button
                disabled
                v-if="onderhoud['complications_onderhoud']"
                class="btn btn-primary"
              >
                Upgraden niet mogelijk
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="hostingPackage" v-if="this.$store.state.hosting.length > 0">
        <div class="container">
          <h4>Hosting</h4>
          <div
            class="hostingWrapper"
            v-for="hosting in this.$store.state.hosting"
            v-bind:key="hosting.id"
          >
            <div class="top">
              <div style="width: 35%">
                <span class="hostingTitle">{{ hosting.name }}</span>
              </div>
              <div>
                <p class="hostingExpires fw-bold">
                  Verloopt op
                  <span class="fw-normal highlightBlue">{{
                    this.dateFormat(hosting.invoice_date)
                  }}</span>
                </p>
              </div>
              <div>
                <span class="maintenancePrice text-muted price"
                  >€{{ this.roundDecimal(hosting) }}</span
                >
              </div>
              <div>
                <button class="btn btn-outline-primary hostingExtend">
                  <span>Hosting opzeggen</span>
                </button>
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <span class="specificationsTitle">Specificaties</span>
                <div
                  v-for="specification in this.hostingSpecifications[
                    hosting['hosting_package']
                  ]"
                  v-bind:key="specification"
                  class="specificationHosting"
                >
                  <span class="specificationName">{{ specification }}</span>
                  <div
                    class="progress"
                    v-if="this.calculateUsage(specification)"
                  >
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="
                        'width: ' + this.calculateUsage(specification) + '%'
                      "
                    ></div>
                  </div>
                  <span
                    class="percentageUsage"
                    v-if="this.calculateUsage(specification)"
                    >{{
                      this.calculateUsage(specification) + "% in gebruik"
                    }}</span
                  >
                </div>
              </div>
              <div class="right">
                <button
                  class="btn btn-primary"
                  @click="
                    upgradeHosting(hosting.name, hosting['hosting_package'])
                  "
                  v-if="this.hostingPackage != 'Ultimate'"
                >
                  Upgraden
                </button>
                <button class="btn btn-primary" v-else disabled>
                  Upgraden niet mogelijk
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="domains" v-if="this.$store.state.domains.length > 0">
        <div class="container">
          <h4>Domeinen</h4>
          <div
            class="domainsWrapper"
            v-for="domein in this.$store.state.domains"
            v-bind:key="domein.id"
          >
            <div class="top">
              <div class="left">
                <span class="domain">{{ domein.name }}</span>
                <p class="domainExpires fw-bold" v-if="domein.invoice_date">
                  Verloopt op
                  <span class="fw-normal highlightBlue">{{
                    this.dateFormat(domein.invoice_date)
                  }}</span>
                </p>
              </div>
              <div class="right">
                <span class="maintenancePrice text-muted price"
                  >€{{ this.roundDecimal(domein) }}</span
                >
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <p v-if="domein.invoice_date">
                  Domein wordt automatisch verlengd op:
                  <span class="highlightBlue">
                    {{ this.dateFormat(domein.invoice_date) }}</span
                  >
                </p>
                <p v-else>Domein wordt niet automatisch verlengd</p>
              </div>
              <div class="ritght">
                <button
                  @click="stopDomain(domein.name)"
                  class="btn btn-outline-primary"
                >
                  Domein opzeggen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="domainAvailability">
          <h4>Registreer je domein</h4>
          <div class="content">
            <div class="top">
              <input
                v-model="domainNameToCheck"
                type="text"
                placeholder="Welke domeinnaam wil je? (Bijvoorbeeld: millerdigital.nl)"
              />
              <button
                @click="getDomainAvailability(this.domainNameToCheck)"
                class="btn btn-primary"
              >
                Bekijk beschikbaarheid
              </button>
            </div>
            <div class="domainError" v-if="this.domainNameAvailabilityError">
              <p>*Vul een correct domein in</p>
            </div>
            <div class="bottomLoading" v-if="this.domainAvailableLoading">
              <div class="spinner-wrapper">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </div>
            <div
              class="bottom"
              v-if="this.showInfo && !this.domainAvailableLoading"
            >
              <p>
                Het domein
                <span class="fw-bold">{{ this.domainNameChecked }}</span> is
                <span
                  :class="
                    this.domainIsAvailable ? 'available' : 'not-available'
                  "
                  >{{
                    this.domainIsAvailable ? "beschikbaar" : "niet beschikbaar"
                  }}</span
                >
              </p>
              <p v-if="!this.domainIsAvailable">
                Zoek een ander domein, dit domein is al in gebruik.
              </p>
              <div
                class="claimDomain"
                v-if="this.domainIsAvailable && !this.claimDomain"
              >
                <p>
                  Dit domein is nog niet in gebruik. Reserveer dit domeinnaam
                  door op de knop te klikken. Onze hosting afdeling zal dit zo
                  snel mogelijk regelen.
                </p>
                <p><span class="fw-bold">Prijs: {{this.domainPrice != null ? this.domainPrice : "onbekend" }}</span></p>
                <button
                  v-if="!this.claimDomainLoading"
                  @click="this.domainIsClaimed(this.domainNameChecked)"
                  class="btn btn-primary"
                >
                  Reserveer domeinnaam
                </button>
                <button
                  v-if="this.claimDomainLoading"
                  class="btn btn-primary"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only"> Reserveer domeinnaam...</span>
                </button>
              </div>
              <div class="domainIsClaimed" v-if="this.claimDomain">
                <p>
                  Je domeinnaam is succesvol doorgegeven aan onze hosting
                  afdeling en wordt zo spoeding mogelijk gereserveerd. We houden
                  je op de hoogte.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ssl" v-if="this.$store.state.ssl.length > 0">
        <div class="container">
          <h4>SSL</h4>
          <div
            class="otherWrapper"
            v-for="ssl in this.$store.state.ssl"
            v-bind:key="ssl.id"
          >
            <div class="top">
              <div class="left">
                <span class="title">{{ ssl.name }}</span>
                <p v-if="ssl.invoice_date" class="sslExpires fw-bold">
                  Verloopt op
                  <span class="highlightBlue fw-normal"
                    >{{ this.dateFormat(ssl.invoice_date) }}
                  </span>
                </p>
              </div>
              <div class="right">
                <span class="maintenancePrice text-muted price"
                  >€{{ this.roundDecimal(ssl) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="otherSubscription" v-if="this.$store.state.others.length > 0">
        <div class="container">
          <h4>Overige</h4>
          <div
            class="otherWrapper"
            v-for="overige in this.$store.state.others"
            v-bind:key="overige.id"
          >
            <div class="top">
              <div class="left">
                <span class="title">{{ overige.name }}</span>
                <p v-if="overige.invoice_date" class="otherExpires fw-bold">
                  Verloopt op
                  <span class="highlightBlue fw-normal"
                    >{{ this.dateFormat(overige.invoice_date) }}
                  </span>
                </p>
              </div>
              <div class="right">
                <span class="maintenancePrice text-muted price"
                  >€{{ this.roundDecimal(overige) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import hostingService from "../../service/HostingService";
import directAdminService from "../../service/DirectAdminService";
import jQuery from "jquery";
import HostingSkeleton from "../../components/skeletons/hosting/HostingSkeleton.vue";
const $ = jQuery;
window.$ = $;
export default {
  components: { HostingSkeleton },
  data() {
    return {
      hostingSpecifications: {
        Normaal: ["5gb opslag", "50gb bandbreedte", "1 database"],
        Pro: ["10gb opslag", "100gb bandbreedte", "5 database"],
        Shop: ["10gb opslag", "100gb bandbreedte", "5 database", "pagespeed"],
        Ultimate: [
          "onbeperkte opslag",
          "onbeperkte bandbreedte",
          "10 database",
          "pagespeed",
          "Caching",
        ],
      },
      domainNameToCheck: "",
      domainIsAvailable: false,
      showInfo: false,
      domainAvailableLoading: false,
      domainNameChecked: "",
      domainNameAvailabilityError: false,
      claimDomain: false,
      claimDomainLoading: false,
      domainPrice: null
    };
  },
  created() {
    this.getHostingServices();
    this.getDirectAdmin();
  },
  methods: {
    calculateUsage(specification) {
      if (!this.$store.state.usage) {
        return;
      }
      let limit = specification.split("gb")[0] * 1000;
      if (specification.includes("opslag")) {
        let percentage = (this.$store.state.usage.quota / limit) * 100;
        return Math.round(percentage);
      } else if (specification.includes("bandbreedte")) {
        let percentage = (this.$store.state.usage.bandwidth / limit) * 100;
        return Math.round(percentage);
      }
    },
    async upgradeHosting(name, hostingPackage) {
      await hostingService.upgradeHosting(name, hostingPackage);
      $("#alert")
        .fadeTo(4000, 500)
        .slideUp(500, function () {
          $("#alert").slideUp(500);
        });
      $("#alert").empty();
      $("#alert").append(
        "<span>Miller digital zal zo spoedig mogelijk je hosting pakket upgraden. <br>Hou je account in de gaten!</span>"
      );
    },
    async upgradeMaintenance(name) {
      await hostingService.upgradeMaintenance(name);
      $("#alert")
        .fadeTo(4000, 500)
        .slideUp(500, function () {
          $("#alert").slideUp(500);
        });
      $("#alert").empty();
      $("#alert").append(
        "<span>Miller digital zal zo spoedig mogelijk je Wordpress onderhoud upgraden. <br>Hou je account in de gaten!</span>"
      );
    },
    async stopDomain(domainName) {
      await hostingService.stopDomain(domainName);
      $("#alert")
        .fadeTo(4000, 500)
        .slideUp(500, function () {
          $("#alert").slideUp(500);
        });
      $("#alert").empty();
      $("#alert").append(
        "<span>Miller digital zal zo spoedig mogelijk je domein opzeggen. <br>Hou je account in de gaten!</span>"
      );
    },
    dateFormat(dateString) {
      let date = new Date(dateString);
      let day = ("0" + date.getDate()).slice(-2);

      let month = ("0" + (date.getMonth() + 1)).slice(-2);

      return day + "-" + month + "-" + date.getFullYear();
    },
    roundDecimal(object) {
      if (object.subscription_cycle == "Year") {
        return parseFloat(object.price).toFixed(2).replace(".", ",") + " p/j";
      } else {
        return parseFloat(object.price).toFixed(2).replace(".", ",") + " p/m";
      }
    },
    getHostingServices() {
      hostingService.getHosting();
    },
    getDirectAdmin() {
      directAdminService.getDirectAdminInfo();
    },
    getDomainAvailability(domainName) {
      this.domainNameAvailabilityError = false;
      this.showInfo = false;
      this.domainAvailableLoading = true;
      this.claimDomain = false;
      this.domainIsAvailable = false;

      let data = hostingService.getDomainAvailability(domainName);
      data
        .then((res) => {
          if (res["availability"]["status"] == "free") {
            this.domainIsAvailable = true;
          }

          if (res["price"] != null) {
            this.domainPrice = res["price"];
          }
          this.domainNameChecked = this.domainNameToCheck;
          this.showInfo = true;
          this.domainAvailableLoading = false;
        })
        .catch((err) => {
          this.domainAvailableLoading = false;
          this.domainNameAvailabilityError = true;
        });
    },
    domainIsClaimed(domainName) {
      this.claimDomain = false;
      this.claimDomainLoading = true;
      let data = hostingService.claimDomain(domainName);
      data.then((res) => {
        this.claimDomain = true;
        this.claimDomainLoading = false;
        this.domainNameToCheck = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#hosting {
  padding-bottom: 4rem;
}
.hostingHeader {
  margin-top: 3rem;
}
.wordpressMaintenance {
  margin-top: 3.5rem;
}
.wordpressMaintenanceWrapper {
  background-color: white;
  max-width: 850px;

  .top {
    display: flex;
    padding: 2.5rem 2rem 1.5rem 2rem;
    border-bottom: 1px solid rgb(240, 240, 240);
  }

  .bottom {
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: flex-end;
  }

  .maintenanceText {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    width: 100%;
  }
  .maintenanceTextTop {
    display: flex;
    justify-content: space-between;
  }
  .maintenancePrice {
    opacity: 0.7;
  }

  .maintenanceTextBottom {
    margin-top: 1rem;
    span {
      display: block;
    }
  }

  .inclusive,
  .exclusive {
    font-size: 14px;
    font-weight: bold;
  }
}

.price {
  opacity: 0.7;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .wordpressMaintenanceWrapper .top,
  .wordpressMaintenanceWrapper .bottom {
    padding: 1rem;
  }

  .wordpressMaintenanceWrapper .maintenanceText {
    margin-left: 0;
  }
}

.hostingWrapper {
  background-color: white;
  max-width: 850px;
  margin-top: 1rem;
  .percentageUsage {
    margin-left: 1.5rem;
  }
  .specificationHosting {
    display: flex;
    align-items: center;
    .progress {
      width: 150px !important;
      margin-left: 2rem;
      margin-bottom: 0 !important;
      height: 0.6rem;
      border-radius: 50px;
    }

    .progress .progress-bar {
      background-color: #ffc400;
      border-radius: 50px;
    }
  }

  .specificationName {
    width: 175px;
  }
  p {
    margin-bottom: 0;
  }
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding: 1.5rem 2rem;
    align-items: center;

    .hostingTitle {
      font-weight: bold;
      font-size: 18px;
      margin-right: 4rem;
      width: 100%;
      word-break: break-word;
    }

    .hostingExpires {
      font-size: 14px;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem;

    .left {
      display: flex;
      flex-direction: column;
      .specificationsTitle {
        font-weight: bold;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.domainsWrapper {
  background-color: white;
  max-width: 850px;
  margin-top: 1rem;
  p {
    margin-bottom: 0;
  }
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding: 1.5rem 2rem;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      .domain {
        font-weight: bold;
        margin-right: 4rem;
        font-size: 18px;
        width: 15rem;
        max-width: 15rem;
        display: block;
      }

      .domainExpires {
        font-size: 14px;
      }
    }
  }

  .bottom {
    display: flex;
    padding: 1.5rem 2rem;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
    }
  }
}

.domainAvailability {
  margin-top: 2rem;
  .content {
    background-color: white;
    max-width: 850px;
    margin-top: 1rem;
    p {
      margin-bottom: 0;
    }
    width: 100%;
    .top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgb(240, 240, 240);
      padding: 1.5rem 2rem;
      align-items: center;
      width: 100%;

      input {
        display: block;
        background-color: #fcfcfc;
        border: none;
        width: 60%;
        padding: 1rem;
      }
    }
    .bottom {
      padding: 1.5rem 2rem;
      border-bottom: 1px solid rgb(240, 240, 240);

      span.available {
        color: green;
        font-weight: bold;
      }
      span.not-available {
        color: red;
        font-weight: bold;
      }

      .domainIsClaimed {
        p {
          margin-top: 1rem;
        }
      }

      .claimDomain {
        p {
          margin-top: 0.5rem;
        }
        button {
          margin-top: 1rem;
        }
      }
    }
    .bottomLoading {
      .spinner-wrapper {
        display: flex;
        padding: 1rem 2rem;
        justify-content: flex-start;
      }
    }
    .domainError {
      padding: 1rem 2rem;
      p {
        color: red;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hostingWrapper .top,
  .hostingWrapper .bottom {
    padding: 1rem;
  }

  .hostingWrapper .top div,
  .hostingWrapper .bottom div {
    margin-bottom: 0.5rem;
  }

  .domainsWrapper .top,
  .domainsWrapper .bottom {
    padding: 1rem;
  }

  .domainsWrapper .top div,
  .domainsWrapper .bottom div {
    margin-bottom: 0.5rem;
  }
}

.otherWrapper {
  background-color: white;
  max-width: 850px;
  margin-top: 1rem;
  p {
    margin-bottom: 0;
  }

  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding: 1.5rem 2rem;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-weight: bold;
        margin-right: 4rem;
        font-size: 18px;
        width: 15rem;
        max-width: 15rem;
        display: block;
      }
      .sslExpires,
      .otherExpires {
        font-size: 14px;
      }
    }
  }
}

.hostingPackage,
.domains,
.ssl,
.otherSubscription {
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .wordpressMaintenanceWrapper .top,
  .maintenanceTextTop {
    flex-direction: column;
  }

  .wordpressMaintenanceWrapper .top .imageWrapper {
    margin-bottom: 1rem;
  }
  .wordpressMaintenanceWrapper .bottom {
    justify-content: flex-start;
  }

  .hostingWrapper .top,
  .hostingWrapper .top .left,
  .hostingWrapper .bottom {
    flex-direction: column;
    align-items: flex-start;
  }
  .hostingWrapper .top {
    div {
      width: 100% !important;
    }
  }

  .hostingWrapper .specificationHosting {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .hostingWrapper .specificationHosting .progress {
    margin-left: 0;
  }
  .progress-bar {
    margin-bottom: 0 !important;
  }
  .hostingWrapper .percentageUsage {
    margin-left: 0;
  }

  .hostingWrapper .bottom .right {
    align-items: flex-start;
  }

  .domainsWrapper .top,
  .domainsWrapper .top .left {
    flex-direction: column;
    align-items: flex-start;
  }

  .domainsWrapper .bottom {
    display: flex;
    flex-direction: column;
  }

  .otherWrapper .top,
  .otherWrapper .top .left {
    flex-direction: column;
    align-items: flex-start;
  }

  .otherWrapper .top {
    padding: 1rem;
  }

  .otherWrapper .top div {
    margin-bottom: 0.5rem;
  }

  .domainAvailability .content .top {
    flex-direction: column;
    align-items: flex-start;
    input {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

#alert {
  position: fixed;
  right: 1rem;
}
</style>
