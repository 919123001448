<template>
    <div id="sync">
      <div class="syncHeader">
        <div class="container">
          <h1 class="title">Sync management</h1>
          <p>{{ $t("sync.mapping.header", this) }}</p>
        </div>
      </div>
      <div class="syncData pb-5 mt-4" v-if="this.dataLoaded">
        <div class="container">
          <div class="syncDataRows">
            <div
              class="row"
              v-for="(mapping, key, index) in this.mappings"
              v-bind:key="mapping"
            >
              <div class="col key-label">{{ key }}</div>
              <div class="col">
                <Multiselect
                  class="groupSelect"
                  mode="single"
                  :id="key"
                  :disabled="this.loading"
                  v-model="this.mappings[key]['value']"
                  :searchable="true"
                  :options="this.values ?? mapping['options']"
                  :allow-empty="false"
                  @select="addMapping(key)"
                >
                </Multiselect>
              </div>
              <div class="col-2" v-if="mapping['products']">
                <div class="d-grid">
                  <button type="button" class="btn btn-primary collapsed"            
                        data-bs-toggle="collapse"
                        :data-bs-target="'#collapse' + index"
                        @click=showProducts(key)>
                      Producten
                  </button>
                </div>
              </div>
              <div
                :id="'collapse' + index"
                class="collapse col-12">
                  <div class="products">
                    <div class="row" v-if="this.mappings[key]['showProducts']">
                    <div v-for="product in mapping['products']"
                        class="col-2">
                      <img class="img-fluid" :src="product['image']"/>
                      <div class="text-center">{{ product['name'] }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="spinner-wrapper" v-if="!this.dataLoaded">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Multiselect from "@vueform/multiselect";
  import { useOrganisationComponent } from "@/service/organisation/organisation";

  export default {
    props: [
        'supplier',
        'type',
    ],
    components: {
      Multiselect,
    },
    data() {
      return {
        loading: false,
        dataLoaded: false,
        mappings: {}
      };
    },
    computed: {
      services () {
        const services = require.context('@/service/organisation', true, /SyncService.js/);
        return services.keys().map(x => services(x).default)
      }
    },
    async created() {
      this.dataLoaded = false;
      this.$store.state.promiseApp.then(() => {
        this.syncService = useOrganisationComponent(this.services, this.$store);
        this.syncService.supplier = this.supplier;
        this.syncService.type = this.type;
        this.getData();
      });
    },
    methods: {
      async getData() {
        await this.getValues();
        await this.getMappings();
        this.dataLoaded = true;
      },
      async getValues() {
        if (this.type == 'category') {
          await this.syncService.getCategories().then((res) => {
            this.values = res;
          });
        } else {
          await this.syncService.getValues(this.supplier, this.type).then((res) => {
            this.values = res;
          });
        }
      },
      async getMappings() {
        await this.syncService.getMappings(this.supplier, this.type).then((res) => {
          for (let key in res) {
            let mapping = res[key];
            this.mappings[key] = mapping !== null && typeof(mapping) === 'object' ? mapping : {'value': mapping};
          }
        });
      },
      async addMapping(index) {
        if (index == null) {
          return;
        }
        this.loading = true;
        let data = {
          payload: [{
            key: index,
            value: this.mappings[index]['value'],
          }],
        };
        await this.syncService.addMapping(this.supplier, this.type, data);
        this.loading = false;
      },
      showProducts(index) {
        this.mappings[index]['showProducts'] = true;
      }
    },
  };
  </script>
  
  <style src="@vueform/multiselect/themes/default.css"></style>
  
  <style lang="scss" scoped>
  .syncHeader {
    margin-top: 3rem;
  }
  
  .syncDataRows {
    .row {
      background-color: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 1rem;
  
      .key-label {
        display: flex;
        align-items: center;
        font-size: 18px;
      }
    }

    .products {
      min-height: 300px;
    }
  }
  
  .spinner-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: calc(50vh - 6rem);
  
    .spinner-border {
      height: 3rem;
      width: 3rem;
    }
  }
  </style>