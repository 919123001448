import axios from "axios"
import { store } from '../../store'
import router from '../../router'

export default {
    async createStandupUser(gitId, name, avatar) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'admin/standup/users', { gitId: gitId, name: name }).then((res) => {
            console.log('created')
        });
    },
    async getNewStandupUsers() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/standup/users/new').then((res) => {
            return res.data
        });
    },
    async getAddedStandupUsers() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/standup/users/added').then((res) => {
            return res.data
        });
    },
    async removeStandupUser(gitId) {
        return await axios.delete(process.env.VUE_APP_ROOT_API + 'admin/standup/users/' + gitId).then((res) => {
            return res.data
        });
    },
    async updateOrder(order) {
        return await axios.put(process.env.VUE_APP_ROOT_API + 'admin/standup/users', {order: order}).then((res) => {
            return res.data
        });
    },
    async uploadAvatar(formData, gitId) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'admin/standup/ ' + gitId + '/avatar', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            // res.data['data']['paths'].forEach((element) => {
            //     store.state.fileList.push(element);
            // });
            // return res.data['data']['paths'];
        }).catch((err) => {
            console.log(err);
        });
    }
}
