import axios from "axios"
import { store } from '../store'

export default {
    async getInvoices() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'invoice').then((res) => {
            store.state.invoices = res.data['data']['invoices'];
            store.state.invoicesLoaded = true;
        }).catch((err) => {
            store.state.invoicesLoaded = true;
            console.log(err);
        })
    },
    async getInvoiceById(id) {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'invoice/' + id).then((res) => {
            store.state.invoiceDetail = res.data['data']['invoice'];
        }).catch((err) => {
            console.log(err);
        })
    }
}