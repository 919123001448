<template>
  <div class="newOrganisations">
    <div class="container">
      <div
        id="alert"
        style="display: none"
        class="alert alert-success"
        role="alert"
      ></div>
      <div class="organisationHeader">
        <router-link to="/admin">Ga terug naar overzicht</router-link>

        <h1>Nieuwe organisaties</h1>
        <p>
          Er zijn
          <span class="fw-bold">{{
            this.$store.state.newOrganisations.length
          }}</span>
          organisaties bijgekomen.
        </p>
      </div>

      <h3>Organisatie toevoegen aan dashboard</h3>
      <div class="organisationCard">
        <div class="organisationName organisationRow">
          <div class="label" style="background-color: orange">
            <span>Nieuwe organisatie</span>
          </div>
          <Multiselect
            class="organisationSelect"
            mode="single"
            :searchable="true"
            filterResults="true"
            v-model="this.selectedOrganisation"
            :options="this.$store.state.newOrganisations"
            label="name"
            valueProp="id"
            :object="true"
            :allow-empty="false"
            @select="this.changeOrganisation()"
          >
          </Multiselect>
        </div>
        <div class="organisationOptions" v-if="this.selectedOrganisation">
          <div class="users organisationRow">
            <p class="title">Contactpersonen koppelen</p>
            <div
              class="form-check"
              v-for="user in this.selectedOrganisation[
                'linked_persons_contacts'
              ]"
              v-bind:key="user"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="user"
                :id="user.id"
                v-model="this.users"
              />
              <label class="form-check-label" :for="user.id">
                {{ user["first_name"] }} {{ user["family_name"] }} -
                {{ user["work_email"] }}
              </label>
            </div>
            <span class="text-danger fw-bold" v-if="this.usersError"
              >*Let op er zijn geen users geselecteerd, dit is niet verplicht!</span
            >
            <span class="text-danger fw-bold" v-if="this.sameEmailAddressError"
              >*Er zijn users geselecteerd met hetzelfde email adres</span
            >
          </div>

          <div class="gitGroupName organisationRow">
            <p class="title">Git groepnaam instellen</p>
            <input
              type="text"
              class="form-control select"
              placeholder="Git groep naam"
              v-model="this.gitGroupName"
            />
            <span class="text-danger fw-bold" v-if="this.gitGroupNameError"
              >*Een groep naam kan geen speciale tekens bevatten, leeg zijn of
              meer dan 256 karakters bevatten</span
            >
          </div>

          <div class="gitRepoSettings organisationRow">
            <p class="title">Git repo instellingen</p>
            <div class="row">
              <div class="col-6">
                <p>Wil je een nieuwe repo aanmaken voor het thema?</p>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    v-model="this.createDefaultRepo"
                    :value="true"
                    @click="this.resetRepoValue()"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    Ja
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    v-model="this.createDefaultRepo"
                    :value="false"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    Nee, gebruik de repo:
                  </label>
                </div>
              </div>
              <div class="col-6 repoOptions">
                <div class="gitRepoName" v-if="this.createDefaultRepo">
                  <input
                    type="text"
                    class="form-control select"
                    placeholder="Git repo naam"
                    v-model="this.gitRepoName"
                    v-if="this.createDefaultRepo"
                  />
                  <span
                    v-if="this.gitRepoNameError && this.createDefaultRepo"
                    class="text-danger fw-bold"
                    >*Git repo naam kan niet leeg zijn of speciale tekens
                    bevatten</span
                  >
                </div>
                <div class="selectRepo" v-if="!this.createDefaultRepo">
                  <Multiselect
                    class="groupSelect"
                    mode="single"
                    :searchable="true"
                    v-model="this.selectedGroup"
                    :options="this.$store.state.allGitGroups"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    :allow-empty="false"
                    @select="this.getProjectsForGroup()"
                  >
                  </Multiselect>
                  <Multiselect
                    class="groupSelect"
                    mode="single"
                    :searchable="true"
                    filterResults="true"
                    v-model="this.selectedProject"
                    :options="this.$store.state.reposFromGroup"
                    label="name"
                    valueProp="id"
                    :object="true"
                    :allow-empty="false"
                    v-if="this.selectedGroup"
                  >
                  </Multiselect>
                
                  <span
                    v-if="this.existingRepoError && !this.createDefaultRepo"
                    class="text-danger fw-bold"
                    >*Git repo moet geselecteerd zijn</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="developer organisationRow">
            <p class="title">Developer instellingen</p>
            <div class="row">
              <div class="col-6">
                <p>Wie is de aangewezen developer?</p>
              </div>
              <div class="col-6">
                <Multiselect
                  class="organisationSelect"
                  mode="single"
                  :searchable="true"
                  filterResults="true"
                  v-model="this.selectedDeveloper"
                  :options="this.$store.state.gitUsers"
                  label="name"
                  valueProp="id"
                  :allow-empty="false"
                >
                </Multiselect>
                <span v-if="this.devError" class="text-danger fw-bold"
                  >*Git developer moet geselecteerd zijn</span
                >
              </div>
            </div>
          </div>

          <div class="submit organisationRow">
            <span class="text-danger fw-bold" v-if="this.duplicateError"
              >*{{ this.errorMsg }}</span
            >
            <button
              v-if="!this.sendRequest"
              @click="this.submit()"
              class="btn btn-primary"
            >
              Organisatie toevoegen en gebruikers uitnodigen
            </button>
            <button
              v-if="this.sendRequest"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Organisatie toevoegen en gebruikers uitnodigen...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
import adminOrganisationService from "../../service/admin/OrganisationService";
import gitService from "../../service/admin/GitService";
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      value: null,
      options: ["Batman", "Robin", "Joker"],
      selectedOrganisation: "",
      users: [],
      gitGroupName: "",
      gitRepoName: "",
      selectedGroup: "",
      selectedProject: "",
      createDefaultRepo: true,
      selectedDeveloper: "",
      sendRequest: false,

      // errors
      gitGroupNameError: false,
      usersError: false,
      noUsersOk: false,
      gitRepoNameError: false,
      existingRepoError: false,
      devError: false,
      sameEmailAddressError: false,
      duplicateError: false,
      errorMsg: "",
    };
  },
  async created() {
    await this.getOrganisations();
    await this.getGitUsers();
    await this.getGitGroups();
    await this.setFirstOrganisation();
    await this.setGroupNameSuggestion();
  },
  methods: {
    async getOrganisations() {
      await adminOrganisationService.getNewOrganisations();
    },
    async getGitUsers() {
      await gitService.getGitUsers();
    },
    async getGitGroups() {
      await gitService.getGitGroups();
    },
    getProjectsForGroup() {
      this.selectedProject = "";
      this.$store.state.reposFromGroup = [];
      this.createDefaultRepo = false;
      gitService.getReposForGroup(this.selectedGroup);
    },
    setFirstOrganisation() {
      this.selectedOrganisation = this.$store.state.newOrganisations[0];
    },
    setGroupNameSuggestion() {
      var format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/g;
      let nameWithoutBV = this.selectedOrganisation["name"].replaceAll(
        "B.V.",
        ""
      );
      this.gitGroupName = nameWithoutBV
        .replaceAll(format, "")
        .replace(/ +/g, " ")
        .trim();
      this.gitRepoName = this.gitGroupName + " " + "theme";
    },
    resetRepoValue() {
      this.selectedGroup = "";
      this.selectedProject = "";
    },
    resetValues() {
      this.users = [];
      this.gitGroupName = "";
      this.createDefaultRepo = true;
      this.gitRepoName = "";
      this.selectedProject = "";
      this.selectedGroup = "";
      this.selectedDeveloper = "";
    },
    changeOrganisation() {
      this.resetValues();
      this.setGroupNameSuggestion();
    },
    async submit() {
      this.gitGroupNameError = false;
      this.usersError = false;
      this.gitRepoNameError = false;
      this.existingRepoError = false;
      this.devError = false;
      this.sameEmailAddressError = false;
      this.duplicateError = false;

      let error = false;
      var format = /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

      if (
        this.gitGroupName == "" ||
        format.test(this.gitGroupName.replaceAll(" ", "")) ||
        this.gitGroupName.length >= 256
      ) {
        this.gitGroupNameError = true;
        error = true;
      }

      if (this.users.length == 0 && !this.noUsersOk) {
        this.usersError = true;
        this.noUsersOk = true;
        error = true;
      }

      if (
        this.createDefaultRepo &&
        (this.gitRepoName == "" ||
          format.test(this.gitRepoName.replaceAll(" ", "")))
      ) {
        this.gitRepoNameError = true;
        error = true;
      }

      if (
        !this.createDefaultRepo &&
        (this.selectedGroup == "" || this.selectedProject == "")
      ) {
        this.existingRepoError = true;
        error = true;
      }

      if (this.selectedDeveloper == "") {
        this.devError = true;
        error = true;
      }

      const uniqueValues = new Set(this.users.map((v) => v["work_email"]));
      if (uniqueValues.size < this.users.length) {
        this.sameEmailAddressError = true;
        error = true;
      }

      if (error) {
        return;
      }

      let data = {
        organisation_id: this.selectedOrganisation["id"],
        organisation_name: this.selectedOrganisation["name"],
        git_group_name: this.gitGroupName.trim(),
        git_repo_name: this.gitRepoName.trim(),
        users: this.users,
        create_default_repo: this.createDefaultRepo,
        developer: this.selectedDeveloper,
        existing_project_id: !this.createDefaultRepo
          ? this.selectedProject["id"]
          : "",
        existing_project_name: !this.createDefaultRepo
          ? this.selectedProject["name"]
          : "",
        existing_project_namespace: !this.createDefaultRepo
          ? this.selectedProject["namespace"]["full_path"]
          : "",
      };
      console.log(data);

      try {
        this.sendRequest = true;
        await adminOrganisationService.createOrganisation(data);

        let currentIndex = this.$store.state.newOrganisations.indexOf(
          this.selectedOrganisation
        );
        this.$store.state.newOrganisations.splice(currentIndex, 1);

        this.resetValues();
        this.setFirstOrganisation();
        this.setGroupNameSuggestion();
        this.sendRequest = false;

        $("#alert")
          .fadeTo(4000, 500)
          .slideUp(500, function () {
            $("#alert").slideUp(500);
          });
        $("#alert").empty();
        $("#alert").append("<span>Organisatie aangemaakt!</span>");
      } catch (err) {
        this.duplicateError = true;
        this.errorMsg = err.response.data.msg;
        this.sendRequest = false;
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss" scoped>
#alert {
  position: fixed;
  right: 1rem;
}
.newOrganisations {
  padding-bottom: 2rem;
}
.organisationHeader {
  margin: 50px 0;
}

.organisationCard {
  background-color: white;

  p {
    margin-bottom: 0;
  }

  .organisationRow {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 3rem;
    width: 100%;

    .row {
      width: 100%;
    }

    .select {
      max-width: 500px;
      background-color: rgb(249, 247, 247);
      border: none;
      padding: 15px 20px;
      border-radius: 0;
      font-weight: 400;
    }
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 0.3rem;
  }

  .organisationName {
    display: flex;
    align-items: center;

    .organisationSelect {
      margin: 0;
      max-width: 400px;
    }

    .label {
      display: flex;
      align-items: center;
      height: 1.7rem;
      border-radius: 2rem;
      width: fit-content;
      padding: 0.1rem 0.7rem;
      color: white;
      font-size: 14px;
      font-weight: bold;
      margin-right: 1rem;
    }
  }
  .groupSelect {
    width: 200px;
  }
  .repoOptions {
    display: flex;
  }
  .selectRepo {
    display: flex;
    align-items: flex-end;
  }
  .gitRepoName {
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .submit {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>