import axios from "axios";
import { store } from '../store'

export default {
    async request(request, process) {
        return new Promise((resolve, reject) => {
            getShopwareToken().then((token) => {
                tryRequest(token, request, process, resolve);
            });
        });
    }
}

function tryRequest(token, request, process, resolve) {
    const client = axios.create({
        headers: { Authorization: 'Bearer ' + token }
    });
    request(client).then((res) => {
        let data = res.data;
        if (process) {
            data = process(data);
        }
        resolve(data);
    }).catch((err) => {
        if (err.response.status == 401) {
            authenticate().then((token) => tryRequest(token, request, process, resolve));
        }
    });
}

async function getShopwareToken() {
    let token = store.state.shopwareToken;
    if (!token) {
        token = localStorage.getItem('shopwareToken');
    } 
    if (token) {
        return new Promise((resolve) => resolve(token));
    }

    return authenticate();
}

async function authenticate() {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_ROOT_API + 'shopware/auth')
        .then((res) => {
            const token = res.data["access_token"];
            store.state.shopwareToken = token;
            localStorage.setItem("shopwareToken", token);
            resolve(token);
        }).catch((err) => {
            reject(err);
        });
    })
}