<template>
  <div id="standup">
    <div class="sidebar">
      <div class="sidebarLogo">
        <img
          src="..\..\assets\md_hres.png"
          @click="this.resetFilter()"
          alt=""
        />
      </div>
      <div class="avatarWrapper">
        <div v-dragscroll class="userAvatars">
          <div
            v-for="user in standupUsers"
            :class="this.activeUser == user.gitId ? 'active' : ''"
            v-bind:key="user.gitId"
            @click="getIssuesForUser(user.gitId, user.name)"
            class="avatar"
          >
            <img
              :src="
                'https://mijn.millerdigital.nl/api/avatar/' +
                this.getFileName(user['avatar'] ?? '')
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="standupWrapper">
      <div class="standupWrapperTop">
        <h1>
          <span class="highlightUnderline">Issues</span>
        </h1>
        <p class="amountIssues">
          {{ this.issues.length }} issues
          <span v-if="this.activeDeveloperIssues != ''">van </span>
          <span class="highlightUnderline">{{
            this.activeDeveloperIssues != "" ? this.activeDeveloperIssues : ""
          }}</span>
          getoond
        </p>
        <div class="filterRow">
          <div class="searchInputWrapper">
            <input
              @input="this.resultQuery()"
              @focus="this.showKeyboard()"
              v-model="this.searchQuery"
              type="search"
              class="form-control search"
              placeholder="Zoeken in issues"
            />
            <div id="keyboard" :class="keyboardClass"></div>
          </div>

          <div class="checkboxRow">
            <p>Filter op:</p>
            <div class="checkboxWrapper">
              <input
                v-model="this.filterScheduledActive"
                @change="filterScheduled($event.target.checked)"
                type="checkbox"
                id="scheduled"
                name="scheduled"
              />
              <label class="scheduledLabel" for="scheduled">Ingepland</label>
            </div>
            <div class="checkboxWrapper">
              <p
                @click="resetFilter()"
                v-if="this.issuesShowing != this.allIssues"
                class="resetFilter"
              >
                Reset filters
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-dragscroll class="issueRows">
        <div
          v-for="issue in issues"
          v-bind:key="issue"
          class="issueRow"
          :class="compareDate(issue.due_date) ? 'overdue' : 'on-time'"
        >
          <div class="issueTheme">
            <p>{{ this.getThemeName(issue["references"]["full"]) }}</p>
            <span>{{ this.localizeDate(issue["created_at"]) }}</span>
            <span v-if="issue['confidential'] == true"> / confidential</span>
          </div>
          <div class="issueTitle">
            <p>{{ issue.title }}</p>
          </div>
          <div class="issueLabels">
            <div
              v-for="label in issue.labels"
              v-bind:key="label"
              class="label"
              :style="'background-color:' + this.setLabelColor(label)"
            >
              <p>{{ label }}</p>
            </div>
          </div>
          <div class="issueDate">
            <img
              :class="!issue.due_date ? 'muted calenderIcon' : 'calenderIcon'"
              src="../../assets/calender_icon.png"
            />
            <span class="text-muted" v-if="!issue.due_date"
              >Niet ingepland
            </span>

            <span v-if="issue.due_date">Ingepland op <br /> </span>
            <span v-if="issue.due_date" class="highlightBlue scheduledDate">{{
              this.localizeDate(issue.due_date)
            }}</span>
          </div>
          <div class="issueEstimatedTime">
            <p v-if="issue.time_stats.human_time_estimate">
              {{
                this.timeEstimateFormat(
                  issue["time_stats"]["human_time_estimate"]
                )
              }}
            </p>
            <p class="text-muted" v-else>Geen inschatting</p>
          </div>
        </div>
      </div>
      <div class="spinner-wrapper">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jQuery from "jquery";
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
const $ = jQuery;
window.$ = $;
import standupService from "../../service/admin/StandupService";
import issueService from "../../service/admin/IssueService";
import { store } from "@/store";

export default {
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    input: {
      type: String,
    },
  },
  data() {
    return {
      issues: [],
      issuesShowing: [],
      issuesShowingCopy: [],
      allIssues: [],
      standupUsers: [],
      loaded: false,
      issuesDevelopers: [],
      filtersEnabled: false,
      activeDeveloperIssues: "",
      sortDate: "asc",
      sortTitle: "a-z",
      sortDateActive: false,
      sortTitleActive: false,
      searchQuery: "",
      keyboard: null,
      filterScheduledActive: false,
      activeUser: false,
    };
  },
  methods: {
    getFileName(file) {
      if (file == '') {
        return '';
      }

      return file.split("avatar/")[1];
    },
    hideKeyBoard(event) {
      console.log(event);
      $("#keyboard").css("visibility", "hidden");
    },
    showKeyboard() {
      $("#keyboard").css("visibility", "visible");
    },
    onChange(input) {
      console.log(input);
      this.searchQuery = input;
      this.resultQuery();
    },
    onKeyPress(button) {
      $("#keyboard").css("visibility", "visible");

      if (button === "{enter}") {
        this.hideKeyBoard();
      }
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    resultQuery() {
      console.log(this.searchQuery);
      if (this.searchQuery) {
        let copy = JSON.parse(JSON.stringify(this.$store.state.allIssues));
        let results = copy.filter((item) => {
          var columns =
            item.title + this.getThemeName(item["references"]["full"]);
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => columns.toLowerCase().includes(v));
        });

        this.$store.state.allIssues = results;
      } else {
        this.issuesShowing = this.issuesShowingCopy;
      }
    },
    compareDate(date) {
      if (date == null) {
        return false;
      }
      let parsedDate = new Date(date);
      if (parsedDate < new Date()) {
        return true;
      }
      return false;
    },
    localizeDate(date) {
      let splitOnT = date.split("T")[0];
      const splittedDate = splitOnT.split("-");
      return splittedDate[2] + "-" + splittedDate[1] + "-" + splittedDate[0];
    },
    timeEstimateFormat(timeEstimate) {
      let format = timeEstimate;
      let times = format.split(" ");

      times.forEach((element, key) => {
        let amount = parseInt(element.substring(0, element.length - 1));
        times[key] =
          (key == times.length - 1 && times.length != 1 ? " en " : "") +
          element
            .replace("d", amount == 1 ? " dag" : " dagen")
            .replace("w", amount == 1 ? " week" : " weken")
            .replace("h", amount == 1 ? " uur" : " uur")
            .replace("m", amount == 1 ? " minuut" : " minuten")
            .replace("s", amount == 1 ? " Seconde" : " seconden");
      });

      return times.join(" ");
    },
    getThemeName(name) {
      let wihoutHash = name.split("#")[0];
      return wihoutHash.substring(
        wihoutHash.lastIndexOf("/") + 1,
        wihoutHash.length
      );
    },
    resetFilter() {
      this.activeUser = "";
      this.activeDeveloperIssues = "";
      this.searchQuery = "";
      this.filtersEnabled = false;

      this.loadData();
    },
    loadData() {
      issueService.getAllIssues().then((data) => {
        this.issues = data;
      });
    },
    loadAddedStandupUsers() {
      standupService.getAddedStandupUsers().then((data) => {
        this.standupUsers = data;
      });
    },
    getIssuesForUser(userId, name) {
      this.activeUser = userId;
      issueService.getIssuesForDev(userId).then((data) => {
        this.issues = data;
      });

      this.activeDeveloperIssues = name;
    },
    setLabelColor(label) {
      switch (label) {
        case "Afwachting klant":
          return "#7F8C8D";
        case "Akkoord klant?":
          return "#ed9121";
        case "Bij livegang":
          return "#9400d3";
        case "Doorvoeren":
          return "#69D100";
        case "Geen oplossing beschikbaar":
          return "#FF0000";
        case "Inschatten":
          return "#0033CC";
        case "Klokken op strippenkaart":
          return "#FFECDB";
        case "Meer info nodig / overleggen":
          return "#F0AD4E";
        case undefined:
          return "#a69392";
      }
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
  created() {
    this.loadData();
    this.loadAddedStandupUsers();
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
    });

    this.keyboard.setInput(this.input);

    console.log("input", this.input);
  },
};
</script>

<style lang="scss" scoped>
#standup {
  display: flex;
  width: 100%;
  height: 100vh;
  touch-action: manipulation;
}
.sidebar {
  background-color: white;
  width: 125px;
  height: 100%;
}
.sidebarLogo {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  height: 100px;
}
.sidebarLogo img {
  width: 60%;
  object-fit: contain;
  cursor: pointer;
}

.avatarWrapper {
  overflow: hidden;
  height: calc(100vh - 100px);
  z-index: 1;
}
.userAvatars {
  overflow-y: auto;
  height: 100%;
  scrollbar-width: 50px;
}
// ::-webkit-scrollbar {
//   width: 1.5em;
// }

// ::-webkit-scrollbar-track {
//   background: lightgray;
//   border-radius: 50vw;
//   margin-block: .5em;
// }

// ::-webkit-scrollbar-thumb {
//     background-color: #e9b22c;
//     border-radius: 50vw;
//     height: 250px;
// }

// ::-webkit-scrollbar-corner {
//     background: #000;
// }
.avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 0.1rem;
  position: relative;
  margin-bottom: 1rem;
}

.avatar:before {
  content: "";
  background: #f0f0f0;
  position: absolute;
  top: 0;
  height: 90px;
  width: 90px;
  transform: translateY(10px);
  border-radius: 50%;
}

.avatar.active:before {
  background: #f4b824;
}

.avatar img {
  position: relative;
  width: 93px;
  height: 93px;
  cursor: pointer;
  object-fit: contain;
  z-index: 1;
}

.standupWrapper {
  padding-left: 3rem;
  padding-right: 3rem;
  width: calc(100vw - 125px);
  overflow: hidden;
}

.standupWrapperTop {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.filterRow {
  height: 55px;
  display: flex;
  margin-top: 2.5rem;
}
.filterRow .search {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.filterRow .checkboxRow {
  width: 75%;
  height: 100%;
  background-color: white;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}
.searchInputWrapper {
  position: relative;
  width: 25%;

  #keyboard {
    position: absolute;
    width: 750px;
    visibility: hidden;
    z-index: 999;
  }
}
.titelLabel {
  display: flex;
}

.checkboxWrapper {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;

  label {
    margin-left: 0.5rem;
  }
  .resetFilter {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .arrow-down {
    width: 12px;
    margin-left: 0.3rem;
    cursor: pointer;
  }
}
.issueRows {
  height: calc(100vh - 175px);
  overflow-y: auto;
  padding-bottom: 4rem;
}
@keyframes blinking {
  50% {
    border-color: red;
  }
}

.issueRow.overdue {
  border: 2px solid orange;
  -webkit-animation: blinking 3s infinite;
  -moz-animation: blinking 3s infinite;
  -o-animation: blinking 3s infinite;
  animation: blinking 3s infinite;
}

.issueRow {
  background-color: white;
  width: 100%;
  min-height: 4rem;
  height: auto;
  margin-bottom: 0.5rem;
  display: flex;
  padding-left: 1.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 0.2rem;
  align-items: center;

  p {
    margin-bottom: 0;
  }
  .issueTheme {
    width: 20%;
    p {
      font-weight: bold;
    }

    span {
      font-weight: normal;
      font-size: 13px;
    }
  }

  .issueTitle {
    width: 35%;
    padding-right: 1rem;
  }

  .issueLabels {
    display: flex;
    flex-direction: column;
    width: 15%;
  }
  .label {
    background-color: #69d100;
    border-radius: 2rem;
    max-width: 9rem;
    padding: 0.1rem 0.7rem;
    color: white;
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;
    display: table-cell;
    line-height: 1.25rem;
    margin-bottom: 0.4rem;
    text-align: center;
  }

  .issueDate {
    width: 15%;
    .scheduledDate {
      font-size: 14px;
    }
    .muted {
      opacity: 0.2;
    }
  }

  .issueDeveloper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .issueEstimatedTime {
    width: 15%;
  }
  .issueDeveloper img {
    height: 100%;
    max-height: 3.5rem;
    object-fit: contain;
  }
}
.spinner-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 10rem;

  .spinner-border {
    height: 3rem;
    width: 3rem;
  }
}
.calenderIcon {
  width: 22px;
  margin-right: 0.5rem;
}
@media screen and (min-width: 3000px) {
  p,
  span,
  h1 {
    font-size: 225%;
  }
  .sidebar {
    width: 18rem;
  }

  .sidebarLogo {
    height: 15rem;
  }

  .filterRow {
    height: 6rem;
    .search {
      font-size: 30px;
    }

    label {
      font-size: 30px;
    }
  }

  .amountIssues span {
    font-size: 36px;
  }

  .checkboxWrapper input[type="checkbox"] {
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 5px;
    border: 2px solid #555;
  }
  .issueRows {
    height: calc(100vh - 362px);
  }
  .issueRow {
    min-height: 6rem;
  }
  .issueRow .issueLabels {
    padding-left: 2.5rem;
  }

  .issueRow .label {
    padding: 0.7rem 1.4rem;
    max-width: 17rem;
    line-height: 1;
  }
  .issueRow .issueDeveloper img {
    max-height: 7rem;
  }

  .issueRow .issueTitle {
    padding-right: 3rem;
  }

  .calenderIcon {
    width: 2.5rem;
  }
  .issueRow .issueTheme span {
    font-size: 20px;
  }

  .avatarWrapper {
    height: calc(100vh - 250px);
  }

  .issueRow .issueDate .scheduledDate {
    font-size: 32px;
  }

  // .searchInputWrapper #keyboard {
  //   width: 1800px;
  //   height: 1000px;

  //   .hg-row {
  //     height: 100%;
  //   }

  //   .hg-button {
  //     height: 100%;
  //   }

  //   .hg-rows {
  //     height: 200px;
  //   }
  // }
}
</style>