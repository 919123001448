import axios from "axios"
import { store } from '../../store'
import router from '../../router'

export default {
    async getUsersCount() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/users/count').then((res) => {
            store.state.allUsersCount = res.data['data']['users'];
        });
    },
    async getUsers() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/users').then((res) => {
            store.state.allUsers = res.data['data']['users'];
        });
    },
    async addUser(data) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'admin/user', data).then((res) => {
        });
    },
    async removeUser(email) {
        return await axios.delete(process.env.VUE_APP_ROOT_API + 'admin/user', { data: { email: email } }).then((res) => {
        });
    },
    async connectUserToOrganisation(userId, organisationId) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'admin/user/' + userId + "/organisation/" + organisationId + "/connect").then((res) => {
        });
    }

}