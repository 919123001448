import axios from "axios"
import { store } from '../../store'
import router from '../../router'

export default {
    async getAllSubscriptions() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/subscriptions').then((res) => {
            console.log(res.data);
            store.state.allSubscriptionProjects = res.data['data']['subscriptions']
        });
    },
}