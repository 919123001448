import axios from "axios"
import { store } from '../store'
import router from '../router'

export default {
    async login(data, nextUrl) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'auth/login', data)
            .then((res) => {
                localStorage.setItem("token", res.data["data"]["token"]);
                store.state.user = res.data["data"]["user"];
                store.state.isAuthenticated = true;
                if (res.status === 206) {
                    store.state.selectOrganisation = true;
                    localStorage.setItem('selectOrganisation', true);
                } else {
                    router.push(nextUrl || "/dashboard");
                }
            }).catch((err) => {
                console.log(err);
                throw err;
            })
    },
    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        store.state.isAuthenticated = false;
        router.push({ path: "/login" }).then(() => window.location.reload());       
    },
    async resetPassword(email) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'password-reset/token', { email: email }).then((res) => {
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        })
    },
    async checkPasswordToken(token) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'password-reset/check', { token: token }).then((res) => {
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        });
    },
    async changePassword(password, token) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'password-reset', { token: token, password: password }).then((res) => {
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }
}