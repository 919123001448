<template>
    <div class="col-md-4 sync-card">
        <div class="content">
            <p class="title">{{ title }}</p>
            <p class="sync-card-description">{{ description }}</p>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'title', 'description', 'route', 'button'
        ]
    }
</script>

<style lang="scss" scoped>
    .sync-card {
        margin-top: 1rem;
        text-decoration: none;
        cursor: pointer;

        .content {
            background-color: white;
            padding: 2rem;
            transition: 0.1s ease-in all;

            .title {
                color: black;
                font-weight: 600;
                font-size: 20px;
            }

            p {
                color: black;
            }

            .sync-card-description {
                min-height: 50px;
            }
        }
    }

    .sync-card .content:hover {
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        transform: scale(1.02);
    }
</style>
