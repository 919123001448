<template>
  <div id="stripTickets">
    <div class="stripTicketsHeader">
      <div class="container">
        <h1 class="stripTicketsHeaderTitle">Hosting en onderhoud</h1>
        <p class="stripTicketCount"></p>
      </div>
    </div>

    <div class="stripTicketRows">
      <div class="container">
        <div class="stripTicketRow"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.stripTicketsHeaderTitle {
  margin-top: 3.5rem;
}

.stripTicketCount {
  background-color: #ddd;
  animation: pulse-bg 2s infinite;
  width: 400px;
  content: "";
  height: 20px;
}

.stripTicketRows {
  margin-top: 3rem;
}
.stripTicketRow {
  animation: pulse-bg 2s infinite;
  background-color: #ddd;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  max-width: 850px;
  height: 10rem;
}
</style>
