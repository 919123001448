<template>
  <div class="container mt-4">
    <h1>Strippenkaart tarieven</h1>
    <router-link to="/admin/punch-card-tariff/add" class="btn btn-primary"
      >Tarief toevoegen</router-link
    >

    <table class="table">
      <thead>
        <tr>
          <th scope="col">Klant</th>
          <th scope="col">Project id</th>
          <th scope="col">Tarief</th>
          <th scope="col">Budget</th>
          <th scope="col">Prijs</th>
          <th scope="col">Verwijderen</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="rate in this.$store.state.punchCardRates"
          v-bind:key="rate.id"
        >
          <td>{{ rate.name }}</td>
          <td>{{ rate.projectId }}</td>
          <td>€ {{ rate.tarif }}</td>
          <td>€ {{ rate.budget }}</td>
          <td>€ {{ rate.price }}</td>
          <td>
            <button class="btn btn-primary" @click="remove(rate.id)">
              Verwijderen
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <p v-if="this.$store.state.punchCardRates.length == 0">Geen tarieven ingesteld</p>
  </div>
</template>


<script>
import PunchCardService from "../../service/admin/PunchCardService";
export default {
  methods: {
    getAllRates() {
      PunchCardService.getAllRates();
    },
    async remove(id) {
      let data = {
        id: id,
      };

      console.log(data);
      await PunchCardService.remove(data);
      await this.getAllRates();
    },
  },
  created() {
    this.getAllRates();
  },
};
</script>