<template>
  <div id="issueBlock">
    <div class="top">
      <div
        :class="'label ' + this.issueLabel"
        :style="'background-color:' + this.setLabelColor(this.issueLabel)"
      >
        <span v-if="this.issueObject['labels'].length > 0">{{
          this.issueObject["labels"][0]
        }}</span>
        <span v-else>Geen label aanwezig</span>
      </div>

      <p class="issueTitle">{{ this.truncate(this.issueObject["title"]) }}</p>
    </div>
    <div class="bottom">
      <span v-if="isScheduledIssue == true" class="scheduledDate"
        ><img class="calenderIcon" src="../../assets/calender_icon.png" />Ingepland
        op
        <span class="highlightBlue">{{
          this.localizeDate(this.issueObject["due_date"])
        }}</span></span
      >
      <span v-if="isScheduledIssue == false" class="scheduledDate"
        >Nog niet gepland</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["scheduledIssue", "labelBgColor", "issue"],
  name: "Issue",
  data: function () {
    return {
      isScheduledIssue: this.scheduledIssue,
      labelBgColorVar: this.labelBgColor,
      issueObject: this.issue,
      issueLabel: this.issue["labels"][0],
    };
  },
  mounted() {},
  methods: {
    truncate(input) {
      if (input.length > 120) {
        return input.substring(0, 120) + "...";
      }
      return input;
    },
    localizeDate(date) {
      var days = [
        "Zondag",
        "Maandag",
        "Dinsdag",
        "Woensdag",
        "Donderdag",
        "Vrijdag",
        "Zaterdag",
      ];

      var month = [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
      ];
      var d = new Date(date);
      var dayName = days[d.getDay()];
      var monthName = month[d.getMonth()];

      const splittedDate = date.split("-");
      return dayName + " " + splittedDate[2] + " " + monthName;
    },
    setLabelColor(label) {
      switch (label) {
        case "Afwachting klant":
          return "#7F8C8D";
        case "Akkoord klant?":
          return "#ed9121";
        case "Bij livegang":
          return "#9400d3";
        case "Doorvoeren":
          return "#69D100";
        case "Geen oplossing beschikbaar":
          return "#FF0000";
        case "Inschatten":
          return "#0033CC";
        case "Klokken op strippenkaart":
          return "#FFECDB";
        case "Meer info nodig / overleggen":
          return "#F0AD4E";
        case undefined:
          return "#a69392";
        default:
          return "#a69392";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$afwachting_klant: "#7F8C8D";
$akkoord_klant: "#ed9121";
$bij_livegang: "#9400d3";
$doorvoeren: "#69D100";
$geen_oplossing: "#FF0000";
$inschatten: "#0033CC";
$klokken_strippenkaart: "#FFECDB";
$meer_info_nodig: "#F0AD4E";

#issueBlock {
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.top {
  padding: 2.5rem 2rem 0 2rem;
  border-bottom: 1px solid #efefef;
}

.bottom {
  margin-bottom: 1rem;
  padding: 1rem 2rem;

  .calenderIcon {
    width: 22px;
    margin-right: 0.5rem;
  }
}
.label {
  // background-color: #69d100;
  border-radius: 2rem;
  width: fit-content;
  padding: 0.1rem 0.7rem;
  color: white;
  font-size: 14px;
  font-weight: bold;

  vertical-align: middle;
  display: table-cell;
  line-height: 1.25rem;
}
.label.undefined {
  background-color: transparent !important;
  border: 1px solid #a69392;
  color: #a69392;
}

// label colors

// .label.Inschatten {
//   background-color: #0033cc !important;
// }

// .label.Doorvoeren {
//   background-color: #69d100 !important;
// }

// .label.Geen.oplossing.beschikbaar {
//   background-color: #ff0000 !important;
// }

// .label.Afwachting.klant {
//   background-color: #7f8c8d !important;
// }

// .label.Meer.info {
//   background-color: #f0ad4e !important;
// }

.issueTitle {
  padding-top: 1.5rem;
  font-weight: 600;
}
.scheduledDate {
  font-size: 14px;
}
</style>
