<template>
  <div
    id="scheduledIssues"
    :class="this.isSmallContainer ? 'small-container' : 'normal-container'"
  >
    <div class="header">
      <div class="container" style="">
        <div class="row scheduledIssuesHeader">
          <div class="col-lg">
            <h4 class="openIssuesTitleHeader">Ingeplande issues</h4>
          </div>
          <div class="col-lg allIssuesLink" v-if="this.link">
            <router-link to="/issues" class="highlightBlue"
              >Alle issues <img src="../../assets/arrow_right.png"
            /></router-link>
          </div>
        </div>
      </div>
    </div>

    <issues-block-skeleton
      v-if="!this.$store.state.scheduledIssuesLoaded"
    ></issues-block-skeleton>
    <template v-if="this.$store.state.scheduledIssuesLoaded">
      <div class="issues">
        <div class="container">
          <div class="">
            <p v-if="this.$store.state.scheduledIssuesCount == 0">
              Er zijn geen ingeplande issues
            </p>
            <div
              class="projectRow row"
              v-for="(project, index) in this.$store.state.scheduledIssues"
              v-bind:key="project"
            >
              <div class="labelWrapper" v-if="project.length > 0">
                <div class="label" style="background-color: rgb(105, 209, 0)">
                  <span>{{ index }}</span>
                </div>
              </div>

              <div
                v-bind:class="'col-lg-' + this.columns"
                v-for="issue in project"
                v-bind:key="issue"
              >
                <router-link
                  :to="'/issues/' + issue['project_id'] + '/' + issue['iid']"
                  class="issueBlockLink"
                  ><issue-block
                    :labelBgColor="'#69d100'"
                    :scheduledIssue="true"
                    :issue="issue"
                    :projectId="issue['project_id']"
                  ></issue-block>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import issueService from "../../service/IssueService";
import issueBlock from "../../components/issue/IssueBlock";
import IssuesBlockSkeleton from "../skeletons/issues/IssuesBlockSkeleton.vue";

export default {
  props: ["smallContainer", "colsize", "showLink"],
  components: {
    issueBlock,
    IssuesBlockSkeleton,
  },
  data() {
    return {
      isSmallContainer: this.smallContainer,
      columns: this.colsize,
      link: this.showLink,
    };
  },
  async created() {
    await this.getScheduledIssues();
    this.calculateAndSetHeight();
  },
  methods: {
    async getScheduledIssues() {
      await issueService.getScheduledIssues();
    },
    calculateAndSetHeight() {
      var maxHeight = -1;
      $("#scheduledIssues .issueBlockLink").each(function () {
        let height = $(".issueTitle", this).height();
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      $("#scheduledIssues .issueTitle").height(maxHeight);
    },
  },
};
</script>

<style scoped>
.small-container {
  max-width: 960px;
  margin: 0 auto;
  margin-top: 6rem;
}

#scheduledIssues {
  min-height: 5rem;
  margin-top: 4rem;
}

.openIssuesTitleHeader {
  margin-bottom: 2rem;
}

.labelWrapper {
  width: 100%;
  margin-bottom: 1rem;
}
.label {
  background-color: #69d100;
  border-radius: 2rem;
  width: fit-content;
  padding: 0.1rem 0.7rem;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.issueBlockLink {
  color: black;
  text-decoration: none;
}

#issueBlock:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.allIssuesLink {
  text-align: right;
}
@media screen and (max-width: 992px) {
  .scheduledIssuesHeader {
    flex-direction: row;
  }

  .openIssuesTitleHeader {
    margin-bottom: 1rem;
  }
  .allIssuesLink {
    margin-bottom: 1rem;
    text-align: left;
  }

  .OpenIssuesBlockContent {
    margin-top: 3rem;
  }
}
</style>