<template>
  <div id="stripTickets">
    <strip-ticket-skeleton
      v-if="!this.$store.state.punchCardLoaded"
    ></strip-ticket-skeleton>

    <template v-if="this.$store.state.punchCardLoaded">
      <div class="stripTicketsHeader">
        <div class="container">
          <h1 class="stripTicketsHeaderTitle">Strippenkaarten</h1>
          <p>
            Je hebt
            <span class="highlightBold" v-if="this.$store.state.openCards">{{
              this.$store.state.openCards.length > 0
                ? this.$store.state.openCards.length
                : "nog geen"
            }}</span>
            {{
              this.$store.state.openCards.length > 1
                ? "strippenkaarten!"
                : "strippenkaart!"
            }}
          </p>
          <p v-if="false">
            Je hebt nog <span class="highlightBold">4 uur</span> over op je
            huidige kaart.
          </p>
        </div>
      </div>
      <div
        class="noStripTicket"
        v-if="
          this.$store.state.openCards.length == 0 &&
          this.$store.state.fullCards.length == 0
        "
      >
        <div class="container">
          <div class="noStripTicketBlock">
            <div class="row">
              <div class="col-lg-4 imageWrapper">
                <img
                  class="strippenkaartImage"
                  src="../../assets/strippenkaart.png"
                />
              </div>
              <div class="col-lg-8 explanation">
                <h4>Je hebt nog geen strippenkaart</h4>
                <h6>Wat is een strippenkaart</h6>
                <p>
                  Wil je regelmatig doorontwikkelen en optimaliseren aan je
                  website tegen een scherper uurtarief en zonder inschatting
                  vooraf? Als je niet helemaal tevreden bent met je huidige
                  website of shop, of een briljant idee hebt over het verbeteren
                  van je online aanwezigheid dan kan je dat tegenwoordig regelen
                  met de Miller Digital strippenkaart!
                </p>
                <button
                  v-if="!this.loadingFirstStripticket"
                  @click="buyFirstPunchCard()"
                  class="btn btn-primary"
                >
                  Strippenkaart aanschaffen
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  disabled
                  v-if="this.loadingFirstStripticket"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only"> Strippenkaart aanschaffen...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="stripTicketRows">
        <div class="container">
          <div
            class="form-check form-switch"
            v-if="this.$store.state.fullCards.length > 0"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              v-on:click="toggleCards($event)"
            />
            <label class="form-check-label" for="flexSwitchCheckDefault"
              >Laat {{ this.$store.state.fullCards.length }} volle  {{this.$store.state.fullCards.length > 1 ? "kaarten" : "kaart"}}
              zien</label
            >
          </div>

          <div class="accordion" id="accordionExample">
            <p v-if="!this.toggle && !this.$store.state.openCards">
              Er zijn geen strippenkaarten waar uren op geschreven kunnen worden
            </p>

            <div
              class="accordion-item strippenkaartRow"
              v-for="(stripticket, index) in this.$store.state
                .filterStrippenkaartArray"
              v-bind:key="stripticket"
            >
              <div
                class="alert alert-warning"
                role="alert"
                v-if="stripticket.hours >= this.getTotalHours(stripticket) && !this.toggle"
              >
                Let op deze strippenkaart heeft meer dan {{ this.getTotalHours(stripticket) }} uur.<br />Deze
                strippenkaart is dus niet meer te gebruiken. Klik op de knop om
                een nieuwe aan te schaffen met 10% korting
                <br />
                <button
                  v-if="!this.loadingNewStripticket"
                  id="newStripticket"
                  @click="newPunchCard(stripticket.project_id, index)"
                  class="btn btn-primary mt-3"
                >
                  Nieuwe aanschaffen
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  disabled
                  v-if="this.loadingNewStripticket"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only"> Nieuwe aanschaffen...</span>
                </button>
              </div>
              <p class="accordion-header" :id="'heading' + index">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + index"
                  aria-expanded="false"
                  :aria-controls="'collapse' + index"
                  @click="open(stripticket.id, $event)"
                >
                  <div class="stripticketName">
                    {{ stripticket.name }}
                  </div>
                  <div class="stripticketendDate" v-if="this.toggle">
                    einddatum:
                    {{
                      stripticket.end_date
                        ? this.dateFormat(stripticket.end_date)
                        : "niet aanwezig"
                    }}
                  </div>
                  <div class="stripticketPrice" v-if="this.toggle">
                    € {{ this.roundDecimal(stripticket.price) }}
                  </div>

                  <div class="col-lg progressWrapper" v-if="!this.toggle">
                    <div
                      v-if="!this.toggle"
                      class="progress"
                      style="width: 100px"
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="'width:' + (stripticket.hours / this.getTotalHours(stripticket)) * 100 + '%'"
                        :aria-valuenow="(stripticket.hours / this.getTotalHours(stripticket)) * 100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    {{ this.roundDecimal(stripticket.hours) }}
                    / {{this.getTotalHours(stripticket)}} uur
                  </div>
                  <div class="col" v-if="stripticket.hours >= this.getTotalHours(stripticket)"></div>
                </button>
              </p>
              <div
                :id="'collapse' + index"
                class="accordion-collapse collapse"
                :aria-labelledby="'heading' + index"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body" v-if="!this.hoursLoading">
                  <table class="table">
                    <tbody>
                      <tr
                        v-for="hours in this.$store.state.hoursOnPunchCards[
                          stripticket.id
                        ]"
                        v-bind:key="hours"
                      >
                        <td>
                          {{ this.dateFormat(hours.created_at) }}
                        </td>
                        <td class="hoursNote">
                          {{
                            hours.note != "" ? hours.note : "geen omschrijving"
                          }}
                        </td>
                        <td>{{ this.roundDecimal(hours.hours) }}</td>
                        <td>
                          €{{ this.roundDecimal(hours.hours * hours.tariff) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <strong>Totaal aantal uur:</strong>
                    {{ this.roundDecimal(stripticket.hours) }}
                  </p>
                </div>

                <div class="accordion-body" v-if="this.hoursLoading">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="stripTicketSettings">
      <div class="container">
        <div id="serviceRow">
          <div class="row">
            <div class="col-lg-6">
              <p class="settingsType">Strippenkaart automatisch vernieuwen</p>
            </div>
            <div class="col-lg-4">
              <div class="form-check form-switch">
                <input
                  @change="toggleAutoRenew()"
                  v-model="this.autoRenew"
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  :disabled="toggleLoading"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </template>
  </div>
</template>

<script>
import stripTicketSkeleton from "../../components/skeletons/punchCard/StripticketSkeleton.vue";
import punchCardService from "../../service/PunchCardService";
export default {
  components: {
    stripTicketSkeleton,
  },
  data() {
    return {
      filterStrippenkaartArray: [],
      toggle: false,
      hoursOnStrippenkaart: [],
      hoursLoading: false,
      loadingFirstStripticket: false,
      loadingNewStripticket: false,
    };
  },
  methods: {
    async buyFirstPunchCard() {
      this.loadingFirstStripticket = true;

      await punchCardService.addFirstPunchCard();

      this.loadingFirstStripticket = false;
    },
    async newPunchCard(projectId, index) {
      this.loadingNewStripticket = true;
      await punchCardService.addPunchCard(projectId, index);
      this.loadingNewStripticket = false;
    },
    getPunchCard() {
      punchCardService.getPunchCard();
    },
    toggleCards(event) {
      if (event.target.checked) {
        this.toggle = true;
        this.$store.state.filterStrippenkaartArray =
          this.$store.state.fullCards;
      } else {
        this.toggle = false;
        this.$store.state.filterStrippenkaartArray =
          this.$store.state.openCards;
      }
    },
    async open(serviceId, event) {
      event.stopPropagation();
      let isExpanded = event.target.getAttribute("aria-expanded");

      // bug fix click on child
      if (event.target.type != "button") {
        isExpanded = "true";
      }
      if (
        isExpanded == "true" &&
        this.$store.state.hoursOnPunchCards[serviceId] == null
      ) {
        this.hoursLoading = true;
        let hours = punchCardService.getPunchCardHours(serviceId);

        return await Promise.all([hours]).then((res) => {
          this.hoursLoading = false;
        });
      }
    },
    dateFormat(dateString) {
      let date = new Date(dateString.replace(" ", "T"));
      let day = ("0" + date.getDate()).slice(-2);

      let month = ("0" + (date.getMonth() + 1)).slice(-2);

      return day + "-" + month + "-" + date.getFullYear();
    },
    roundDecimal(num) {
      return parseFloat(num).toFixed(2).replace(".", ",");
    },
    getTotalHours(punchCard) {
      const hour_types = punchCard.hour_types;
      var totalHours = 0;
      for(const hour_type in hour_types) {
        totalHours += hour_types[hour_type].budgeted_amount;
      }

      return totalHours;
    }
  },
  created() {
    this.getPunchCard();
  },
};
</script>

<style scoped lang="scss">
#stripTickets {
  padding-bottom: 2rem;
}
.stripTicketsHeaderTitle {
  margin-top: 3rem;
}
.noStripTicketBlock {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 6px 3px 6px solid rgba(0, 0, 0, 0.16);
  max-width: 800px;
  margin: 3rem auto 0;
}
.imageWrapper {
  position: relative;
}
.strippenkaartImage {
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 0;
}
.stripTicketRows .form-switch {
  margin-bottom: 2rem;
}

.hoursNote {
  font-weight: bold;
}

.explanation {
  padding: 3rem 4rem 3rem 5rem;
  z-index: 1;
  h4 {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .explanation {
    padding: 2.5rem;

    h4 {
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .noStripTicketBlock .row {
    flex-direction: column-reverse;
  }

  .strippenkaartImage {
    position: relative;
    width: 100%;
    height: auto;
  }

  .progressWrapper {
    margin-top: 1rem;
  }
  .accordion-button::after {
    position: absolute;
    right: 0;
  }
}

.stripTicketRows {
  margin-top: 3rem;

  .container {
    border-bottom: 1px solid #dddddd;
  }
}

.strippenkaartRow {
  padding: 1rem;
  border: none;
  margin-bottom: 1rem;
}
.form-check-label {
  font-weight: bold;
}
.form-check-input:checked {
  background-color: #ffc400;
  border-color: #ffc400;
}

.stripTicketSettings {
  margin-top: 1rem;
}

.progress {
  border-radius: 50px;
  height: 0.6rem;
  margin-bottom: 0;
  margin-right: 1rem;
  .progress-bar {
    background-color: #ffc400;
    border-radius: 50px;
  }
}

#serviceRow {
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.settingsType {
  margin-bottom: 0;
  font-weight: bold;
}
.progressWrapper {
  display: flex;
  align-items: center;
}
.stripticketName {
  font-weight: bold;
}
@media screen and (min-width: 992px) {
  .stripticketName {
    width: 40%;
  }
  .stripticketendDate,
  .stripticketPrice {
    width: 30%;
  }
}

@media screen and (max-width: 992px) {
  .accordion-button {
    display: flex;
    flex-direction: column;

    div {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  table {
    border: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    caption {
      text-align: left;
      font-size: 1.3em;
      margin: 0.5em 0 0.75em;
    }

    thead {
      display: none;
    }

    tr {
      display: block;
      & + tr {
        margin-top: 0.625em;
      }
    }

    td {
      display: flex;
      // justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid rgb(209, 209, 209);
      font-size: 0.8em;
      line-height: 1.35em;

      &:before {
        content: attr(data-label);
        font-size: 0.9em;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        max-width: 45%;
        color: #545454;
      }

      & + td {
        margin-top: 0.8em;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>