import axios from "axios"
import { store } from '../store'
import router from '../router'
import gitService from "./GitService";
export default {
    async getSubscription() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'subscription').then((res) => {
            let response = res.data['data'];
            if (Object.keys(response).length > 0) {

                var invoice_date = response.subscription.invoice_date ?? response.subscription.start_date
                var invoice_date = invoice_date.split('-');
                response.subscription.invoice_date = `${invoice_date[2]}-${invoice_date[1]}-${invoice_date[0]}`;
                response.subscription.budget_percentage = this.calculateBudgetPercentage(
                    response.project.budget.total.value_budget,
                    response.project.budget.total.value_spent
                );

                // set in vuex  
                store.state.subscription = response;
            }
            store.state.subscriptionLoaded = true

        }).catch((err) => {
            store.state.subscriptionLoaded = true
            console.log(err);
        });
    },
    async getMonthlyMailSwitch() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'monthly-subscription-mail').then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
    async updateMonthlyEmailActive(active) {
        let data = {
            active: active
        };

        return await axios.put(process.env.VUE_APP_ROOT_API + 'monthly-subscription-mail', data).then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
    },
    async addSubscription(hours, price) {
        let data = {
            hours: hours,
            price: price
        }
        return await axios.post(process.env.VUE_APP_ROOT_API + 'subscription', data).then(() => {
            this.getSubscription();
            gitService.getMainDevGroup();
        }).catch((err) => {
            console.log(err);
        });
    },
    monthDiff(dateFrom, dateTo) {
        return (
            dateTo.getMonth() -
            dateFrom.getMonth() +
            12 * (dateTo.getFullYear() - dateFrom.getFullYear())
        );
    },
    calculateBudgetPercentage(budget, spend) {
        let overBudget = false;
        let budgetPercentage = 0;
        if (spend <= budget) {
            let usedBudgetPercentage = (spend / budget) * 100;
            overBudget = false;
            budgetPercentage = usedBudgetPercentage;
            return usedBudgetPercentage;
        } else {
            let difference = spend - budget;
            let percentageOverBudget = (difference / budget) * 100;
            overBudget = true;
            budgetPercentage = percentageOverBudget;
            return percentageOverBudget;
        }
    },
}