<template>
  <div id="issues">
    <div class="issuesHeader">
      <div class="container">
        <div class="row">
          <div class="col-lg left">
            <h1 class="issueHeaderTitle">Issues</h1>
            <span v-if="this.$store.state.openIssues">
              Je hebt
              <span class="highlightBold"
                >{{ this.$store.state.openIssuesCount }} openstaande</span
              >
              issues.
            </span>
          </div>
          <div class="col-lg right">
            <a class="btn btn-primary" @click="scroll()">Issue aanmaken</a>
          </div>
        </div>
      </div>
    </div>
    <estimated-issues :smallContainer="false" colsize="4"></estimated-issues>
    <scheduled-issues :smallContainer="false" colsize="4"></scheduled-issues>
    <open-issues colsize="4"></open-issues>
    <issues-closed-block></issues-closed-block>
    <create-issue-block></create-issue-block>
  </div>
</template>

<script>
import openIssues from "../../components/issue/OpenIssues";
import createIssueBlock from "../../components/issue/CreateIssueBlock";
import scheduledIssues from "../../components/issue/ScheduledIssues";
import estimatedIssues from "../../components/issue/EstimatedIssues";
import jQuery from "jquery";
import IssuesClosedBlock from "../../components/issue/IssuesClosedBlock.vue";
const $ = jQuery;
window.$ = $;
export default {
  components: {
    openIssues,
    scheduledIssues,
    createIssueBlock,
    estimatedIssues,
    IssuesClosedBlock,
  },
  methods: {
    scroll() {
      const el = document.getElementById("createIssueBlock");
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {},
  setup() {},
};
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}
#alert {
  position: fixed;
  top: 7rem;
  right: 1rem;
  z-index: 9999;
}
#issues {
  padding-bottom: 3rem;
  z-index: 99;
  position: relative;
  padding-bottom: 8rem;
}

.issuesHeader .right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  a {
    width: 15rem;
  }
}

.issueHeaderTitle {
  margin-top: 3.5rem;
}

@media screen and (max-width: 992px) {
  .issuesHeader .right {
    margin-top: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .OpenIssuesBlockContent {
    margin-top: 3rem;
  }
}
</style>