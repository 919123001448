<template>
  <div id="nav">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <div class="row">
          <div class="col brandWrapper">
            <router-link to="/" class="navbar-brand" href="#">
              <img src="..\assets\md_hres.png" alt="" />
            </router-link>
          </div>
          <div class="col nav-links">
            <div class="navbar-nav">
              <router-link
                v-if="this.$store.state.isAuthenticated"
                class="nav-item nav-link"
                to="/dashboard"
                >Dashboard</router-link
              >
              <router-link
                v-if="this.$store.state.isAuthenticated"
                class="nav-item nav-link"
                to="/issues"
                >Issues</router-link
              >
              <router-link
                v-if="this.$store.state.isAuthenticated"
                class="nav-item nav-link"
                to="/projecten"
                >Projecten</router-link
              >
              <router-link
                v-if="this.$store.state.isAuthenticated"
                class="nav-item nav-link"
                to="/strippenkaart"
                >Strippenkaart</router-link
              >
              <router-link
                v-if="this.$store.state.isAuthenticated"
                class="nav-item nav-link"
                to="/hosting"
                >Hosting</router-link
              >
              <router-link
                v-if="this.$store.state.isAuthenticated"
                class="nav-item nav-link"
                to="/abonnementen"
                >Abonnementen</router-link
              >
              <router-link
                v-if="this.$store.state.isAuthenticated"
                class="nav-item nav-link"
                to="/SLA"
                >SLA</router-link
              >
              <router-link
                v-if="this.$store.state.isAuthenticated"
                class="nav-item nav-link"
                to="/facturen"
                >Facturen</router-link
              >
              <router-link
                v-if="!this.$store.state.isAuthenticated"
                class="nav-item nav-link profileItem mobile"
                to="/login"
                ><img src="..\assets\profile.png"
              /></router-link>
              <router-link
                v-if="this.showSync"
                class="nav-item nav-link"
                to="/sync"
                >Sync</router-link
              >
            
              <router-link
                class="nav-item nav-link"
                to="/admin"
                v-if="this.$store.state.isAdmin"
                >Admin</router-link
              >

              <li
                class="dropdown"
                v-if="
                  this.$store.state.isAuthenticated &&
                  this.$store.state.inActiveOrganisations.length > 0
                "
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  v-if="this.$store.state.user"
                >
                  Organisaties
                </a>
                <ul
                  class="dropdown-menu switch-organisations"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <a class="dropdown-item dropdown-active"
                      >{{ this.$store.state.user.activeOrganisation.name }}
                      <span>actief</span></a
                    >
                  </li>
                  <li
                    v-for="organisation in this.$store.state
                      .inActiveOrganisations"
                    v-bind:key="organisation"
                  >
                    <a
                      @click="switchOrganisation(organisation.id)"
                      class="dropdown-item"
                      href="#"
                      >{{ organisation.name }}</a
                    >
                  </li>
                </ul>
              </li>
              <li class="mobileUserItem"><hr class="dropdown-divider" /></li>
              <a
                class="nav-link dropdown-toggle mobileUserItem"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-if="this.$store.state.user"
              >
                {{ this.$store.state.user.firstname }}
              </a>
              <ul
                class="dropdown-menu mobileUserItem"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <router-link to="/" class="dropdown-item" href="#"
                    >Mijn profiel</router-link
                  >
                </li>
                <li>
                  <a @click="this.logout()" class="dropdown-item" href="#"
                    >Uitloggen</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col navRight">
            <router-link to="/login" v-if="!this.$store.state.isAuthenticated">
              <div class="profile-icon">
                <a class="nav-item nav-link profile-item" href="#"
                  ><img src="..\assets\profile.png" />
                </a>
              </div>
            </router-link>
            <li class="nav-item dropdown loggedInUserDropdown">
              <a
                class="nav-link"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-if="hasRedirect()"
                @click="goToMainAccount()"
              >
                Terug naar {{ this.getAdminNameFromStorage() }}
              </a>
            </li>
            <li
              class="nav-item dropdown loggedInUserDropdown"
              v-if="this.$store.state.isAuthenticated"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-if="this.$store.state.user"
              >
                {{ this.$store.state.user.firstname }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link to="/profiel" class="dropdown-item" href="#"
                    >Mijn profiel</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a @click="this.logout()" class="dropdown-item" href="#"
                    >Uitloggen</a
                  >
                </li>
              </ul>
            </li>

            <div id="nav-icon3" class="hamburgerMenu">
              <span class="icon1"></span>
              <span class="icon2"></span>
              <span class="icon3"></span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
import authService from "../service/AuthService";
import authServiceAdmin from "../service/admin/AuthService";
import userService from "../service/UserService";
import { syncOrganisations } from "@/service/organisation/organisation";
// import axios from "axios";

export default {
  name: "Navbar",
  data() {
    return {
      showSync: false,
    };
  },
  async created() {
    this.$store.state.promiseApp.then(() => {
      if (this.$store.state.isAuthenticated && this.$store.state.user.activeOrganisation) {
        this.showSync = !!Object.values(syncOrganisations).find((organisation) => 
          organisation.simplicateId == this.$store.state.user.activeOrganisation.simplicateId
        );
      }
    });
  },

  methods: {
    switchOrganisation(id) {
      userService.switchActiveOrganisation(id);
    },
    hasRedirect(){
      return localStorage.getItem("adminToken") != null ? true : false;
    },
    goToMainAccount(){
      authServiceAdmin.switchToMainAccount();
    },
    getAdminNameFromStorage() {
      const name = localStorage.getItem("adminName");
      return name;
    },
    logout() {
      authService.logout();
    },
  },
  mounted: function test() {
    $(".hamburgerMenu").click(function () {
      $(".nav-links").toggleClass("show");
      $("#nav-icon3").toggleClass("open");
    });
  },
  watch: {
    $route() {
      $(".nav-links").removeClass("show");
      $("#nav-icon3").removeClass("open");
    },
  },
};
</script>

<style scoped lang="scss">
#nav {
  position: sticky;
  top: 0;
  z-index: 100;
}
.navbar {
  background-color: #ffffff;
  min-height: 6rem;
  font-weight: bold;

  img {
    max-height: 2.5rem;
  }
}

.navbar .row {
  width: 100%;
}

.brandWrapper {
  display: flex;
  align-items: center;
}

.nav-item {
  padding: 8px 14px !important ;
  list-style-type: none;
}

.nav-links {
  align-items: center;
  display: flex;
}

.navbar-light .navbar-nav .nav-link.organisation {
  color: #0d6efd;
  cursor: pointer;
}
.navbar-light .navbar-nav .nav-link.organisation:hover {
  color: #0967f5;
}

// #navbarDropdown {
//  text-align: center;
// }

// .dropdown-menu {
//   left: 0;
// }
.dropdown-active {
  background: rgb(176, 176, 176);
  white-space: normal;

  span {
    font-size: 12px;
    font-weight: bold;
  }
}

.switch-organisations a {
  white-space: normal;
}

.navbar-collapse {
  justify-content: center;
  align-items: center;
  height: 100%;
}

.profile-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  img {
    width: 20px;
    height: 20px;
  }
}

.loggedInUserDropdown {
  flex-grow: 1;
  padding-right: 0 !important;

  a.dropdown-toggle {
    padding: 0.5rem 0;
    text-align: right;
  }

  .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.profile-item {
  background-color: rgba(0, 159, 217, 0.15);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileItem.mobile {
  display: none;
}

#nav-icon3 {
  cursor: pointer;
  width: 2rem;
  display: none;

  span {
    position: relative;
    display: block;
    width: 2rem;
    height: 1.5px;
    background-color: black;
    -webkit-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span.icon2 {
    margin-top: 5px;
    height: 2px;
    width: 1.6rem;
  }

  span.icon3 {
    margin-top: 5px;
    width: 1.3rem;
    height: 2px;
  }

  .hamburgerMenu.open .icon1 {
    transform: rotate(-45deg) translate(-5px, 6px) !important;
    width: 2rem !important;
  }

  .hamburgerMenu.open .icon2 {
    opacity: 0;
  }

  .hamburgerMenu.open .icon3 {
    transform: rotate(45deg) translate(-5px, -6px) !important;
    width: 2rem !important;
  }
}
#nav-icon3:hover span {
  width: 2rem !important;
}

.navRight {
  display: flex;
  justify-content: flex-end;
  padding-right: 0 !important;
}
@media screen and (min-width: 992px) {
  .mobileUserItem {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  #nav-icon3 {
    display: block;
  }
  .navRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .navbar-toggler {
      border: none;
    }
  }

  // .mobileUserItem {
  //   display: block;
  // }

  .loggedInUserDropdown {
    display: none;
  }
  .dropdown-menu {
    border: none;
    margin-top: 0;
    a {
      padding: 5px 0 !important;
    }
  }

  .profile-icon {
    display: none;
  }

  .navbar {
    min-height: auto;

    .container {
      justify-content: center;
      padding: 0;
    }
  }

  .navbar-nav {
    margin-top: 1rem;

    .nav-item {
      padding: 10px 0 !important;
    }
  }

  .nav-links {
    transition: 0.25s ease-in-out;
  }

  .nav-links {
    display: none;
    position: fixed;
    background: white;
    top: 3.5rem;
    z-index: 99;
    height: calc(100vh - 3.5rem);
    width: 100%;
    left: 0;
    // transform: translateX(100%);
  }

  .nav-links.show {
    display: block;
    // transform: translateX(0%);
  }

  .profileItem.mobile {
    display: block;
  }
}

.logout {
  cursor: pointer;
}
</style>
