<template>
  <div>
    <div class="container">
      <div class="OpenIssuesBlockContent">
        <div class="row">
          <div class="col-lg text-end allIssuesLink">
            <router-link to="/issues" class="highlightBlue"
              ></router-link
            >
          </div>
        </div>
        <div class="row">
          <div
            v-bind:class="'col-lg-' + 4"
            v-for="issue in 3"
            v-bind:key="issue"
          >
            <div class="issue"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["openIssuesCol"],
  name: "IssuesBlockSkeleton",
  data: function() {
    return {
      columns: this.openIssuesCol,
    };
  },
};
</script>

<style scoped>
#dashboard.loading .openIssuesTitleHeader {
  background-color: #ddd;
  animation: pulse-bg 2s infinite;
  width: 70%;
  content: "";
  height: 40px;
}

.issue {
  background-color: #ddd;
  animation: pulse-bg 2s infinite;
  content: "";
  height: 185px;
  margin-bottom: 1rem;
}

.OpenIssuesBlockContent {
  margin: 0 auto;
}

.openIssuesTitleHeader {
  margin-bottom: 3rem;
}

.issueBlockLink {
  color: black;
  text-decoration: none;
}

#issueBlock:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.skeletonTitleHeader {
  background-color: #ddd;
  animation: pulse-bg 2s infinite;
  width: 20%;
  content: "";
  height: 40px;
}

@media screen and (max-width: 992px) {
  .openIssuesHeader {
    flex-direction: column;
  }
  .OpenIssuesBlockContent {
    margin-top: 3rem;
  }

  .openIssuesTitleHeader {
    margin-bottom: 1rem;
  }
}
</style>
