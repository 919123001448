<template>
  <div class="container">
    <div
      id="alert"
      style="display: none"
      class="alert alert-success"
      role="alert"
    ></div>
    <h2 class="profileHeaderTitle">Mijn profiel</h2>
    <form class="profileForm" @submit.prevent>
      <div class="form-group" v-if="this.user">
        <label class="form-label">E-mail</label>
        <input
          type="text"
          class="form-control mb-1"
          placeholder="Email"
          v-model="this.user.email"
          v-if="this.$store.state.dataFetched"
          disabled
        />
      </div>
      <div class="form-group">
        <label class="form-label">Voornaam</label>
        <input
          type="text"
          class="form-control mb-1"
          placeholder="Voornaam"
          v-model="this.user.firstname"
          v-if="this.$store.state.dataFetched"
          @keydown="valueChanged($event)"
        />
      </div>
      <div class="form-group">
        <label class="form-label">Achternaam</label>
        <input
          type="text"
          class="form-control mb-1"
          placeholder="Achternaam"
          v-model="this.user.lastname"
          v-if="this.$store.state.dataFetched"
          @keydown="valueChanged($event)"
        />
      </div>

      <div class="form-group">
        <label class="form-label">Actieve organisatie</label>
        <input
          v-if="this.$store.state.dataFetched"
          type="text"
          class="form-control mb-1"
          placeholder="Organisatie"
          v-model="this.user['activeOrganisation']['name']"
          disabled
        />
      </div>
      <div class="form-check form-switch">
        <input
          v-if="this.$store.state.dataFetched"
          @change="valueChanged($event)"
          v-model="this.user.emailNotifications"
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
          :disabled="this.sendRequest"
        />
        <label class="checkboxLabel" for="titel"
          >Ontvang email notificaties wanneer een developer een bericht in een
          issue stuurt</label
        >
      </div>
      <button
        v-if="!this.sendRequest"
        :disabled="changes != true"
        v-on:click="this.updateUser()"
        class="btn btn-primary mt-4"
      >
        Opslaan
      </button>
      <button
        class="btn btn-primary mt-4"
        type="button"
        disabled
        v-if="this.sendRequest"
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span class="sr-only"> Opslaan...</span>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userService from "../../service/UserService";
export default {
  data() {
    return {
      user: "",
      firstname: "",
      lastname: "",
      changes: false,
      sendRequest: false,
      notifications: false,
    };
  },
  watch: {
    getUser: {
      handler() {
        this.user = JSON.parse(JSON.stringify(this.getUser));
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    valueChanged() {
      this.changes = true;
    },
    async updateUser() {
      let data = {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        notifications: this.user.emailNotifications,
      };
      await userService.updateUser(data);
      $("#alert")
        .fadeTo(4000, 500)
        .slideUp(500, function () {
          $("#alert").slideUp(500);
        });
      $("#alert").empty();
      $("#alert").append("<span>Account aangepast!</span>");
      this.changes = false;
    },
  },
};
</script>

<style scoped>
#alert {
  position: fixed;
  right: 1rem;
}
.profileHeaderTitle {
  margin-top: 3.5rem;
}

.profileForm {
  max-width: 400px;
}

.form-switch {
  margin-top: 1rem;
}

.checkboxLabel {
  margin-left: 0.3rem;
}

.form-check-input:checked {
  background-color: #ffc400;
  border-color: #ffc400;
}
</style>
