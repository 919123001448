import axios from "axios"
import { store } from '../../store'
import router from '../../router'

export default {
    async loginAsUser(email) {
        return await axios.post(process.env.VUE_APP_ROOT_API + 'admin/user/login', { email: email }).then((res) => {
            localStorage.setItem('adminToken', localStorage.getItem('token'));
            localStorage.setItem('adminName', store.state.user.firstname);
            localStorage.setItem('token', res.data['data']['token']);
            router.push({ path: "/dashboard" });
            window.location.reload();
        });
    },
    async switchToMainAccount() {
        localStorage.setItem('token', localStorage.getItem('adminToken'));
        localStorage.removeItem('adminToken');
        localStorage.removeItem('adminName');
        router.push({ path: "/dashboard" });
        window.location.reload();
    }
}