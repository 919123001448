<template>
  <div id="closedIssuesBlock">
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-lg">
            <h4 class="openIssuesTitleHeader">Gesloten issues</h4>
            <router-link class="btn btn-primary" to="/issues/closed"
              >Bekijk gesloten issues</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
#closedIssuesBlock {
  min-height: 5rem;
  margin-top: 4rem;

  .btn {
      margin-top: 2rem;
  }
}
</style>