import axios from "axios"
import { store } from '../../store'
import router from '../../router'

export default {
    async getAllIssues() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/issues').then((res) => {
            return res.data['data']['issues']
        });
    },
    async getIssuesForDev(id) {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'admin/issues/developer/' + id).then((res) => {
            return res.data['data']['issues']
        });
    }
}