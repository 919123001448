<template>
  <div class="container mt-4">
    <div
      id="alert"
      style="display: none"
      class="alert alert-success"
      role="alert"
    ></div>
    <router-link to="/admin">Ga terug naar overzicht</router-link>
    <h2>Beheer alle accounts</h2>
    <router-link to="/admin/accounts/add" class="btn btn-primary"
      >Gebruiker toevoegen</router-link
    >
    <router-link to="/admin/accounts/connect" class="btn btn-primary ms-2"
      >Gebruiker koppelen aan organisatie</router-link
    >
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Organisatie</th>
          <th scope="col">Naam user</th>
          <th scope="col">Inloggen</th>
          <th scope="col">Wachtwoord reset email</th>
          <th scope="col">Verwijderen</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(organisation, indexOrganisation) in this.$store.state
            .allOrganisations"
          :key="organisation.name"
        >
          <tr>
            <th scope="row">{{ indexOrganisation + 1 }}</th>
            <td class="fw-light">{{ organisation.name }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-for="(user, indexUser) in organisation.users" v-bind:key="user">
            <th scope="row"></th>
            <td>
              {{ user.email }}
              <span
                v-if="this.isAdmin(user)"
                class="badge rounded-pill bg-primary"
                >ADMIN</span
              >
              <span
                v-if="user.email == this.$store.state.user.email"
                class="badge rounded-pill bg-primary"
                >Dit ben ik!</span
              >
            </td>
            <td>{{ user.firstname }} {{ user.lastname }}</td>
            <td>
              <button
                v-on:click="loginAsUser(user.email)"
                class="btn btn-primary"
              >
                Inloggen
              </button>
            </td>
            <td>
              <button
                v-on:click="resetLinkPassword(user.email)"
                class="btn btn-primary"
              >
                Wachtwoord reset link
              </button>
            </td>
            <td>
              <button
                v-on:click="
                  removeUser(user.email, indexOrganisation, indexUser)
                "
                class="btn btn-primary"
              >
                Gebruiker verwijderen
              </button>
            </td>
          </tr>
          <tr v-if="organisation.users.length == 0">
            <td></td>
            <td>Deze organisatie heeft geen gebruikers</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import axios from "axios";
import organisationService from "../../service/admin/OrganisationService";
import authService from "../../service/admin/AuthService";
import authServiceUser from "../../service/AuthService";
import userService from "../../service/admin/UserService";
export default {
  methods: {
    isAdmin(user) {
      return user["roles"].includes("ROLE_ADMIN");
    },
    loadData() {
      organisationService.getAllOrganisations();
    },
    loginAsUser(email) {
      authService.loginAsUser(email);
    },
    async resetLinkPassword(email) {
      this.sendRequest = true;
      await authServiceUser.resetPassword(email);

      this.sendRequest = false;
      $("#alert")
        .fadeTo(4000, 500)
        .slideUp(500, function () {
          $("#alert").slideUp(500);
        });
      $("#alert").empty();
      $("#alert").append("<span>Email is verstuurd!</span>");
    },
    async removeUser(userEmail, organisationIndex, userIndex) {
      await userService.removeUser(userEmail);
      this.$store.state.allOrganisations[organisationIndex].users.splice(
        userIndex,
        1
      );

      $("#alert")
        .fadeTo(4000, 500)
        .slideUp(500, function () {
          $("#alert").slideUp(500);
        });
      $("#alert").empty();
      $("#alert").append("<span>Gebruiker is verwijderd!</span>");
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
#alert {
  position: fixed;
  right: 1rem;
}
</style>
