import axios from "axios"
import { store } from '../store'
import router from '../router'

export default {
    async getDirectAdminInfo() {
        return await axios.get(process.env.VUE_APP_ROOT_API + 'direct-admin').then((res) => {
            store.state.usage = res.data['data']['usage'];
        }).catch((err) => {
            console.log(err);
        });

    }
}