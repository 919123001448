<template>
  <div id="invoices">
    <div class="stripTicketsHeader">
      <div class="container">
        <h1 class="stripTicketsHeaderTitle">Facturen</h1>
        <p class="invoiceCount"></p>
      </div>
    </div>

    <div class="container">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Factuurdatum</th>
            <th scope="col">Factuurnummer</th>
            <th scope="col">Omschrijving</th>
            <th scope="col">Totaal incl. btw</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, index) in 10" v-bind:key="index">
            <td data-label="Factuurdatum">
              <div></div>
            </td>
            <td data-label="Factuurnummer"><div></div></td>
            <td data-label="Omschrijving"><div></div></td>
            <td data-label="Totaal excl. btw"><div></div></td>
            <td data-label="Status"><div></div></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#invoices {
  padding-bottom: 2rem;
}
.stripTicketsHeaderTitle {
  margin-top: 3.5rem;
}
$border-color: rgb(209, 209, 209);

td div {
  width: 75%;
  height: 1.5rem;
  background-color: rgb(255, 255, 255);
  animation: pulse-bg 2s infinite;
}
.invoiceCount {
  width: 12rem;
  background-color: rgb(255, 255, 255);
  animation: pulse-bg 2s infinite;
  height: 1rem;
}
@media screen and (max-width: 767px) {
  table {
    border: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    caption {
      text-align: left;
      font-size: 1.3em;
      margin: 0.5em 0 0.75em;
    }

    thead {
      display: none;
    }

    tr {
      display: block;
      border: 2px solid $border-color;
      padding: 1em 1em 0.5em;

      & + tr {
        margin-top: 0.625em;
      }
    }

    td {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid $border-color;
      font-size: 0.8em;
      line-height: 1.35em;

      &:before {
        content: attr(data-label);
        font-size: 0.9em;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        max-width: 45%;
        color: #545454;
      }

      & + td {
        margin-top: 0.8em;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>
