<template>
  <div id="projects">
    <projects-skeleton
      v-if="!this.$store.state.projectsLoaded"
    ></projects-skeleton>
    <template v-if="this.$store.state.projectsLoaded">
      <div class="projectsHeader">
        <div class="container">
          <h1 class="projectsHeaderTitle">Projecten</h1>
          <span>
            Je hebt op dit moment
            <span class="highlightBold"
              >{{ this.$store.state.projects.length }}
              {{
                this.$store.state.projects.length > 1
                  ? "lopende projecten"
                  : this.$store.state.projects.length == 0
                  ? "lopende projecten"
                  : "lopend project"
              }}</span
            >
          </span>
        </div>
      </div>

      <div class="projectRows">
        <div class="container">
          <p v-if="this.$store.state.projects.length == 0">
            Er zijn geen projecten gevonden voor deze organisatie
          </p>
          <div
            v-for="project in this.$store.state.projects"
            v-bind:key="project"
          >
            <div id="projectRow">
              <div class="row">
                <div>
                  <p class="projectTitle">{{ project.name }}</p>
                </div>
              </div>
            </div>
            <!-- {{project}} -->
            <!-- <project-row v-bind:project="project"></project-row> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ProjectsSkeleton from "../../components/skeletons/projects/ProjectsSkeleton.vue";
import projectService from "../../service/ProjectService";
export default {
  components: { ProjectsSkeleton },
  setup() {},
  created() {
    this.getProjects();
  },
  methods: {
    getProjects() {
      projectService.getProjects();
    },
  },
};
</script>

<style scoped lang="scss">
#projects {
  padding-bottom: 3rem;
}
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.projectsHeaderTitle {
  margin-top: 3.5rem;
}

.projectRows {
  margin-top: 3rem;
}
.projectLink {
  color: black;
  text-decoration: none;
}

/* row */

#projectRow {
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;

  a {
    color: black;
    text-decoration: none;
  }

  .projectTitle {
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
  }
}

#projectRow:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;

  .progress {
    width: 100%;
    border-radius: 50px;
    height: 0.6rem;

    .progress-bar {
      background-color: #ffc400;
      border-radius: 50px;
    }
  }
}

.priceIndication {
  color: #797979;
  display: inline;
  font-weight: 500;
  .bold {
    font-weight: bold;
  }
}

.projectDetailArrow {
  color: black;
  font-weight: bold;
  margin-left: 1rem;
}

@media screen and (max-width: 992px) {
  .leftSide {
    flex-direction: column;
    align-items: flex-start;

    .progress {
      margin-left: 0;
    }
  }
}
</style>