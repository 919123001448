<template>
  <div>
    <div id="issueDetailSkeleton">
      <div class="issueDetailHeader">
        <div class="container">
          <p class="allIssues"></p>
          <h2></h2>
          <div class="label">
            <span></span>
          </div>
        </div>
      </div>
      <div class="issueDetailInfo">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 sidebar">
              <div class="project">
                <h6></h6>
                <p></p>
              </div>

              <div class="description">
                <h6></h6>
                <p></p>
              </div>

              <div class="developer">
                <h6></h6>
                <p></p>
              </div>

              <div class="esitmatedTime">
                <h6></h6>
                <p></p>
              </div>

              <div class="dueDate">
                <h6></h6>
                <p></p>
              </div>

              <div class="files">
                <h6></h6>
              </div>

              <a class="btn closeIssue"></a>
            </div>

            <div class="col-lg-8 chatFeed">
              <div class="feed">
                <div class="messageDeveloper message">
                  <div class="messageContent"></div>
                </div>

                <div class="messageCustomer message">
                  <div class="messageContent"></div>
                </div>

                <div class="messageDeveloper message">
                  <div class="messageContent"></div>
                </div>

                <div class="messageCustomer message">
                  <div class="messageContent"></div>
                </div>
                <div class="messageCustomer message">
                  <div class="messageContent"></div>
                </div>
              </div>
              <div class="editor"></div>
              <a class="btn sendMessage"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
#issueDetailSkeleton {


.issueDetailHeader {
  margin-top: 2rem;

  h2 {
    margin-top: 1rem;
    background-color: #ddd;
    width: 50%;
    height: 2.5rem;
  }
  .label {
    margin-top: 1rem;
  }
}

.allIssues {
    width: 6rem;
    height: 1rem;
    background-color: #ddd;
}

.label {
  background-color: #ddd;
  border-radius: 2rem;
  padding: 0.1rem 0.7rem;
  color: white;
  font-size: 14px;
  font-weight: bold;
  height: 2rem;
  width: 8rem;
}

.issueDetailInfo {
  margin-top: 2rem;
}

.sidebar {
  padding: 2rem;
  animation: pulse-bg 2s infinite;
  background-color: #ddd;
  height: calc(27rem + 250px);
  h6 {
    background-color: #ddd;
    width: 65%;
    height: 2rem;
  }
  p {
    background-color: #ddd;
    width: 45%;
    height: 1.5rem;
  }
}

.progress {
  width: 60%;
  border-radius: 50px;
  height: 0.6rem;
}

.project,
.description,
.developer,
.dueDate,
.progress {
  margin-bottom: 2rem;
}

.calenderIcon {
  width: 22px;
  margin-right: 0.5rem;
}

.filesWrapper {
  width: 100%;

  img {
    width: 100%;
  }

  .fileName {
    margin-bottom: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.04);
    text-decoration: underline;
    text-align: center;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
  }

  .fileContent {
    margin-bottom: 1rem;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .bottom {
      text-align: center;
      padding: 0.2rem 0;
      a {
        color: rgba(0, 0, 0, 0.8);
        text-decoration: none;
        cursor: pointer;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .seeImageModal {
    margin-right: 1rem;
  }
}

.closeIssue {
  background-color: #ddd;
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 1rem 0;
  height: 4rem;
}

.chatFeed {
  padding: 0 5rem;
}

.feed {
  height: 25rem;
  overflow-y: scroll;
  margin-bottom: 2rem;
}

.tox-tinymce {
  width: 100% !important;
}
@media screen and (max-width: 992px) {
  .chatFeed {
    padding: 0;
    margin-top: 2rem;
  }
}

.message {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;

  .messageContent {
    border-radius: 10px;
    width: 18rem;
    animation: pulse-bg 2s infinite;
    background-color: #ddd;
    height: 6rem;
  }
}

.messageDeveloper {
  padding-left: 1rem;

  .messageContent {
    background-color: #e6f9ff;

    p {
      margin-bottom: 0;
    }
  }
}

.messageCustomer {
  justify-content: flex-end;
  padding-right: 1rem;
  .messageContent {
    background-color: #ffffff;

    p {
      margin-bottom: 0;
    }
  }
}

.messageContent {
  max-width: 60%;
  padding: 1.5rem;
}
.messageTimeDate {
  font-weight: bold;
  font-size: 14px;
  margin-top: 0.2rem;
}

.editor {
  height: 250px;
  background-color: #ddd;
}

.sendMessage {
  float: right;
  margin-top: 2rem;
  height: 2.5rem;
  background-color: #ddd;
  width: 6rem;
}

.modal-body {
  width: 100%;

  img {
    width: 100%;
  }
}

}
</style>
