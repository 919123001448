<template>
  <div class="skeleton">
    <div class="subscriptionHeader">
      <div class="container">
        <h1 class="subscriptionHeaderTitled">Abonnementen</h1>
        <span> Consequent doorontwikkelen tegen een lager tarief </span>
      </div>
    </div>
    <div class="subscription">
      <div class="container">
        <div class="row">
          <div class="subscriptionSidebar col-lg-4"></div>
          <div class="subscriptionHours col-lg-8">
            <div class="row"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>


<style scoped lang="scss">
.subscriptionHeader {
  margin-top: 3rem;
}

.subscription {
  margin-top: 2rem;
}
.subscriptionSidebar {
  background-color: #ddd;
  animation: pulse-bg 2s infinite;
  content: "";
  height: 30rem;
}

.subscriptionHours {
  padding-left: 4rem;
}

.subscriptionHours .row {
  height: 5rem;
  background-color: #ddd;
  animation: pulse-bg 2s infinite;
  content: "";
}
</style>