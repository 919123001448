<template>
  <div class="container">
    <div id="login">
      <div class="loginWrapper">
        <form class="loginForm">
          <div class="passwordResetContent">
            <img class="mdLogo" src="../../../assets/md_hres.png" />
            <div class="passwordResetInstuction">
              <p class="headTitle">Je wachtwoord is ingesteld</p>
              <p>Je account is succesvol ingesteld</p>
            </div>

            <div class="success-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
            <router-link to="/login" class="btn btn-primary loginButton"
              >Log nu in</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
};
</script>

<style lang="scss" scoped>
#app {
  background-image: url("../../../assets/background_md_55.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  padding-bottom: 0;
}
#login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loginWrapper {
  width: 75%;
  min-height: 25rem;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
  padding: 1.5rem 4rem;
  display: flex;
  height: 100%;

  form {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.passwordResetInstuction {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  .headTitle {
    margin-bottom: 0;
    font-weight: bold;
  }

  p {
    width: 50%;
    margin: auto;
    line-height: 1.3rem;
  }
}

.loginButton {
  width: 60%;
  display: block;
  margin: auto;
  padding: 1rem 0;
}
.mdLogo {
  width: 6rem;
}
@media screen and (max-width: 992px) {
  .loginButton {
    width: 85%;
  }
  .imageHouseWrapper {
    justify-content: center;
    margin-top: 2rem;
  }

  .passwordResetInstuction p {
    width: 85%;
  }

  .loginWrapper {
    width: 95%;
    padding: 1rem;
  }

  .mdLogo {
    width: 4rem;
  }
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      // background: #FFFFFF;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      // background-color: #FFFFFF;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
</style>
