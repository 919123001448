<template>
  <div class="selectOrganisation">
    <h2 class="mb-5">Stel je standaard organisatie in</h2>
    <div class="card-group">
      <div
        v-for="organisation in this.$store.state.organisations"
        v-bind:key="organisation"
        class="card"
        style="width: 18rem; height: 14rem"
      >
        <div class="card-body">
          <h5 class="card-title">{{ organisation.name }}</h5>
          <button
            type="button"
            class="btn btn-link"
            @click="selectOrganisation(organisation.id)"
          >
            Selecteer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import organisationService from "../../service/OrganisationService";
export default {
  async mounted() {
    await this.getOrganisations();
  },
  methods: {
    selectOrganisation(id) {
      organisationService.setOrganisation(id);
    },
    getOrganisations() {
      organisationService.getOrganisations();
    },
  },
};
</script>
<style lang="scss">
.selectOrganisation {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: #eff6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/background_md_55.png");
  background-position: top; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-attachment: fixed;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>