import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import axios from 'axios'
import { dragscrollNext } from "vue-dragscroll";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueEasyLightbox from 'vue-easy-lightbox'
import VuePapaParse from 'vue-papa-parse'
import { i18n } from './snippets/i18n'

import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    // Do something before request is sent
    config.headers = { 'x-api-key': token, Accept: 'application/json ' };
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Not authorized
    if (error.response && error.response.data) {
        if (error.response.request.responseURL.endsWith('password-reset/check')) {
            router.push({ path: '/wachtwoord-vergeten/verlopen' })
            return Promise.reject(error.response.data)
        }

        if (error.response.status === 401) {
            console.log('401');
            localStorage.removeItem('token');

            if (router.options.history.state.current != "/login") {
                router.push({ name: 'login', params: { nextUrl: router.options.history.state.current }, })
            }
            
            return Promise.reject(error.response.data)
        }

        // Forbidden access
        if (error.response.status === 403) {
            router.push({ path: '/' })
        }

        // server error
        if (error.response.status === 500) {
            console.log('500')
        }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
}); 
createApp(App).use(store)
.component('v-select', vSelect)
.use(router, axios).use(VueEasyLightbox).directive('dragscroll', dragscrollNext)
.use(VuePapaParse)
.use(i18n)
.mount('#app')